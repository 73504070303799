export const THICK_SPACER = 'thick-spacer';
export const THICK_SPACER_FILE_ID = '3a5bf1da7517a8253e058fb455fe0bc48bb03d5f';
export const THICK_SPACER_URL = 'https://enotice-production.imgix.net/custom-documents%2Fpermalink%2F9a86.d90a2-thick_header.jpg';
export const SUPER_SKINNY_SPACER = 'three-col-spacer';
export const SUPER_SKINNY_SPACER_FILE_ID = 'a3aa801891821493038afa229b0341640e479624';
export const SUPER_SKINNY_SPACER_URL = 'https://enotice-production.imgix.net/custom-documents%2Fpermalink%2F1169.f2e48-3-col-spacer.jpg';
export const NOTICE_ELEMENT_PREFIX = 'NOTICE';
export const SKINNY_SPACER_PREFIX = 'skinny-spacer';
export const SKINNY_SPACER_FILE_ID = '0aa16641ff3e9ee1e67bc0a74ced3887a95ae143';
export const SKINNY_HEADER_URL = 'https://enotice-production.imgix.net/custom-documents%2Fpermalink%2Fd600.7a390-skinny_header.jpg';
const LEE_ESCALATION_PHOENIX = 'phoenix.legals@lee.net';
const LEE_ESCALATION_MACTIVE = 'mactive.legals@lee.net';
const LEE_SOUTHEAST_ESCALATION_MACTIVE = 'SoutheastMactiveEscalations@lee.net';
const JOY = 'jmonopoli@rsnva.com';
const PAGINATION = 'pagination@column.us';
const PAUL = 'paul@column.us';
const TERESA = 'teresa@column.us';
const TRELLO_INTEGRATION = 'jasonpupek1+a1taqjamrwyvrcirb0xq@boards.trello.com';
const PAGINATION_INTEGRATION_EMAILS = [PAGINATION, TRELLO_INTEGRATION];
const LEE_FTP_CONFIG = {
    format: 'FTP',
    host: 'upload-files.column.us',
    user: 'lee-pagination',
    password: '539760f6e7',
    port: 22,
    secure: false,
    path: ''
};
export const TEST_UPLOAD_DESTINATION = {
    label: 'Column Testing',
    newspaperCode: 'COL-X',
    liaisonContacts: [PAGINATION, PAUL, TERESA],
    paginationContacts: PAGINATION_INTEGRATION_EMAILS,
    organizationId: 'fbhbFfBd6xl3LlTY35lz',
    ftpConfig: LEE_FTP_CONFIG,
    shouldGrayscale: true
};
const LEE_UPLOAD_DESTINATIONS = [
    {
        label: 'Times Democrat',
        newspaperCode: 'ONG-T',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX, JOY],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: '1Pe8dRZD5sZpuzCymn3h',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Pantagraph',
        newspaperCode: 'BLM-P',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX, JOY],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'HPeL6NJPpc5TxId2IYIP',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Woodford',
        newspaperCode: 'BLM-W',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX, JOY],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: '1sbbcSPBYwD5X6EVE0OB',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Herald & Review',
        newspaperCode: 'DEC-H',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX, JOY],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'keQkIB7vlHi00vhCncVY',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Journal Gazette and Times-Courier',
        newspaperCode: 'MAT-J',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX, JOY],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 's3wpzM2RIHTNrmpzneTq',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Hartsville Messenger',
        newspaperCode: 'SCA-MSGR',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: '6RMAJ1T0AV0IBfreq1sO',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Marion Star',
        newspaperCode: 'SCA-STAR',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'ZKVtUGN2hz7A5sWHVMKF',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Florence Morning News',
        newspaperCode: 'SCA-NEWS',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'aLIOF7qmvXFN0QF1HzYn',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Press of Atlantic City',
        newspaperCode: 'PAC-PRES',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'PF6Oj7CkmWn4Ubpi6hvx',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Dothan Eagle',
        newspaperCode: 'DOT-EAGL',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'dHWAgbHhr8y0UOVCskpV',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Enterprise Ledger',
        newspaperCode: 'DOT-LDGR',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'MCCLddMb5yUOw68nBFEJ',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Eufaula Tribune',
        newspaperCode: 'DOT-TRIB',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'QsVXFzxpul1G5QGRuUI9',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Opelika Auburn News',
        newspaperCode: 'OAN-NEWS',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'dQjz1hqNNEgeV8evafZb',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Bristol Herald Courier',
        newspaperCode: 'BHC-HCOU',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'cfPsOFiAW5qnpjrkL754',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Wytheville Enterprise',
        newspaperCode: 'SWV-WCTY',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'AHrz05Q6m0CjzZlGrq7O',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Smyth County News & Messenger',
        newspaperCode: 'SWV-SCTY',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'XR0oaU1rK9hCE9OB99hG',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'The Floyd Press',
        newspaperCode: 'SWV-FPRE',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'dtTG0xt67M0deRbKQ3nE',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Richlands News Press',
        newspaperCode: 'SWV-RNEW',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'xzae015jchEKg3nyP7Mw',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Roanoke Times',
        newspaperCode: 'ROA-TMES',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'LIVF7f0edsUqFtUlKeuF',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Franklin News-Post',
        newspaperCode: 'FNP-NEWS',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'xUnG1hcY3INQAJ2eZIZm',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Fredericksburg Free Lance-Star',
        newspaperCode: 'FLS-FRED',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'xPHHBfytSGjVHjEEz7p0',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Culpeper Star Exponent',
        newspaperCode: 'CSE-STAR',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'BdH9Ws1BxPgsmXD0GsI1',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Amherst New Era Progress',
        newspaperCode: 'LNA-ANEP',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'wuC74H1SSHWVErVz4yWW',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Danville Register & Bee',
        newspaperCode: 'DAN-REGB',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'YVyxZiWwliRvs34WmT4s',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Lynchburg News & Advance',
        newspaperCode: 'LNA-NEWS',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'KEziG5VXuJCarfPZKQLT',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Nelson County Times',
        newspaperCode: 'LNA-NCTI',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: '6zy06zRc71q3rAz7CRJS',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Martinsville Bulletin',
        newspaperCode: 'MVB-BULL',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'GHTiQc6rLsefcbpNqfIx',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Charlottesville Daily Progress',
        newspaperCode: 'CDP-PROG',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'yUU7SoHjCSXeoNMqBs9a',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Waynesboro News Virginian',
        newspaperCode: 'WNV-NEWS',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: '65TB5LcJfIbRPXNOl0ji',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Greene County Record',
        newspaperCode: 'CDP-GREN',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'erQiMl2XdfaSSmycjz3j',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Madison County Eagle',
        newspaperCode: 'CDP-MADI',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'zBGfiNKfFrjcS6dGG9qr',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Orange County Review',
        newspaperCode: 'CDP-ORAN',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'EXrpgxUxFONXJfYoh2i2',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Rural Virginian',
        newspaperCode: 'CDP-VIRG',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'z65hdtnUrmFiq4xeeLOJ',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Richmond Times Dispatch',
        newspaperCode: 'RTD-RTDI',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'oEPFGiI1EOee385RJP2i',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Mechanicsville Ashland Local',
        newspaperCode: 'RTD-MECH',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'KzLpEnSMwS954WhZ7twA',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Powhatan Today',
        newspaperCode: 'RTD-POWH',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'YuQZPBc0cnnFmOY6QmZs',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Goochland Gazette',
        newspaperCode: 'RTD-GOOC',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'LjPeSt8Wk5otrwVhLKno',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'The Daily Nonpareil',
        newspaperCode: 'CBN-NOND',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'LBBgBhxQXosOjefXTPys',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Bulletin-Review',
        newspaperCode: 'DBR-BULL',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'pq1HgfhaPvmhpWpLpcHK',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Southwest Iowa Herald',
        newspaperCode: 'SVN-NEWS',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'TWWebdDPgoVwfUV1cNyu',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Sarpy County Times',
        newspaperCode: 'CBN-BELW',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'vRqeKWUhxYVgY3iKgjrg',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'North Platte Telegraph',
        newspaperCode: 'NPT-TELE',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'L8gQVS33jkkxRxHFt3x0',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Scottsbluff Star-Herald',
        newspaperCode: 'SSH-STAR',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'jOhEP5fq94qqxGzuY4W9',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Gering Courier',
        newspaperCode: 'SSH-GERI',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'CbL1jXL0CTssKO3ZDuAh',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Wahoo Newspaper',
        newspaperCode: 'WAH-NPAP',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'tHlmcVh95YdfWlOoi285',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Waverly News',
        newspaperCode: 'WAH-WNWS',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: '86aHe6SJKQrrPLBYXZQB',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Ashland Gazette',
        newspaperCode: 'WAH-GAZE',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'xBG3rA6VtmNB1lPwcCBo',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Hemingford Ledger',
        newspaperCode: 'SSH-HEMI',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: '4jLtjSIMcz5e2E8MyW6P',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Greensboro News and Record',
        newspaperCode: 'GNR-NWRC',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: '7uRcB05utORKAPy56YWk',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Rockingham Now',
        newspaperCode: 'GNR-ROCK',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'fNRc9Bnsmqmg2XK5Nbny',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Hickory Daily Recod',
        newspaperCode: 'HDR-RCRD',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'ZsYymTcPolQY2Uuo7AwU',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Concord Independent Tribune',
        newspaperCode: 'CIT-INDT',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'wqWtyMGlvw4CMEm0CYOV',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'McDowell News',
        newspaperCode: 'MMN-NEWS',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'm74QygA6Ce9i5Vyt0MFX',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Winston-Salem Journal',
        newspaperCode: 'WSJ-JRNL',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'iVbvd0Ot1FrnQC4qCjfg',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Mooresville Tribune',
        newspaperCode: 'MOT-TRIB',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'Omkr2aDgZrIjAWAK085q',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Morganton News Herald',
        newspaperCode: 'MNH-HRLD',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'vGlQaGMKPP0kVFEbP4Rl',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Statesville Record and Landmark',
        newspaperCode: 'STA-LAND',
        liaisonContacts: [PAGINATION, LEE_SOUTHEAST_ESCALATION_MACTIVE],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: '91Gbsyi6khezIkL2cslb',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Lincoln Journal Star',
        newspaperCode: 'LJS-J',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'uIDVIq1Y2BzR1agKjVlj',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Beatrice Daily Sun',
        newspaperCode: 'BDS-D',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'mcG0FjYH3aMDDfugWW4x',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Fremont Tribune',
        newspaperCode: 'FRE-T',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'srfWOTaOIsyO3qmXdOlu',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Billings Gazette',
        newspaperCode: 'BIL-G',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'vjm8mlPKDi6WoXKIGuCW',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Independent Record',
        newspaperCode: 'HEL-I',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'K510x9DSfxM8nJcse4Tq',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Montana Standard',
        newspaperCode: 'BUT-S',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'WaP8xnz7uXpnQv9QICb7',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Missoulian',
        newspaperCode: 'MIS-M',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'xBgxeM9xLNMPLYBVmV5W',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Ravalli Republic',
        newspaperCode: 'MIS-R',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'oFjtfMJjmRQVsT0cdMPI',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Quad-City Times',
        newspaperCode: 'QCT-T',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: '8dk7WZYxbGKi7RJQsRr2',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Muscatine Journal',
        newspaperCode: 'QCT-J',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'HASpJO0zQpuSjfBv47sF',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Dispatch Argus',
        newspaperCode: 'MDA-D',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'MAvAvxNAEgkjmzIgdgXQ',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Sioux City Journal',
        newspaperCode: 'SCJ-J',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: '6ecRmpZPmAJSjvTTNVlQ',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Courier',
        newspaperCode: 'WOO-C',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'xzdFkPxk10lWXoYJHvjy',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Globe Gazette',
        newspaperCode: 'MSC-G',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: '4m4efBcllBpnlr12eUNB',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Michell County Press-News',
        newspaperCode: 'MSC-M',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'qp49ScofwxaejUswFlS4',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Summit-Tribune',
        newspaperCode: 'MSC-S',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: '8DC6dt4MzTwMosxPEBnH',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Gazette Times - Democrat Herald',
        newspaperCode: 'MVN-G',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: '4hf5AHVDlA9y2kFlWu2O',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Napa Valley Register',
        newspaperCode: 'NVR-R',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'OKlgzKJ8FrgJavk2KBJi',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'St. Helena Star',
        newspaperCode: 'NVR-S',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'xSmu6vkzFnWMTbZ8Saxg',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Daily News',
        newspaperCode: 'LNG-N',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: '1sM404s4cRBdLDm0BCSK',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Bismarck Tribune',
        newspaperCode: 'BIS-T',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'hRu6GeiIpiV0OwxisI3X',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Mandan News',
        newspaperCode: 'BIS-M',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'ewrEJuZJjyzRjCrYVvVh',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Rapid City Journal',
        newspaperCode: 'RCJ-R',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'VIrGdYW77kS55jiHgJWL',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Chadron Record',
        newspaperCode: 'RCJ-C',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: '4Zg29lpfR5yUzcWSrnCt',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Casper Star-Tribune',
        newspaperCode: 'CAS-T',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'S0QRjldIjmTzQDGFgQ0R',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Lacrosse Tribune',
        newspaperCode: 'LAC-T',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'svQvGBfP9hqdTjG4cvnn',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Winona Daily News',
        newspaperCode: 'LAC-W',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: '3EasZTZgneguAcfJF4LP',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Chippewa Herald',
        newspaperCode: 'LAC-C',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'lPdbuGY8jbHbjPVXKUby',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Houston County News',
        newspaperCode: 'LAC-H',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'ZBoq2tV6IxFTpLvSlcxC',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'La Crosse Courier',
        newspaperCode: 'LAC-L',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'BbmJ8agp55ahugc0KBYL',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Vernon County Times',
        newspaperCode: 'LAC-V',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'jNpNUjvuPFK6Vo0tzfyP',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Tomah Journal',
        newspaperCode: 'LAC-T',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'GqE92wqIRMXPi6SU8bro',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Dunn County News',
        newspaperCode: 'LAC-N',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'qgeVqXp8wSBViLQ4gBYa',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'The Sentinel',
        newspaperCode: 'SEN-S',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'hTNn6TmBeF2iXNQSXY2L',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Post Star',
        newspaperCode: 'GLN-P',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: '93gfuexmNIGuYDVONRdw',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'The Citizen',
        newspaperCode: 'AUB-C',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'osFTGmhqBtTUp5A6hUhd',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Journal Times',
        newspaperCode: 'RAC-J',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'wpdLsSR1mOlae6UHami7',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Kenosha News',
        newspaperCode: 'KEN-K',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: '8disduMvFVxYPC35slmY',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Lake Geneva Reporter',
        newspaperCode: 'KEN-L',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'rRirxYxtib0WVcwpmrBy',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Wisconsin State Journal',
        newspaperCode: 'MAD-W',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'nNVqpElC8Jo43s2BvpNA',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Baraboo News Republic',
        newspaperCode: 'BAR-R',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'JCJQysimemSe24duqYjE',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Portage Daily Register',
        newspaperCode: 'PTG-P',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: '0OsuGXLJB1NBnssqxb35',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Beaver Dam Daily Citizen',
        newspaperCode: 'BDM-B',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'ykTadnpqf9gJqNFW8OGG',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'The Capital Times',
        newspaperCode: 'MAD-C',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'Tvq7r5MdGwLS5s6JDS9W',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Juneau County Star Times',
        newspaperCode: 'PTG-S',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'voYQEaC4GlrAB54rOEg2',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Wisconsin Dells Event',
        newspaperCode: 'PTG-D',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: '5wEoxcKU8C38W22JE0AL',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Agri-View',
        newspaperCode: 'MAD-A',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: '0Cg1bqiGxI9eG7BNidga',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Times News',
        newspaperCode: 'TWN-T',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'iHUFzbVrEpmBVfJC4ZjU',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Elko Daily Free Press',
        newspaperCode: 'ELK-E',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'LC7fRTgiCjI97S60mmy8',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Kearney Hub',
        newspaperCode: 'KHP-K',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: '2mQdZxZMqZjcNak6hDjK',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Lexington Clipper-Herald',
        newspaperCode: 'LCH-L',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'z4EfOE1DXuiCnb2gHmHc',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Grand Island Independent',
        newspaperCode: 'GII-I',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'lJNwGxRwF2OBdSuVa1T8',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'The Columbus Telegram',
        newspaperCode: 'COL-T',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'RpvBCZC6McpHo3ZSRTlN',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'David City Banner Press',
        newspaperCode: 'COL-D',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: '4J495Gu0Ad7tIYgRvzBw',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'Schuyler Sun',
        newspaperCode: 'COL-S',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'aOK4QX7Xjvc0M9pfLgm2',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'York News-Times',
        newspaperCode: 'YNT-Y',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'X4TiRxfuVzPZPSXuZHDu',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    {
        label: 'St. Louis Post Dispatch',
        newspaperCode: 'STL-D',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        paginationContacts: PAGINATION_INTEGRATION_EMAILS,
        organizationId: 'S2sP2B8XQe4Bj2q7YzMC',
        ftpConfig: LEE_FTP_CONFIG,
        shouldGrayscale: true
    },
    TEST_UPLOAD_DESTINATION
];
const GRAND_JUNCTION_ESCALATION = 'production@gjsentinel.com';
const MANHATTAN_MERCURY_ESCALATION = 'camera@themercury.com';
const SHERIDAN_PRESS_ESCALATION = 'production@thesheridanpress.com';
const SEATON_FTP_CONFIG = {
    format: 'FTP',
    host: 'upload-files.column.us',
    user: 'seaton-pagination',
    password: 'I5w%2RYVP51$bx6MADRo',
    port: 22,
    secure: false,
    path: ''
};
const SEATON_UPLOAD_DESTINATIONS = [
    {
        label: 'Grand Junction Daily Sentinel',
        newspaperCode: 'GJMI',
        liaisonContacts: [PAGINATION, GRAND_JUNCTION_ESCALATION],
        paginationContacts: [PAGINATION, GRAND_JUNCTION_ESCALATION],
        organizationId: 'S8fqyy2TfAOkN7xjZEts',
        ftpConfig: SEATON_FTP_CONFIG,
        shouldGrayscale: false
    },
    {
        label: 'Manhattan Mercury',
        newspaperCode: 'MER',
        liaisonContacts: [PAGINATION, MANHATTAN_MERCURY_ESCALATION],
        paginationContacts: [PAGINATION, MANHATTAN_MERCURY_ESCALATION],
        organizationId: 'xVWmRsNEh9zobzmevsrr',
        ftpConfig: SEATON_FTP_CONFIG,
        shouldGrayscale: false
    },
    {
        label: 'Sheridan Press',
        newspaperCode: 'TSP',
        liaisonContacts: [PAGINATION, SHERIDAN_PRESS_ESCALATION],
        paginationContacts: [PAGINATION, SHERIDAN_PRESS_ESCALATION],
        organizationId: 'bTWNHYwzq7OVqHEKF6zw',
        ftpConfig: SEATON_FTP_CONFIG,
        shouldGrayscale: false
    },
    {
        label: 'The Times, Pottawatomie County',
        newspaperCode: 'TTP',
        liaisonContacts: [PAGINATION, MANHATTAN_MERCURY_ESCALATION],
        paginationContacts: [PAGINATION, MANHATTAN_MERCURY_ESCALATION],
        organizationId: 'PoQDBMienp8ftWsCBkcH',
        ftpConfig: SEATON_FTP_CONFIG,
        shouldGrayscale: false
    }
];
// TODO: We should stop using hardcoded configuration once pagination is used by
// 3 or more publishers. Instead, we should use firestore to save configuration,
// and build a UI in Admin to manage it.
export const PAGINATION_UPLOAD_DESTINATIONS = [
    ...LEE_UPLOAD_DESTINATIONS,
    ...SEATON_UPLOAD_DESTINATIONS
];
