var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert } from 'lib/components/Alert';
import { ColumnButton } from 'lib/components/ColumnButton';
import { Form } from 'lib/components/Form';
import moment from 'moment';
import { useState } from 'react';
import Placemat from 'routes/ads/place/Placemat';
import MultiStepHeader from 'routes/ads/place/components/MultiStepHeader';
import { logAndCaptureException } from 'utils';
import { getFirebaseContext } from 'utils/firebase';
import { ColumnService } from 'lib/services/directory';
import { getModelFromSnapshot } from 'lib/model';
import { ObituaryModel } from 'lib/model/objects/obituaryModel';
import { getUserByEmail } from 'utils/users';
import { VerificationInput } from './VerificationInput';
function Footer({ loading }) {
    return (_jsxs("div", Object.assign({ className: "fixed bottom-0 flex w-full py-4 shadow border-b bg-white px-8 xl:px-12 justify-between items-center" }, { children: [_jsx(ColumnButton, { type: "button", tertiary: true, buttonText: "Need help?" }), _jsx(ColumnButton, { type: "submit", primary: true, buttonText: "Submit verification", loading: loading })] })));
}
const getVerificationPromptsFromObituary = (obituary) => {
    const { deceasedName, deathVerification, deceasedBirthDate } = obituary;
    const verificationQuestions = [
        {
            id: 'funeral-home-name',
            labelText: `Please confirm your funeral home name is ${deathVerification.funeralHomeName}.`
        },
        {
            id: 'deceased-name',
            labelText: `Please confirm the name of the person that passed on is ${deceasedName}.`
        },
        {
            id: 'deceased-date',
            labelText: `Please confirm the date of birth of ${deceasedName} is ${moment(deceasedBirthDate).format('MMMM Do, YYYY')}.`
        }
    ];
    return verificationQuestions;
};
export function VerificationForm({ orderData, obituarySnap, email }) {
    const [error, setError] = useState();
    const context = getFirebaseContext();
    const name = orderData.isAnonymousOrder
        ? `${orderData.filerFirstName} ${orderData.filerLastName}`
        : 'a newspaper';
    const verificationQuestions = getVerificationPromptsFromObituary(obituarySnap.data());
    const [verificationInputs, setVerificationInputs] = useState(verificationQuestions.map(() => undefined));
    const [loading, setLoading] = useState(false);
    const handleSelectedValueChange = (newValue, index) => {
        const newVerificationInputs = [...verificationInputs];
        newVerificationInputs.splice(index, 1, newValue);
        setVerificationInputs(newVerificationInputs);
        setError(undefined);
    };
    const handleSubmit = () => __awaiter(this, void 0, void 0, function* () {
        var _a;
        if (verificationInputs.some(o => !o)) {
            setError({
                title: 'Invalid verification',
                description: 'If all items cannot be truthfully confirmed, please click the Need help button.'
            });
            return;
        }
        if (!email) {
            return;
        }
        setLoading(true);
        try {
            const obituary = getModelFromSnapshot(ObituaryModel, context, obituarySnap);
            const user = yield getUserByEmail(email);
            const verificationData = {
                verificationMethod: 'manual',
                verifiedBy: (_a = user === null || user === void 0 ? void 0 : user.ref) !== null && _a !== void 0 ? _a : email
            };
            yield obituary.setVerificationStatus(true, verificationData);
        }
        catch (e) {
            setLoading(false);
            setError({
                title: 'Something went wrong',
                description: 'Please try again. If the error persists, please contact Column support.'
            });
            logAndCaptureException(ColumnService.OBITS, e, 'Error verifying obituary', {
                obituaryId: obituarySnap.id
            });
        }
        window.location.reload();
    });
    return (_jsxs(Form, Object.assign({ onSubmit: handleSubmit }, { children: [_jsxs(Placemat, { children: [_jsx(MultiStepHeader, { title: "Column's Death Verification Check", description: `Your funeral home has been submitted by ${name} to verify a death. Please verify by checking the boxes below.` }), error && (_jsx(Alert, { id: "verification-error", title: error.title, description: error.description, status: "error" })), verificationQuestions.map(({ id, labelText }, index) => (_jsx("div", Object.assign({ className: "pb-6" }, { children: _jsx(VerificationInput, { id: id, labelText: labelText, value: verificationInputs[index], onSelectedValueChange: newValue => handleSelectedValueChange(newValue, index) }) }), id)))] }), _jsx(Footer, { loading: loading })] })));
}
