var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BillingStatusType, NoticeStatusType } from '../../enums';
import { createDBPricingFromNotice } from '../../pricing';
import { generateFormattedFooter } from '../../headers_footers/footers';
import { removeUndefinedFields } from '../../helpers';
import { requestDisplayParameters } from '../../indesign/request';
import { NoticeService } from './NoticeService';
import { wrapError, wrapSuccess } from '../../types/responses';
import { safeGetOrThrow } from '../../safeWrappers';
import { getModelFromRef } from '../../model';
import { UserNoticeModel } from '../../model/objects/userNoticeModel';
/**
 * Create a complete notice with given fields, useful for importing notices in bulk or outside of the placement flow
 */
export const createCompleteNotice = (firebaseContext, indesignClient, DOMparser, publisher, adTemplate, filer, rate, noticeContent, publicationDates, noticeType, noticeMetadata) => __awaiter(void 0, void 0, void 0, function* () {
    const publicationTimestamps = publicationDates.map(firebaseContext.timestampFromDate);
    const { noticeHTML, columns, referenceId, headerText } = noticeContent;
    const footer = yield generateFormattedFooter(firebaseContext, Object.assign({ publicationDates: publicationTimestamps, newspaper: publisher }, noticeMetadata), undefined, DOMparser);
    const { error: filerError, response: filerSnapshot } = yield safeGetOrThrow(filer);
    if (filerError)
        return wrapError(filerError);
    const filedBy = filerSnapshot.data().activeOrganization;
    const currentTime = firebaseContext
        .fieldValue()
        .serverTimestamp();
    const notice = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ createTime: currentTime, newspaper: publisher, filer }, (filedBy ? { filedBy } : {})), { user: filer, userId: filer.id, adTemplate, publicationDates: publicationTimestamps, columns, confirmedHtml: noticeHTML, dynamicFooter: footer, dynamicHeaders: [], noticeType,
        rate }), (headerText ? { headerText } : {})), { 
        // This is a hack! In onNoticeUpdate we rely on the transition from !noticeStatus
        // to NoticeStatus.pending.value so moving from 0->1 or undefined->1 satisfies that
        // but only 0->1 satisfies the requirement in the type.
        noticeStatus: 0, 
        // Other default values
        billingStatus: BillingStatusType.invoice_not_submitted.value, previousNoticeType: null, proofStoragePath: null, invoice: null, finalProofURL: null, generatedAffidavitStoragePath: null, generatedAffidavitURL: null, referenceId, isArchived: false }), removeUndefinedFields(noticeMetadata));
    const noticeService = new NoticeService(firebaseContext);
    const blankNoticeRef = firebaseContext.userNoticesRef().doc();
    yield blankNoticeRef.set({});
    const modelForNewNotice = yield getModelFromRef(UserNoticeModel, firebaseContext, blankNoticeRef);
    modelForNewNotice.updateWithObjectData(notice);
    const agent = {
        isPublisher: true,
        user: filer,
        source: 'registered_agents'
    };
    const { error, response: newNoticeModel } = yield noticeService.publishNotice(modelForNewNotice, agent, true);
    if (error)
        return wrapError(error);
    let displayParams;
    try {
        displayParams = yield requestDisplayParameters(firebaseContext, indesignClient, newNoticeModel, DOMparser);
        // This update moves the status to pending which causes onNoticeUpdate
        // to try to generate docs (proof, affidavit, etc)
        yield newNoticeModel.ref.update({
            displayParams,
            noticeStatus: NoticeStatusType.pending.value
        });
    }
    catch (err) {
        return wrapError(new Error('Failed to set display params on notice'));
    }
    const { error: updateError, response: updatedNotice } = yield safeGetOrThrow(newNoticeModel.ref);
    if (updateError)
        return wrapError(updateError);
    // This update adds pricing to the notice. Note that we could
    // probably convert this to a createDBPricingFromData() call instead
    // and then include the pricing in the previous update.
    const pricing = yield createDBPricingFromNotice(firebaseContext, updatedNotice);
    yield updatedNotice.ref.update(Object.assign(Object.assign({}, removeUndefinedFields({
        pricing
    })), { confirmedAt: firebaseContext.fieldValue().serverTimestamp(), confirmedReceiptTime: firebaseContext.fieldValue().serverTimestamp(), confirmed: true }));
    return wrapSuccess(updatedNotice);
});
