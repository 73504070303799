import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
function TableRow({ label, value, isLastRow = false }) {
    return (_jsxs("tr", Object.assign({ className: `${!isLastRow ? 'border-b border-column-gray-100' : ''}` }, { children: [_jsx("td", Object.assign({ className: "px-4 py-2 font-medium" }, { children: label })), _jsx("td", Object.assign({ className: "px-4 py-2 text-right" }, { children: value }))] })));
}
function AdSizeDrawer({ newspaperOrderPublishingData }) {
    var _a, _b, _c, _d;
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };
    return (_jsxs("div", Object.assign({ className: "w-full" }, { children: [_jsxs("button", Object.assign({ type: "button", className: "flex items-center space-x-2", onClick: toggleDrawer }, { children: [_jsx("span", Object.assign({ className: "font-medium" }, { children: "Size" })), isDrawerOpen ? (_jsx(ChevronUpIcon, { className: "h-5 w-5" })) : (_jsx(ChevronDownIcon, { className: "h-5 w-5" }))] })), isDrawerOpen && (_jsx("div", Object.assign({ className: "mt-2 bg-white rounded-lg border border-column-gray-100" }, { children: _jsx("table", Object.assign({ className: "w-full text-left" }, { children: _jsxs("tbody", { children: [_jsx(TableRow, { label: "Words", value: (_b = (_a = newspaperOrderPublishingData.displayParams) === null || _a === void 0 ? void 0 : _a.words) !== null && _b !== void 0 ? _b : '' }), _jsx(TableRow, { label: "Lines", value: (_d = (_c = newspaperOrderPublishingData.displayParams) === null || _c === void 0 ? void 0 : _c.lines) !== null && _d !== void 0 ? _d : '' }), _jsx(TableRow, { label: "Total column inches", value: newspaperOrderPublishingData.columnInches
                                    ? newspaperOrderPublishingData.columnInches.toFixed(2)
                                    : '', isLastRow: true })] }) })) })))] })));
}
export default AdSizeDrawer;
