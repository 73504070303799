var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import api from 'api';
import { GridInput } from 'lib/components/Card/Grid';
import { TextField } from 'lib/components/TextField';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import useDebounce from 'lib/frontend/hooks/useDebounce';
import { getFulfilled, isDefined } from 'lib/helpers';
import { exists } from 'lib/types';
import { useState } from 'react';
import { selectActiveOrganization } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { getFirebaseContext } from 'utils/firebase';
import CustomerCreation from './CustomerCreation';
import FuneralHomeSearchResults from './FuneralHomeSearchResults';
import getFuneralHomeCustomerInformationFromCustomer from './getFuneralHomeCustomerInformationFromCustomer';
const getFuneralHomeCustomerInformationFromSearchableCustomerRecord = (result) => __awaiter(void 0, void 0, void 0, function* () {
    const customersRef = getFirebaseContext().customersRef();
    const customerRef = customersRef.doc(result.id);
    return getFuneralHomeCustomerInformationFromCustomer(customerRef);
});
const getDefaultResult = (chosenCustomer) => __awaiter(void 0, void 0, void 0, function* () {
    if (chosenCustomer) {
        const defaultResult = yield getFuneralHomeCustomerInformationFromCustomer(chosenCustomer);
        return defaultResult !== null && defaultResult !== void 0 ? defaultResult : null;
    }
    return null;
});
const search = (activeOrganizationId, searchString) => __awaiter(void 0, void 0, void 0, function* () {
    const filters = [
        { organizationid: activeOrganizationId },
        { archived: Number(false) }
    ];
    const reqBody = {
        search: searchString,
        filters
    };
    const searchCustomersResponse = yield api.post('search/customers', reqBody);
    if (!searchCustomersResponse.success) {
        throw searchCustomersResponse.error;
    }
    return searchCustomersResponse.results;
});
function FuneralHomeCustomerSearch({ chosenCustomer, onChosenCustomerChange }) {
    const activeOrganization = useAppSelector(selectActiveOrganization);
    /* Used to force a customer to be present and selected in the results (as when returning from a previous
      screen or creating a new customer) */
    const [forcedCustomerRef, setForcedCustomerRef] = useState(chosenCustomer);
    const [searchboxValue, setSearchboxValue] = useState('');
    const searchString = useDebounce(searchboxValue, 250);
    const { value: searchResults, isLoading } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            const defaultResult = yield getDefaultResult(forcedCustomerRef);
            if (defaultResult) {
                setSearchboxValue(defaultResult.name);
                onChosenCustomerChange(defaultResult);
                return [defaultResult];
            }
            if (!activeOrganization) {
                return [];
            }
            const results = yield search(activeOrganization.id, searchString);
            const customerPromises = results.map(getFuneralHomeCustomerInformationFromSearchableCustomerRecord);
            const settledCustomers = yield Promise.allSettled(customerPromises);
            const customers = getFulfilled(settledCustomers).filter(isDefined);
            return customers;
        }),
        dependencies: [searchString, forcedCustomerRef === null || forcedCustomerRef === void 0 ? void 0 : forcedCustomerRef.id]
    });
    return (_jsx("div", Object.assign({ className: "w-full inline-block" }, { children: _jsxs("div", Object.assign({ className: "grid gap-4" }, { children: [_jsx("div", Object.assign({ className: "font-semibold text-lg text-column-gray-500" }, { children: "Funeral home details" })), _jsxs("div", Object.assign({ className: "grid gap-4" }, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(TextField, { id: "search-field", labelText: "", placeholder: "Search by name", prefix: _jsx(MagnifyingGlassIcon, { className: "w-5 text-column-gray-300", "aria-hidden": "true" }), validationMessages: {
                                    valueMissing: 'Select a funeral home below or enter a name to search.'
                                }, value: searchboxValue, onChange: newValue => {
                                    setForcedCustomerRef(undefined);
                                    setSearchboxValue(newValue);
                                }, onKeyDown: e => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                    }
                                }, required: !chosenCustomer, errorText: searchboxValue && !chosenCustomer
                                    ? 'Choose an existing funeral home or create a new one to continue'
                                    : '' }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: exists(activeOrganization) && (_jsx(CustomerCreation, { onCreate: customer => {
                                    setForcedCustomerRef(customer.ref);
                                }, activeOrganization: activeOrganization })) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(FuneralHomeSearchResults, { searchResults: (searchResults === null || searchResults === void 0 ? void 0 : searchResults.length) ? searchResults : [], onSelect: customer => {
                                    onChosenCustomerChange(customer);
                                }, selection: chosenCustomer, isLoading: isLoading }) }))] }))] })) })));
}
export default FuneralHomeCustomerSearch;
