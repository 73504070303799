export var PublicationFontFamily;
(function (PublicationFontFamily) {
    PublicationFontFamily["HELVETICA"] = "Helvetica";
    PublicationFontFamily["CENTURY"] = "Century";
    PublicationFontFamily["CENTURY_725_BT"] = "Century725 BT";
    PublicationFontFamily["CHRONICLE"] = "Chronicle";
    PublicationFontFamily["LUCIDA"] = "Lucida";
    PublicationFontFamily["MYRIAD"] = "Myriad";
    PublicationFontFamily["NIMROD_MT"] = "NimrodMT";
    PublicationFontFamily["NIMROD_WGL"] = "NimrodWGL";
    PublicationFontFamily["OLYMPIAN"] = "Olympian";
    PublicationFontFamily["WHITNEY"] = "Whitney";
    PublicationFontFamily["CHAPARRAL"] = "Chaparral";
    PublicationFontFamily["ADAMANT"] = "Adamant";
    PublicationFontFamily["MONTSERRAT"] = "Montserrat";
    PublicationFontFamily["ANTIQUA"] = "Antiqua";
    PublicationFontFamily["TIMES_NEW_ROMAN"] = "TimesNewRoman";
    PublicationFontFamily["CASLON"] = "Caslon";
    PublicationFontFamily["ELLINGTON"] = "EllingtonMT";
    PublicationFontFamily["OPEN_SANS"] = "OpenSans";
    PublicationFontFamily["COOPER_HEWITT"] = "CooperHewitt";
    PublicationFontFamily["SWISS_721_BT"] = "Swiss721BT";
    PublicationFontFamily["MINION_PRO"] = "MinionPro";
    PublicationFontFamily["NEW_BASKERVILLE"] = "NewBaskervilleITC";
    PublicationFontFamily["FUTURA_BOOK"] = "FuturaBook";
    PublicationFontFamily["FUTURA_DEMI"] = "FuturaDemi";
    PublicationFontFamily["UTOPIA"] = "Utopia";
    PublicationFontFamily["HELVETICA_NEUE"] = "HelveticaNeue";
    PublicationFontFamily["NEW_CENTURY_SCHOOLBOOK"] = "NewCenturySchoolBook";
    PublicationFontFamily["BERTHOLD_BASKERVILLE"] = "BertholdBaskerville";
    PublicationFontFamily["FUTURA_LT_PRO_XB"] = "FuturaLtProXBold";
    PublicationFontFamily["SOURCE_SERIF_PRO"] = "SourceSerifPro";
    PublicationFontFamily["BENTON_SANS_COND"] = "BentonSansCondensed";
})(PublicationFontFamily || (PublicationFontFamily = {}));
export const getEmbeddedValue = (mediaType, base64String) => {
    const DATA = 'data:';
    const BASE64 = ';base64,';
    return [DATA, mediaType, BASE64, base64String].join('');
};
const getNormalFontFace = (publicationFontResource) => {
    return `
  @font-face {
      font-family: "${publicationFontResource.fontFamily}";
      src: url(${getEmbeddedValue(publicationFontResource.type, publicationFontResource.contentBase64)});
  }`;
};
const getBoldFontFace = (publicationFontResource) => {
    let result = '';
    if (publicationFontResource.contentBoldBase64) {
        result = `
      @font-face {
          font-family: "${publicationFontResource.fontFamily}";
          src: url(${getEmbeddedValue(publicationFontResource.type, publicationFontResource.contentBoldBase64)});
          font-weight: bold;
      }`;
    }
    return result;
};
const getItalicFontFace = (publicationFontResource) => {
    let result = '';
    if (publicationFontResource.contentItalicBase64) {
        result = `
      @font-face {
          font-family: "${publicationFontResource.fontFamily}";
          src: url(${getEmbeddedValue(publicationFontResource.type, publicationFontResource.contentItalicBase64)});
          font-style: italic;
      }`;
    }
    return result;
};
const getBoldItalicFontFace = (publicationFontResource) => {
    let result = '';
    if (publicationFontResource.contentBoldItalicBase64) {
        result = `
      @font-face {
          font-family: "${publicationFontResource.fontFamily}";
          src: url(${getEmbeddedValue(publicationFontResource.type, publicationFontResource.contentBoldItalicBase64)});
          font-weight: bold;
          font-style: italic;
      }`;
    }
    return result;
};
export const getFontFaceDefinition = (publicationFontResource) => {
    const definition = `
      ${getNormalFontFace(publicationFontResource)}
      ${getBoldFontFace(publicationFontResource)}
      ${getItalicFontFace(publicationFontResource)}
      ${getBoldItalicFontFace(publicationFontResource)}
  `;
    return definition;
};
