var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { isDefined, removeUndefinedFields, replaceUndefinedWithDelete } from 'lib/helpers';
import { isAdvertiserWithOrganizationOrder, isAnonymousUserOrder, isPublisherAsAdvertiserWithOrganizationOrder, isAnonymousOrder } from 'lib/types/order';
import { merge } from 'lodash';
import { useState } from 'react';
import { centsToExtendedCurrency } from 'lib/utils/rates';
import { logAndCaptureCriticalError, logAndCaptureException } from 'utils';
import { getFirebaseContext } from 'utils/firebase';
import { ColumnService } from 'lib/services/directory';
import { OrganizationType, Product } from 'lib/enums';
import { getOrThrow } from 'lib/utils/refs';
import { isObituary } from 'lib/types/ad';
import { ObituaryFilingTypeNames } from 'lib/types/obituary';
import { logInfo } from 'utils/logger';
import { BLOCK_SUBMIT_ID } from 'components/LoadingState';
import { COLUMN_SUPPORT_EMAIL } from 'lib/constants';
import { apiPostWithParams } from 'api/typed';
import { useAppSelector } from 'redux/hooks';
import { selectActiveOrganization, selectIsPublisher } from 'redux/auth';
import { createOrUpdateAnonymousFiler, getUserByEmail } from 'utils/users';
import CustomerService from 'lib/services/customerService';
import { exists } from 'lib/types';
import { getOrCreateCustomer } from 'lib/notice/customer';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { CustomerTypeString } from '../filters/helpers';
import FormWrapper from './FormWrapper';
import Placemat from './Placemat';
import { useAdForm } from './contexts/AdFormStatusProvider';
import { finalizeOrderInvoice, requestNewOrderInvoice } from './helpers/paymentProcessing';
import { usePricing } from './hooks/usePricing';
import { PlacementFlowStep } from './placementFlowStep';
import DraftContent from './steps/DraftContent';
import FlowChoiceStep from './steps/FlowChoice';
import OrderSummary from './steps/OrderSummary';
import PersonalDetail, { MINIMUM_ORDER } from './steps/PersonalDetail';
import ProvideVerification from './steps/ProvideVerification';
import SelectPublication from './steps/SelectPublication';
import SelectSchedules from './steps/SelectSchedules';
import getOrCreateCustomerWithCustomerOrganization from './helpers/getOrCreateCustomerWithCustomerOrganization';
import CategoryChoiceStep from './steps/CategoryChoice';
import { useGetRequireNewInvoice } from './hooks/useGetRequireNewInvoice';
export var SuccessModalType;
(function (SuccessModalType) {
    SuccessModalType["EditConfirmation"] = "Your edits have been processed.";
    SuccessModalType["FreeOrderSubmission"] = "Your order has been submitted successfully.";
})(SuccessModalType || (SuccessModalType = {}));
const getCustomerUserId = (orderFormData) => __awaiter(void 0, void 0, void 0, function* () {
    if (isPublisherAsAdvertiserWithOrganizationOrder(orderFormData)) {
        const customerSnapshot = yield getOrThrow(orderFormData.advertiserCustomer);
        return customerSnapshot.data().user.id;
    }
    return orderFormData.user.id;
});
function PlacementFlowStepSelector({ orderModel, adModel, draftNewspaperOrders, steps, product, version, editData }) {
    const isInitialPlacementFlow = orderModel.modelData.activeVersion === adModel.modelData.orderVersion;
    const isPublisher = useAppSelector(selectIsPublisher);
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const { currentStep, updateCurrentStep } = useAdForm();
    const [newspaperOrdersFormData, setNewspaperOrdersFormData] = useState(draftNewspaperOrders || []);
    const [orderFormData, setOrderFormData] = useState(orderModel.modelData);
    const [adFormData, setAdFormData] = useState(adModel.modelData);
    const [invoiceId, setInvoiceId] = useState();
    const [isInvoiceLoading, setInvoiceLoading] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(null);
    // Tracks the original total price when the user arrives on the order summary page so we can compare it to price changes after that point
    const [originalTotalPriceInCents, setOriginalTotalPriceInCents] = useState(null);
    const [userError, setUserError] = useState('');
    const [isSubmitLoading, setSubmitLoading] = useState(false);
    const { consolidatedOrderPricing, handleRecalculateOrderPrice, handleRefreshOrderPrice, priceLoading, priceError, priceIsStale } = usePricing({
        newspaperOrdersFormData,
        orderModel,
        adFormData,
        adModel,
        orderFormData,
        version
    });
    const isEditing = orderModel.modelData.activeVersion !== version;
    const totalInCents = consolidatedOrderPricing === null || consolidatedOrderPricing === void 0 ? void 0 : consolidatedOrderPricing.totalInCents;
    const { isLoading: getRequireNewInvoiceLoading, requireNewInvoice } = useGetRequireNewInvoice({
        orderModel,
        isEditing,
        newspaperOrdersFormData,
        totalInCents,
        step: steps[currentStep - 1]
    });
    const loading = isSubmitLoading ||
        isInvoiceLoading ||
        priceLoading ||
        getRequireNewInvoiceLoading;
    const error = priceError || userError;
    const chosenFlow = isAdvertiserWithOrganizationOrder(orderFormData)
        ? CustomerTypeString.FUNERAL_HOME
        : CustomerTypeString.INDIVIDUAL;
    const advertiserCustomer = isPublisherAsAdvertiserWithOrganizationOrder(orderFormData)
        ? orderFormData.advertiserCustomer
        : undefined;
    const goToNextStep = () => {
        setUserError('');
        updateCurrentStep(currentStep + 1);
    };
    const updateModels = () => __awaiter(this, void 0, void 0, function* () {
        yield orderModel.ref.update(replaceUndefinedWithDelete(getFirebaseContext(), isAdvertiserWithOrganizationOrder(orderFormData)
            ? orderFormData
            : merge(Object.assign({}, MINIMUM_ORDER), orderFormData)));
        // TODO(goodpaul): handle delete sentinel values in the model code
        yield orderModel.refreshData();
        yield adModel.ref.update(removeUndefinedFields(adFormData));
        /**
         * This is a solution for the fact that, when we edit order content in the Draft Content
         * step, we aren't updating `newspaperOrdersFormData` with a new `pdfStoragePath`, because the
         * PDF is generated asynchronously in the back end (see `priceAdWithSettings` in `updateNewspaperOrderPricing`).
         * Because of that, we were adding the new storage path to the newspaper orders in Firestore when
         * handling price recalculation, but then reverting it to the original PDF when the user proceeded
         * to the next step. This was fixed when the user generated an invoice, as the price was recalculated
         * and the correct proof was re-attached to the newspaper orders, but this was still causing a strange
         * bug where if the user clicked "Back" to the Draft Content step, the proof would have reverted
         * to the previous version.
         */
        const sanitizedNewspaperOrdersFormData = newspaperOrdersFormData.map(nofd => {
            const sanitized = Object.assign({}, nofd);
            delete sanitized.pdfStoragePath;
            return sanitized;
        });
        yield orderModel.updateNewspaperOrders(removeUndefinedFields(sanitizedNewspaperOrdersFormData), version);
    });
    const onSubmit = () => __awaiter(this, void 0, void 0, function* () {
        setUserError('');
        // TODO: Survey the group to see if making the hidden input field required would be acceptable
        // Check for the hidden input field to block form submission
        const blockSubmitInput = document.getElementById(BLOCK_SUBMIT_ID);
        if (blockSubmitInput) {
            logInfo('Navigation blocked due to form loading');
            return;
        }
        setSubmitLoading(true);
        try {
            // currentStep is 1-indexed so this if statement will not be executed for the last step
            if (currentStep < steps.length) {
                // We don't want to overrite the models at the last step because the BE has changed them after creating the invoice
                yield updateModels();
            }
            const { onClickNext } = stepMap[steps[currentStep - 1]];
            if (onClickNext) {
                yield onClickNext();
            }
        }
        catch (e) {
            logAndCaptureException(ColumnService.OBITS, e, `Failure during ${product} placement flow ${steps[currentStep - 1]} step`, Object.assign(Object.assign({}, (isAnonymousUserOrder(orderModel.modelData)
                ? { contactEmail: orderModel.modelData.contactEmail }
                : { userId: orderModel.modelData.user.id })), { adId: adModel.id, product, service: ColumnService.OBITS }));
            setUserError(`Could not load order. Please try again or contact ${COLUMN_SUPPORT_EMAIL} for assistance.`);
            return;
        }
        finally {
            setSubmitLoading(false);
        }
        if (currentStep < steps.length) {
            goToNextStep();
        }
    });
    const handleRequestOrderInvoice = () => __awaiter(this, void 0, void 0, function* () {
        setUserError('');
        setInvoiceLoading(true);
        const { response: invoiceId, error: invoiceError } = yield requestNewOrderInvoice(orderModel.id, version);
        if (invoiceError) {
            setUserError(invoiceError.message);
            setInvoiceLoading(false);
            return;
        }
        yield handleRefreshOrderPrice();
        setInvoiceId(invoiceId);
        setInvoiceLoading(false);
    });
    const stepMap = {
        [PlacementFlowStep.CustomerType]: {
            component: (_jsx(Placemat, { children: _jsx(FlowChoiceStep, { chosenCustomer: advertiserCustomer, onChosenCustomerChange: (advertiser) => {
                        setOrderFormData(Object.assign(Object.assign({}, orderFormData), { firstName: undefined, lastName: undefined, contactEmail: undefined, advertiserCustomer: advertiser === null || advertiser === void 0 ? void 0 : advertiser.ref, advertiser: advertiser === null || advertiser === void 0 ? void 0 : advertiser.user.ref, advertiserOrganization: (advertiser === null || advertiser === void 0 ? void 0 : advertiser.organization.ref)
                                ? advertiser.organization.ref
                                : null }));
                        if (isObituary(adFormData)) {
                            // if advertiser is an individual, clear death verification
                            if (!(advertiser === null || advertiser === void 0 ? void 0 : advertiser.organization)) {
                                setAdFormData(Object.assign(Object.assign({}, adFormData), { deathVerification: {} }));
                            }
                            // if advertiser is a funeral home, set death verification to verified
                            else if ((advertiser === null || advertiser === void 0 ? void 0 : advertiser.organization.data().organizationType) ===
                                OrganizationType.funeral_home.value) {
                                setAdFormData(Object.assign(Object.assign({}, adFormData), { deathVerification: {
                                        verifiedAt: getFirebaseContext().timestamp()
                                    } }));
                            }
                            // TODO: handle other organization types for customer selection on obits
                            else {
                                logAndCaptureCriticalError(ColumnService.OBITS, new Error('Unimplemented death verification behavior for selecting an advertiser that is not a funeral home'), 'Unimplemented death verification behavior for selecting an advertiser that is not a funeral home', {
                                    adId: adModel.id,
                                    product,
                                    service: ColumnService.OBITS
                                });
                                setAdFormData(Object.assign(Object.assign({}, adFormData), { deathVerification: {} }));
                            }
                        }
                    } }) }))
        },
        [PlacementFlowStep.Category]: {
            component: (_jsx(CategoryChoiceStep, { product: product, inputData: adFormData, newspaperOrdersFormData: newspaperOrdersFormData, onSetNewspaperOrdersFormData: setNewspaperOrdersFormData, orderModel: orderModel, onFilingTypeChange: filingTypeName => {
                    setAdFormData(Object.assign(Object.assign({}, adFormData), { filingTypeName }));
                } }))
        },
        [PlacementFlowStep.Details]: {
            component: (_jsx(Placemat, { children: _jsx(PersonalDetail, { inputData: orderFormData, setInputData: setOrderFormData }) })),
            onClickNext: () => __awaiter(this, void 0, void 0, function* () {
                if (getBooleanFlag(LaunchDarklyFlags.ENABLE_CLASSIFIEDS_CUSTOMERS) &&
                    isPublisher &&
                    activeOrganization &&
                    product === Product.Classified &&
                    isAnonymousOrder(orderFormData)) {
                    const user = yield getUserByEmail(orderFormData.contactEmail);
                    const customer = user &&
                        (yield new CustomerService(getFirebaseContext()).getCustomerFromUserAndOrg(user === null || user === void 0 ? void 0 : user.ref, activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.ref));
                    if (exists(customer)) {
                        return;
                    }
                    const userRef = yield createOrUpdateAnonymousFiler(activeOrganization, {
                        email: orderFormData.contactEmail,
                        firstName: orderFormData.firstName,
                        lastName: orderFormData.lastName,
                        phone: orderFormData.phone
                    }, { invitedBy: activeOrganization.ref });
                    const userSnap = yield userRef.get();
                    yield getOrCreateCustomer(getFirebaseContext(), userSnap, activeOrganization);
                }
            })
        },
        [PlacementFlowStep.Publication]: {
            component: (_jsx(Placemat, { children: _jsx(SelectPublication, { newspaperOrdersFormData: newspaperOrdersFormData, onNewspaperOrdersFormDataChange: setNewspaperOrdersFormData, product: product, orderModel: orderModel, inputData: adFormData }) })),
            onClickNext: () => __awaiter(this, void 0, void 0, function* () {
                if (isAdvertiserWithOrganizationOrder(orderFormData)) {
                    const promises = newspaperOrdersFormData
                        .map(newspaperOrder => { var _a; return (_a = newspaperOrder.newspaper) === null || _a === void 0 ? void 0 : _a.id; })
                        .filter(isDefined)
                        .map((newspaperId) => __awaiter(this, void 0, void 0, function* () {
                        const userId = yield getCustomerUserId(orderFormData);
                        yield getOrCreateCustomerWithCustomerOrganization({
                            userId,
                            organizationId: orderFormData.advertiserOrganization.id,
                            publisherId: newspaperId
                        });
                    }));
                    yield Promise.all(promises);
                }
            })
        },
        [PlacementFlowStep.Schedule]: {
            component: (_jsx(SelectSchedules, { onFilingTypeChange: (label) => __awaiter(this, void 0, void 0, function* () {
                    /*
                     * Include the funeral home logo if the filing type is an obituary
                     * and the order is placed by or on behalf of a funeral home.
                     */
                    var _a, _b;
                    const filingTypeIsObituary = label === ObituaryFilingTypeNames.Obituary ||
                        ObituaryFilingTypeNames.DeathNotice;
                    const funeralHomeLogo = filingTypeIsObituary &&
                        isAdvertiserWithOrganizationOrder(orderFormData)
                        ? (_b = (_a = (yield orderFormData.advertiserOrganization.get()).data()) === null || _a === void 0 ? void 0 : _a.icon) !== null && _b !== void 0 ? _b : ''
                        : '';
                    setAdFormData(Object.assign(Object.assign({}, adFormData), { filingTypeName: label, funeralHomeLogo }));
                }), product: product, newspaperOrdersFormData: newspaperOrdersFormData, onNewspaperOrdersFormDataChange: setNewspaperOrdersFormData, isInitialPlacementFlow: isInitialPlacementFlow, editData: editData, orderModel: orderModel }))
        },
        [PlacementFlowStep.Verification]: {
            component: (_jsx(Placemat, { children: _jsx(ProvideVerification, { chosenFlow: chosenFlow, inputData: adFormData, setInputData: value => {
                        setAdFormData(value);
                    } }) }))
        },
        [PlacementFlowStep.Content]: {
            component: (_jsx(DraftContent, { adData: adFormData, onAdChange: setAdFormData, newspaperOrdersFormData: newspaperOrdersFormData, onUpdateNewspaperOrdersFormData: setNewspaperOrdersFormData, consolidatedOrderPricing: consolidatedOrderPricing, priceLoading: priceLoading, priceIsStale: priceIsStale, order: orderModel, version: version, canEditContent: isInitialPlacementFlow ||
                    !!(editData && Object.values(editData).every(ed => ed.canEdit)) })),
            onClickNext: () => __awaiter(this, void 0, void 0, function* () {
                var _c;
                /**
                 * Even though we're calculating price on invoice creation, not awaiting a price calculation here could cause a race condition and
                 * overwrite newspaper order data
                 */
                yield handleRecalculateOrderPrice();
                if (originalTotalPriceInCents == null) {
                    setOriginalTotalPriceInCents((_c = consolidatedOrderPricing === null || consolidatedOrderPricing === void 0 ? void 0 : consolidatedOrderPricing.totalInCents) !== null && _c !== void 0 ? _c : null);
                }
                // Don't await this because it takes ~10s to complete
                void handleRequestOrderInvoice();
            })
            /**
             * If we insert a step between the Content & Summary steps, we should recalculate the price in the onClickNext handler
             * We don't currently because we create the invoice in the Summary step, which recalculates the price
             */
        },
        [PlacementFlowStep.Summary]: {
            component: (_jsx(Placemat, { children: _jsx(OrderSummary, { newspaperOrdersFormData: newspaperOrdersFormData, invoiceLoading: isInvoiceLoading, orderModel: orderModel, filingTypeLabel: adFormData.filingTypeName || '', adData: adFormData, product: product, version: version, consolidatedOrderPricing: consolidatedOrderPricing, requireNewInvoice: requireNewInvoice, getRequireNewInvoiceLoading: getRequireNewInvoiceLoading, showSuccessModal: showSuccessModal, originalTotalPriceInCents: originalTotalPriceInCents, onCouponUpdate: handleRefreshOrderPrice }) })),
            onClickNext: () => __awaiter(this, void 0, void 0, function* () {
                if (getRequireNewInvoiceLoading) {
                    return;
                }
                // TODO: Get Stripe threshold for 'free' which will be automatically paid
                if ((consolidatedOrderPricing === null || consolidatedOrderPricing === void 0 ? void 0 : consolidatedOrderPricing.totalInCents) === 0) {
                    setSubmitLoading(true);
                    const { error } = yield apiPostWithParams(`orders/${orderModel.id}/submit-free`, { version });
                    if (error) {
                        logAndCaptureException(ColumnService.OBITS, error, 'Failed to submit free order', { orderId: orderModel.id });
                        setUserError(error);
                        setSubmitLoading(false);
                        return;
                    }
                    setShowSuccessModal(SuccessModalType.FreeOrderSubmission);
                    setSubmitLoading(false);
                    return;
                }
                if (!requireNewInvoice) {
                    setShowSuccessModal(SuccessModalType.EditConfirmation);
                    const { error } = yield apiPostWithParams(`orders/${orderModel.id}/confirm-edits`, { version });
                    if (error) {
                        logAndCaptureException(ColumnService.OBITS, error, 'Failed to confirm order edits', { orderId: orderModel.id });
                    }
                    return;
                }
                if (!invoiceId) {
                    logAndCaptureCriticalError(ColumnService.OBITS, new Error('invoiceId not set'), 'Cannot proceed to payInvoice page because invoiceId is not set', {
                        adId: adModel.id,
                        product,
                        service: ColumnService.OBITS
                    });
                    setUserError('Invoice not found. Please click go to the previous step and try again.');
                    return;
                }
                const { error: invoiceError } = yield finalizeOrderInvoice(invoiceId);
                if (invoiceError) {
                    setUserError(invoiceError.message);
                    return;
                }
                // Open the pay invoice page in the current tab, not a new window
                window.location.href = `${window.location.origin}/invoices/${invoiceId}/pay`;
            })
        }
    };
    const shouldSubmitInsteadOfPay = !requireNewInvoice || (consolidatedOrderPricing === null || consolidatedOrderPricing === void 0 ? void 0 : consolidatedOrderPricing.totalInCents) === 0;
    return (_jsx(FormWrapper, Object.assign({ onSubmit: onSubmit, submitText: steps[currentStep - 1] === PlacementFlowStep.Summary &&
            shouldSubmitInsteadOfPay
            ? 'Submit'
            : currentStep < steps.length || !consolidatedOrderPricing
                ? 'Next'
                : `Pay $${centsToExtendedCurrency(consolidatedOrderPricing.totalInCents)}`, steps: steps, loading: loading, newspaperOrdersFormData: newspaperOrdersFormData, userError: error, product: product }, { children: stepMap[steps[currentStep - 1]].component })));
}
export default PlacementFlowStepSelector;
