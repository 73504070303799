var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-await-in-loop */
import React from 'react';
import moment from 'moment';
import api from 'api';
import { Badge } from 'lib/components/Badge';
import { ColumnButton } from 'lib/components/ColumnButton';
import { InvoiceStatus } from 'lib/enums';
import { ArrowDownTrayIcon, CurrencyDollarIcon, EnvelopeIcon } from '@heroicons/react/24/outline';
import { isPublisherOrganization } from 'lib/utils/organizations';
import { dbToUICurrencyString } from 'lib/pricing/ui';
import { isUnpaidSearchableInvoice } from 'lib/utils/invoices';
import { INVOICE_STATUSES_FUNDS_PENDING } from 'lib/model/objects/invoiceModel';
import { getInvoiceStatus } from './invoiceTableSettingsUtils';
export const downloadInvoice = (e, invoiceId, isBulkInvoice) => __awaiter(void 0, void 0, void 0, function* () {
    e.stopPropagation();
    const { response } = yield api.get(`documents/${isBulkInvoice ? 'bulk-invoice' : 'invoice'}/${invoiceId}/download`);
    if (response) {
        window.open(response);
    }
});
export const getInvoiceBadgeStatus = (invoiceStatus, due_date, voided, amount) => {
    let status = 'critical';
    let color = 'red';
    // in case of invoice has no line items or amount is 0
    if (!amount) {
        status = 'success';
        color = 'green';
    }
    else if (INVOICE_STATUSES_FUNDS_PENDING.includes(invoiceStatus)) {
        status = 'warning';
        color = 'yellow';
    }
    else if (voided) {
        status = undefined;
        color = 'gray';
    }
    else if (invoiceStatus === InvoiceStatus.unpaid.value &&
        moment.utc(due_date * 1000).isBefore(moment.utc())) {
        color = 'red';
        status = 'critical';
    }
    else if (invoiceStatus === InvoiceStatus.unpaid.value) {
        color = 'yellow';
        status = 'warning';
    }
    else if ([InvoiceStatus.paid.value, InvoiceStatus.partially_refunded.value].includes(invoiceStatus)) {
        color = 'green';
        status = 'success';
    }
    return { status, color };
};
export function InvoicesTableRow({ invoice, organization, setSendReminderModalInvoice, setProcessPaymentModalInvoice, usesBulkInvoiceV2 }) {
    const isInvoiceUnpaid = isUnpaidSearchableInvoice(invoice);
    const invoiceBadgeStatus = getInvoiceBadgeStatus(invoice.status, moment.utc(invoice.formattedduedate, 'MM/DD/YYYY').unix(), invoice.voided, invoice.nettotal).status;
    const invoiceRowStatus = getInvoiceStatus(invoice.status, moment.utc(invoice.formattedduedate, 'MM/DD/YYYY').unix(), invoice.voided, invoice.nettotal);
    if (isPublisherOrganization(organization)) {
        return (_jsxs(_Fragment, { children: [_jsx("td", { children: _jsx("div", Object.assign({ className: "flex items-center" }, { children: _jsxs("div", Object.assign({ className: "text-sm font-medium" }, { children: [_jsx("div", Object.assign({ className: "leading-6 text-column-gray-400" }, { children: invoice.filername })), _jsx("div", Object.assign({ className: "text-column-gray-400" }, { children: invoice.invoicenumber }))] })) })) }), !usesBulkInvoiceV2 ? (_jsx("td", { children: _jsx("a", Object.assign({ href: `/notice/${invoice.noticeid}`, target: "_blank", rel: "noreferrer", className: "text-blue-700 underline cursor-pointer" }, { children: invoice.noticename })) })) : (_jsx(_Fragment, {})), _jsx("td", { children: invoice.filerorganizationname || '' }), _jsx("td", { children: moment.utc(invoice.created).format('MM/DD/YYYY') }), _jsxs("td", { children: ["$", dbToUICurrencyString(invoice.nettotal)] }), _jsx("td", { children: _jsx(Badge, Object.assign({ status: invoiceBadgeStatus }, { children: invoiceRowStatus })) }), _jsx("td", { children: invoice.formattedduedate }), _jsxs("td", Object.assign({ className: "flex items-center pt-4 gap-2" }, { children: [_jsx(ColumnButton, { tertiary: true, "aria-label": "Send Reminder", size: "sm", disabled: !isInvoiceUnpaid, onClick: () => {
                                setSendReminderModalInvoice(invoice);
                            }, startIcon: _jsx(EnvelopeIcon, { className: "w-5 py-1 text-column-grey-400" }), type: "button" }), _jsx(ColumnButton, { tertiary: true, "aria-label": "Process Payment", size: "sm", disabled: !isInvoiceUnpaid, onClick: () => {
                                setProcessPaymentModalInvoice(invoice);
                            }, startIcon: _jsx(CurrencyDollarIcon, { className: "w-5 py-1 text-column-grey-400" }), type: "button" }), _jsx(ColumnButton, { tertiary: true, "aria-label": "Download Invoice", size: "sm", onClick: (e) => downloadInvoice(e, invoice.id, !!invoice.isbulkinvoice), startIcon: _jsx(ArrowDownTrayIcon, { className: "w-5 py-1 text-column-grey-400" }), type: "button" })] }))] }));
    }
    return (_jsxs(_Fragment, { children: [_jsx("td", { children: moment.utc(invoice.created).format('MM/DD/YYYY') }), _jsx("td", { children: invoice.invoicenumber }), !usesBulkInvoiceV2 ? (_jsx("td", { children: _jsx("a", Object.assign({ href: `/notice/${invoice.noticeid}`, target: "_blank", rel: "noreferrer", className: "text-blue-700 underline cursor-pointer" }, { children: invoice.noticename })) })) : (_jsx(_Fragment, {})), _jsx("td", { children: invoice.publishername }), _jsxs("td", { children: ["$", dbToUICurrencyString(invoice.nettotal)] }), _jsx("td", { children: _jsx(Badge, Object.assign({ status: invoiceBadgeStatus }, { children: invoiceRowStatus })) }), _jsx("td", { children: invoice.formattedduedate }), _jsxs("td", Object.assign({ className: "flex items-center pt-5 justify-around text-align" }, { children: [_jsx(ColumnButton, { buttonText: 'Pay', secondary: true, size: "sm", onClick: (e) => {
                            e.stopPropagation();
                            window.open(`${window.location.origin}/invoices/${invoice.id}/pay`);
                        }, disabled: !isInvoiceUnpaid, type: "button" }), _jsx("div", { children: _jsx(ColumnButton, { tertiary: true, id: "download-report", size: "sm", onClick: (e) => downloadInvoice(e, invoice.id, !!invoice.isbulkinvoice), startIcon: _jsx(ArrowDownTrayIcon, { className: "w-5 py-1 text-column-grey-400" }), type: "button" }) })] }))] }));
}
