import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ColumnSelect, enumKeyToSelectInput } from 'lib/components/ColumnSelect';
import { TextField } from 'lib/components/TextField';
import { RateType, State } from 'lib/enums';
import { isRateShared } from 'lib/utils/rates';
import { SharedResourceAlert } from 'routes/settings/publisher/sharing/SharedResourceAlert';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import CurrencyTextField from 'lib/components/CurrencyTextField';
import { isNoticeRate } from 'lib/types/rates';
import { hideRatePricingDetailsWhenEditing } from '../../../ratesUtils';
import { rateSupportsDisplayAdsForPublisher, getDefaultBehaviorOptions, getSupportedAdTypes, rateIsFlat, getRateAmountLabel } from '../rateUpdateFormUtils';
import { RunBasedRates } from './RunBasedRates';
import ImageRateSettings from './ImageRateSettings';
/**
 * Determines the value for the "How do you want to charge per run?" select
 * @param {AdRate} rate rate to get the select value for
 * @returns {string} select value
 */
const getPerRunChargeSelectValue = (rate) => {
    if (rate.rateType === RateType.flat.value)
        return 'flat';
    if (rate.rateType === RateType.per_run.value)
        return 'per_run';
    if (rate.runBased)
        return 'run-based';
    return 'non-run-based';
};
/**
 * We collapse "per run" and "flat" rates in the UI into one option
 * @param {AdRate} rate rate to get the display value for
 * @returns {number} the rate type value to display in the UI
 */
const getDisplayRateTypeValueFromRateData = (rate) => {
    if (rate.rateType === RateType.per_run.value)
        return RateType.flat.value;
    return rate.rateType;
};
/**
 * Show different options for rate types to publishers based on which state they are in
 */
const getRateTypeOptionsForPublisher = (publisher) => {
    const rateOptions = [
        enumKeyToSelectInput(RateType.flat),
        enumKeyToSelectInput(RateType.folio),
        enumKeyToSelectInput(RateType.word_count),
        enumKeyToSelectInput(RateType.line),
        enumKeyToSelectInput(RateType.column_inch)
    ];
    if (publisher.data().state === State.oklahoma.value) {
        rateOptions.push(enumKeyToSelectInput(RateType.oklahoma));
    }
    if (publisher.data().state === State.iowa.value) {
        rateOptions.push(enumKeyToSelectInput(RateType.iowa_form));
    }
    return rateOptions;
};
export default function BasicPropertiesCard({ activeOrganization, onUpdateRateData, setDefaultBehavior, updatedRateData, defaultBehavior, rateRef }) {
    var _a, _b, _c, _d;
    const isEditingNoticeRate = isNoticeRate(updatedRateData);
    const supportedAdTypes = getSupportedAdTypes(rateRef === null || rateRef === void 0 ? void 0 : rateRef.id, activeOrganization, updatedRateData);
    const defaultBehaviorOptions = getDefaultBehaviorOptions(activeOrganization, updatedRateData, rateRef === null || rateRef === void 0 ? void 0 : rateRef.id);
    const showPricingDetails = !hideRatePricingDetailsWhenEditing(updatedRateData);
    const isFlatRate = rateIsFlat(updatedRateData);
    const showRunBased = !isFlatRate && !hideRatePricingDetailsWhenEditing(updatedRateData);
    const showRoundOff = updatedRateData.rateType === RateType.column_inch.value;
    return (_jsxs(CardGridLayout, Object.assign({ header: {
            title: 'Basic Properties',
            description: 'Configure basic properties for this rate.'
        } }, { children: [isRateShared(updatedRateData) && (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(SharedResourceAlert, { resourceNoun: "rate", numOrganizations: ((_b = (_a = updatedRateData.publisherOrganizations) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) + 1 }) }))), _jsx(GridInput, { children: _jsx(TextField, { id: "rate-name", labelText: "Rate name", required: true, value: updatedRateData.description, onChange: newValue => onUpdateRateData(Object.assign(Object.assign({}, updatedRateData), { description: newValue })) }) }), _jsx(GridInput, { children: _jsx(ColumnSelect, { id: "rate-type", labelText: "How do you calculate this rate?", options: getRateTypeOptionsForPublisher(activeOrganization), onChange: newValue => {
                        const newRateType = RateType.by_value(parseInt(newValue, 10));
                        if (!newRateType)
                            return;
                        onUpdateRateData(Object.assign(Object.assign(Object.assign(Object.assign({}, updatedRateData), { rateType: newRateType.value }), (newRateType === RateType.flat ? { runBased: false } : {})), (newRateType !== RateType.column_inch
                            ? { roundOff: null }
                            : {})));
                    }, value: getDisplayRateTypeValueFromRateData(updatedRateData).toString() }) }), isEditingNoticeRate && (_jsxs(_Fragment, { children: [_jsx(GridInput, { children: _jsx(ColumnSelect, { id: "display-ad-support", labelText: "Does this rate support display notices?", options: supportedAdTypes, onChange: newValue => {
                                onUpdateRateData(Object.assign(Object.assign({}, updatedRateData), { supportsDisplay: newValue === 'true' }));
                            }, value: rateSupportsDisplayAdsForPublisher(rateRef === null || rateRef === void 0 ? void 0 : rateRef.id, activeOrganization, updatedRateData).toString() }) }), _jsx(GridInput, { children: _jsx(ColumnSelect, { id: "rate-default-behavior-select", labelText: "Is this the newspaper's default rate?", options: defaultBehaviorOptions, onChange: newValue => setDefaultBehavior(newValue), value: defaultBehavior }) })] })), showPricingDetails && (_jsxs(_Fragment, { children: [_jsx(GridInput, { children: _jsx(CurrencyTextField, { id: "minimum-all-runs", labelText: "Minimum charge (all runs)", initialValue: updatedRateData.minimum, onChange: newValue => onUpdateRateData(Object.assign(Object.assign({}, updatedRateData), { minimum: newValue })) }) }), _jsx(GridInput, { children: _jsx(CurrencyTextField, { id: "minimum-per-run", labelText: "Minimum charge (per run)", initialValue: updatedRateData.runMinimumInCents, onChange: newValue => onUpdateRateData(Object.assign(Object.assign({}, updatedRateData), { runMinimumInCents: newValue })) }) }), isFlatRate && (_jsx(GridInput, { children: _jsx(ColumnSelect, { id: "flat-rate-run-based", labelText: "Charge the flat rate per run?", options: [
                                {
                                    value: 'flat',
                                    label: 'No — charge the flat rate once'
                                },
                                {
                                    value: 'per_run',
                                    label: 'Yes — charge the flat rate per run'
                                }
                            ], onChange: newValue => onUpdateRateData(Object.assign(Object.assign({}, updatedRateData), { rateType: newValue === 'per_run'
                                    ? RateType.per_run.value
                                    : RateType.flat.value, additionalRates: null })), value: getPerRunChargeSelectValue(updatedRateData) }) })), showRunBased && (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(ColumnSelect, { id: "run-based", labelText: "Does the price vary per run?", options: [
                                {
                                    value: 'non-run-based',
                                    label: 'No — charge the same price per run'
                                },
                                {
                                    value: 'run-based',
                                    label: 'Yes — charge different prices per run'
                                }
                            ], onChange: newValue => onUpdateRateData(Object.assign(Object.assign({}, updatedRateData), { runBased: newValue === 'run-based', rate_1: updatedRateData.rate_0, rate_2: updatedRateData.rate_0, additionalRates: null })), value: getPerRunChargeSelectValue(updatedRateData) }) }))), !updatedRateData.runBased && (
                    // fullWidth is conditional in order for components to fit
                    // as closely to each other as possible within the grid
                    _jsx(GridInput, Object.assign({ fullWidth: showRunBased && !showRoundOff }, { children: _jsx(CurrencyTextField, { id: "fixed-rate-rate-input", labelText: getRateAmountLabel(updatedRateData.product, updatedRateData.rateType), initialValue: updatedRateData.rate_0, onChange: newValue => onUpdateRateData(Object.assign(Object.assign({}, updatedRateData), { rate_0: newValue, rate_1: newValue, rate_2: newValue })) }) }))), updatedRateData.runBased && (_jsx(RunBasedRates, { rateType: updatedRateData.rateType, value: updatedRateData, onChange: updates => onUpdateRateData(Object.assign(Object.assign({}, updatedRateData), updates)) }))] })), showRoundOff && (_jsx(GridInput, { children: _jsx(TextField, { id: "rate-round-off", labelText: _jsxs(_Fragment, { children: ["Round off (to the nearest ", _jsx("i", { children: "n" }), " column inch)"] }), value: (_d = (_c = updatedRateData.roundOff) === null || _c === void 0 ? void 0 : _c.toString()) !== null && _d !== void 0 ? _d : undefined, type: "number", min: 0, step: 0.01, onChange: newValue => {
                        onUpdateRateData(Object.assign(Object.assign({}, updatedRateData), { roundOff: Number(newValue) }));
                    } }) })), _jsx(ImageRateSettings, { activeOrganization: activeOrganization, updatedRateData: updatedRateData, onUpdateRateData: onUpdateRateData })] })));
}
