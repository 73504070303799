import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import LinkTo from 'components/LinkTo';
import { Popover } from 'lib/components/Popover';
import HeaderNavBarLink from './HeaderNavBarLink';
/**
 * Navbar popover in the header to items like orders, pagination, etc.
 */
export default function HeaderNavBarPopover({ label, icon, items, id }) {
    const isActive = !!items.find(item => window.location.pathname.startsWith(item.path.split('?')[0]));
    const enabledItems = items.filter(item => item.enabled);
    const popoverHasOneItemEnabeld = enabledItems.length === 1;
    const firstEnabledItem = items.find(item => item.enabled);
    return (_jsx("div", Object.assign({ className: "flex" }, { children: popoverHasOneItemEnabeld && firstEnabledItem ? (_jsx(HeaderNavBarLink, { path: firstEnabledItem.path, label: label, icon: icon, id: firstEnabledItem.id })) : (_jsx(Popover, Object.assign({ id: `${id}-popover`, activator: _jsx("button", Object.assign({ id: `${id}-popover-activator`, className: classNames('flex font-medium h-full p-3 cursor-pointer hover:bg-primary-50 hover:text-primary-500 rounded-md', {
                    'text-column-gray-400': !isActive,
                    'text-primary-500': isActive
                }), type: "button" }, { children: _jsx("div", Object.assign({ className: "flex flex-wrap items-center text-center" }, { children: _jsxs(_Fragment, { children: [_jsx("span", Object.assign({ "aria-hidden": "true", className: "mr-2" }, { children: icon })), _jsx("span", Object.assign({ className: "w-max" }, { children: label })), _jsx("span", Object.assign({ "aria-hidden": "true" }, { children: _jsx(ChevronDownIcon, { className: "w-3 h-3 ml-1" }) }))] }) })) })), alignment: "left" }, { children: _jsx("div", Object.assign({ className: "w-40" }, { children: enabledItems.map(item => {
                    const isItemActive = window.location.href.includes(item.path);
                    return (_jsx(LinkTo, Object.assign({ id: item.id, href: item.path, className: classNames('flex font-medium h-full p-3 cursor-pointer hover:bg-primary-50 hover:text-primary-500 rounded-md', {
                            'text-column-gray-400': !isItemActive,
                            'text-primary-500': isItemActive
                        }) }, { children: _jsx("span", Object.assign({ className: "ml-2" }, { children: item.label })) }), item.id));
                }) })) }))) })));
}
