var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { ColumnService } from 'lib/services/directory';
import { getFirebaseContext } from 'utils/firebase';
import { SelectAdjudicationAreaDropdown } from './SelectAdjudicationAreaDropdown';
export function SelectAdjudicationCountyDropdown({ selectedState, selectedCounty, onCountySelected }) {
    const { value: countyOptions, isLoading: loadingAreas, isError: error } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            if (!selectedState) {
                return null;
            }
            const stateRef = getFirebaseContext()
                .adjudicationAreasRef()
                .doc(selectedState.id);
            const countiesFromState = yield getFirebaseContext()
                .adjudicationAreasRef()
                .where('type', '==', 'county')
                .where('parent', '==', stateRef)
                .orderBy('name')
                .get();
            return countiesFromState.docs.map(doc => (Object.assign({ id: doc.id }, doc.data())));
        }),
        dependencies: [selectedState === null || selectedState === void 0 ? void 0 : selectedState.id],
        errorConfig: {
            message: 'Failed to load adjudication areas in placement flow',
            service: ColumnService.WEB_PLACEMENT
        }
    });
    function onChangeHandler(areaId) {
        const objectWithinList = countyOptions === null || countyOptions === void 0 ? void 0 : countyOptions.find(county => county.id === areaId);
        if (!objectWithinList)
            return;
        onCountySelected(objectWithinList);
    }
    if (!selectedState)
        return null;
    if (loadingAreas)
        return _jsx(LoadingSpinner, {});
    return (_jsx(SelectAdjudicationAreaDropdown, { selectedState: selectedCounty, areaOptions: countyOptions, onChange: onChangeHandler, label: "County", placeholder: "Select a county", loading: loadingAreas, error: error }));
}
