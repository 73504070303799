var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { wrapError, wrapSuccess } from 'lib/types/responses';
import { ProductSiteSettingModel } from 'lib/model/objects/productSiteSettingModel';
import { logWarn } from 'utils/logger';
import api from 'api';
import { BadRequestError, InternalServerError, NotFoundError, wrapErrorAsColumnError } from 'lib/errors/ColumnErrors';
import { getFirebaseContext } from 'utils/firebase';
import { getModelFromSnapshot } from 'lib/model';
import { safeGetOrThrow } from 'lib/safeWrappers';
export const updateSiteSetting = (newspaperId, siteSettingModel, siteSettingData) => __awaiter(void 0, void 0, void 0, function* () {
    const { error: updateError } = yield api.safePostWithParams(`site-settings/${siteSettingModel.id}`, {
        newspaperId,
        productSiteSetting: siteSettingData
    });
    if (updateError !== null) {
        logWarn(`Failed to save site settings: ${updateError}`, {
            newspaperId,
            productLine: siteSettingModel.modelData.product
        });
        return wrapError(new BadRequestError(updateError));
    }
    yield siteSettingModel.refreshData();
    return wrapSuccess(siteSettingModel);
});
export const createSiteSetting = (newspaperId, siteSettingData) => __awaiter(void 0, void 0, void 0, function* () {
    const newspaperRef = getFirebaseContext().organizationsRef().doc(newspaperId);
    const logData = {
        newspaperId,
        productLine: siteSettingData.product
    };
    const { response: createResult, error: createError } = yield api.safePost('site-settings', {
        newspaperId,
        productSiteSetting: siteSettingData
    });
    if (createError !== null) {
        logWarn(`Failed to save site settings: ${createError}`, logData);
        return wrapError(new InternalServerError(createError));
    }
    const { response: productSiteSettingSnap, error: getError } = yield safeGetOrThrow(getFirebaseContext()
        .organizationProductSiteSettingsRef(newspaperRef)
        .doc(createResult.productSiteSettingId));
    if (getError) {
        logWarn(`Failed to get site settings: ${getError.message}`, logData);
        return wrapErrorAsColumnError(getError, NotFoundError);
    }
    const newSiteSetting = getModelFromSnapshot(ProductSiteSettingModel, getFirebaseContext(), productSiteSettingSnap);
    return wrapSuccess(newSiteSetting);
});
