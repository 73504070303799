var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import LoadingState from 'components/LoadingState';
import { Alert } from 'lib/components/Alert';
import { Product } from 'lib/enums';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { ColumnService } from 'lib/services/directory';
import { isAdvertiserWithOrganizationOrder } from 'lib/types/order';
import { useState } from 'react';
import AuthActions, { selectAuthLoading, selectIsPublisher, selectUser } from 'redux/auth';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { OrderModel } from 'lib/model/objects/orderModel';
import { getModelFromId } from 'lib/model';
import { getFirebaseContext } from 'utils/firebase';
import { CustomerTypeString } from '../filters/helpers';
import FormWrapper from './FormWrapper';
import PlacementFlowStepSelector from './PlacementFlowStepSelector';
import Placemat from './Placemat';
import ResumeOrder from './ResumeOrder';
import { useAdForm } from './contexts/AdFormStatusProvider';
import { createOrder, getLoadOrderEmail, getOrderToken } from './helpers/orderOperations';
import ClassifiedPlacementFlowHelper from './helpers/placementFlowHelper/classifiedPlacementFlowHelper';
import ObituaryPlacementFlowHelper from './helpers/placementFlowHelper/obituaryPlacementFlowHelper';
import { PlacementFlowStep } from './placementFlowStep';
import PersonalDetail, { MINIMUM_ORDER } from './steps/PersonalDetail';
const stepsByFlow = {
    [CustomerTypeString.FUNERAL_HOME]: [
        PlacementFlowStep.Verification,
        PlacementFlowStep.Publication,
        PlacementFlowStep.Schedule,
        PlacementFlowStep.Content,
        PlacementFlowStep.Summary
    ],
    [CustomerTypeString.INDIVIDUAL]: [
        PlacementFlowStep.Details,
        PlacementFlowStep.Publication,
        PlacementFlowStep.Schedule,
        PlacementFlowStep.Verification,
        PlacementFlowStep.Content,
        PlacementFlowStep.Summary
    ],
    [Product.Classified]: [
        PlacementFlowStep.Details,
        PlacementFlowStep.Category,
        PlacementFlowStep.Publication,
        PlacementFlowStep.Schedule,
        PlacementFlowStep.Content,
        PlacementFlowStep.Summary
    ]
};
export default function AdPlacementFlow({ claim, orderDraftId, product }) {
    var _a, _b;
    const context = getFirebaseContext();
    const dispatch = useAppDispatch();
    const { updateCurrentStep } = useAdForm();
    const user = useAppSelector(selectUser);
    const isPublisher = useAppSelector(selectIsPublisher);
    const isAuthLoading = useAppSelector(selectAuthLoading);
    const [orderFormData, setOrderFormData] = useState(Object.assign({}, MINIMUM_ORDER));
    const [anonymousOrderCreationRequested, setAnonymousOrderCreationRequested] = useState(false);
    const isDraftFlow = !!orderDraftId;
    const draftId = orderDraftId || claim;
    const placementFlowHelper = product === Product.Classified
        ? new ClassifiedPlacementFlowHelper()
        : new ObituaryPlacementFlowHelper();
    const { value: createdOrderId, isError: failedToCreateOrder } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            if (!draftId && user) {
                const { response: order, error: createAdError } = yield placementFlowHelper.createAd(user);
                if (createAdError) {
                    return;
                }
                return order.id;
            }
        }),
        errorConfig: {
            service: ColumnService.OBITS,
            message: 'Failed to create order model',
            tags: {
                userId: (user === null || user === void 0 ? void 0 : user.id) || ''
            }
        },
        dependencies: [user === null || user === void 0 ? void 0 : user.id]
    });
    const orderId = createdOrderId || draftId;
    const { value, isError: failedToLoad } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            if (!orderId) {
                return null;
            }
            const orderModel = yield getModelFromId(OrderModel, context, context.ordersRef(), orderId);
            const newspaperOrderModels = yield orderModel.getNewspaperOrders();
            const newspaperOrders = newspaperOrderModels.map(newspaperOrderModel => newspaperOrderModel.modelData);
            const adModelResult = yield placementFlowHelper.getAdModel(orderModel);
            if (adModelResult.error) {
                throw adModelResult.error;
            }
            return { orderModel, newspaperOrders, adModel: adModelResult.response };
        }),
        errorConfig: {
            service: ColumnService.OBITS,
            message: 'Failed to retrieve order model',
            tags: {
                orderId,
                claim
            }
        },
        dependencies: [orderId]
    });
    if (failedToCreateOrder || failedToLoad) {
        return _jsx(Alert, { title: "Loading failure", id: "loading-failure-error" });
    }
    const { orderModel, adModel, newspaperOrders } = value !== null && value !== void 0 ? value : {};
    const chosenFlow = product === Product.Classified
        ? Product.Classified
        : orderModel && isAdvertiserWithOrganizationOrder(orderModel.modelData)
            ? CustomerTypeString.FUNERAL_HOME
            : CustomerTypeString.INDIVIDUAL;
    const steps = stepsByFlow[chosenFlow];
    const getPlacementFlowSteps = () => {
        if (isPublisher && !isDraftFlow && chosenFlow !== Product.Classified) {
            return [PlacementFlowStep.CustomerType, ...steps];
        }
        if (chosenFlow === Product.Classified && !isPublisher) {
            return steps.includes(PlacementFlowStep.Details) ? steps.slice(1) : steps;
        }
        return steps;
    };
    if (orderModel && adModel) {
        return (_jsx(PlacementFlowStepSelector, { orderModel: orderModel, adModel: adModel, draftNewspaperOrders: newspaperOrders, steps: getPlacementFlowSteps(), version: orderModel.modelData.activeVersion, product: product, editData: undefined }));
    }
    const login = (accessCode, email) => __awaiter(this, void 0, void 0, function* () {
        const { token } = yield getOrderToken(accessCode, email);
        dispatch(AuthActions.loginToken(token));
        updateCurrentStep(2);
    });
    const loadOrderEmail = getLoadOrderEmail();
    if (loadOrderEmail) {
        if (claim) {
            dispatch(AuthActions.logout());
        }
        return (_jsx(Placemat, { children: _jsx(ResumeOrder, { email: loadOrderEmail, login: login }) }));
    }
    const isAnonymousFlow = !user && !isAuthLoading;
    if (isAnonymousFlow && !anonymousOrderCreationRequested) {
        return (_jsx(FormWrapper, Object.assign({ onSubmit: () => __awaiter(this, void 0, void 0, function* () {
                // We never disable this because we'll be in a different block once this is done
                setAnonymousOrderCreationRequested(true);
                try {
                    const { contactEmail, firstName, lastName, phone } = Object.assign(Object.assign({}, MINIMUM_ORDER), orderFormData);
                    const accessCode = yield createOrder({
                        contactEmail,
                        firstName,
                        lastName,
                        phone,
                        product
                    });
                    yield login(accessCode, contactEmail);
                }
                finally {
                }
            }), submitText: "Next", steps: steps, loading: anonymousOrderCreationRequested, newspaperOrdersFormData: [], userError: "", product: product }, { children: _jsx(Placemat, { children: _jsx(PersonalDetail, { inputData: orderFormData, setInputData: setOrderFormData }) }) })));
    }
    return (_jsx(LoadingState, { context: {
            service: ColumnService.OBITS,
            location: 'Ad placement',
            tags: {
                isAnonymousFlow: isAnonymousFlow ? 'true' : 'false',
                order: (_a = orderModel === null || orderModel === void 0 ? void 0 : orderModel.id) !== null && _a !== void 0 ? _a : 'undefined',
                adModel: (_b = adModel === null || adModel === void 0 ? void 0 : adModel.id) !== null && _b !== void 0 ? _b : 'undefined',
                product,
                adPlacementFlow: 'true'
            }
        } }));
}
