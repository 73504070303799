var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CouponStatus } from '../types/coupon';
import { BaseService } from '.';
import { wrapError, wrapSuccess } from '../types/responses';
import { CouponModel } from '../model/objects/couponModel';
import { safeGetModelArrayFromQuery, safeGetModelFromRef } from '../model/getModel';
import { BadRequestError, InternalServerError, NotFoundError, wrapErrorAsColumnError } from '../errors/ColumnErrors';
import { ColumnService } from './directory';
import { getErrorReporter } from '../utils/errors';
export class CouponService extends BaseService {
    constructor(ctx) {
        super(ctx, ctx.couponsRef());
    }
    isValidCouponData(data) {
        if (data.isGlobal && data.automaticallyApply) {
            const err = new BadRequestError('Cannot set automaticallyApply with isGlobal: true');
            getErrorReporter().logAndCaptureError(ColumnService.PAYMENTS, err, 'Coupon data validation failed', { couponCode: data.code });
            return wrapError(err);
        }
        return wrapSuccess(undefined);
    }
    createCoupon(data) {
        const _super = Object.create(null, {
            create: { get: () => super.create }
        });
        return __awaiter(this, void 0, void 0, function* () {
            const [validationError] = this.isValidCouponData(data);
            if (validationError) {
                return wrapError(validationError);
            }
            const { response: couponRef, error: couponError } = yield _super.create.call(this, data);
            if (couponError) {
                return wrapError(couponError);
            }
            const { response: coupon, error: modelError } = yield safeGetModelFromRef(CouponModel, this.ctx, couponRef);
            if (modelError) {
                return wrapError(modelError);
            }
            return wrapSuccess(coupon);
        });
    }
    getCouponByCode(couponCode) {
        return __awaiter(this, void 0, void 0, function* () {
            const { response: couponResults, error: queryError } = yield this.searchCoupons({
                code: couponCode
            });
            if (queryError) {
                getErrorReporter().logAndCaptureCriticalError(ColumnService.DATABASE, queryError, 'Failed to query coupopns', { couponCode });
                return wrapErrorAsColumnError(queryError, InternalServerError);
            }
            if (couponResults.length === 0) {
                return wrapError(new NotFoundError('Coupon not found'));
            }
            if (couponResults.length > 1) {
                const err = new InternalServerError('Too many coupons match coupon code');
                getErrorReporter().logAndCaptureCriticalError(ColumnService.PAYMENTS, err, 'Failed to get coupon by code due to too many matches', { couponCode });
                return wrapError(err);
            }
            const coupon = couponResults[0];
            return wrapSuccess(coupon);
        });
    }
    searchCoupons(params) {
        return __awaiter(this, void 0, void 0, function* () {
            const { code, newspaperId } = params;
            let query = this.ctx.couponsRef();
            if (code && newspaperId) {
                const err = new BadRequestError('Cannot search by both code and newspaperId');
                getErrorReporter().logAndCaptureCriticalError(ColumnService.PAYMENTS, err, 'Failed to search coupons', Object.assign({}, params));
                return wrapError(err);
            }
            if (code) {
                query = query.where('code', '==', code);
            }
            if (newspaperId) {
                const newspaperRef = this.ctx.organizationsRef().doc(newspaperId);
                query = query.where('newspapers', 'array-contains', newspaperRef);
            }
            const { response: coupons, error: queryError } = yield safeGetModelArrayFromQuery(CouponModel, this.ctx, query);
            if (queryError) {
                getErrorReporter().logAndCaptureCriticalError(ColumnService.DATABASE, queryError, 'Failed to query coupons', Object.assign({}, params));
                return wrapErrorAsColumnError(queryError, InternalServerError);
            }
            const filteredCoupons = coupons.filter(coupon => coupon.modelData.status !== CouponStatus.DELETED);
            return wrapSuccess(filteredCoupons);
        });
    }
}
