var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { useFetchAvailablePublishers } from 'hooks/useFetchAvailablePublishers';
import { Autocomplete } from 'lib/components/Autocomplete';
import { Product } from 'lib/enums';
import { useState, useContext, useEffect } from 'react';
import { PublisherLocationFilter } from 'routes/placeScroll/ConfirmPublisher/PublisherLocationFilter';
import { getFirebaseContext } from 'utils/firebase';
import { NewspaperOrderStatus } from 'lib/types/newspaperOrder';
import { PublishingMedium } from 'lib/enums/PublishingMedium';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { getModelFromSnapshot } from 'lib/model';
import { getOrThrow } from 'lib/utils/refs';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { isAnonymousUserOrder, isPublisherOrder } from 'lib/types/order';
import { Alert } from 'lib/components/Alert';
import { logAndCaptureException } from 'utils';
import { getPublisherOrgOrderTemplate } from 'lib/utils/templates';
import { ColumnService } from 'lib/services/directory';
import { useFetchSubdomainAssociatedPapers } from 'hooks/useFetchSubdomainAssociatedPapers';
import LoadingState from 'components/LoadingState';
import { groupBy } from 'lodash';
import { ProductPublishingSettingsService } from 'lib/services/productPublishingSettingsService';
import { asyncFilter, asyncMap, isDefined } from 'lib/helpers';
import { wrapError, wrapSuccess } from 'lib/types/responses';
import { NotFoundError } from 'lib/errors/ColumnErrors';
import useSafeAsyncEffect from 'lib/frontend/hooks/useSafeAsyncEffect';
import { isTemplateValidationIssue, validateNewspapersAndFilingTypes } from './validation';
import MultiStepHeader from '../../components/MultiStepHeader';
import { NewspapersContext } from '../../contexts/NewspapersContext';
import { shouldExcludeNewspaperOrder } from '../CategoryChoice/helpers';
import PublisherBadge from './PublisherBadge';
import PublisherCard from './PublisherCard';
const ALL_MEDIUMS = Object.values(PublishingMedium);
const getValidPublishingMediums = (newspaper, product, isUserPublisher) => __awaiter(void 0, void 0, void 0, function* () {
    const productPublishingSettingService = new ProductPublishingSettingsService(getFirebaseContext());
    const mediums = yield asyncFilter(ALL_MEDIUMS, (medium) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const result = yield productPublishingSettingService.fetchOrCreateDetailedProductPublishingSetting(newspaper, product, medium, {
            shouldCreate: false
        });
        // It's fine if the settings aren't there; we only care about other errors.
        if (result.error && !(result.error instanceof NotFoundError)) {
            return result;
        }
        const detailedProductPublishingSetting = result.response;
        if (!detailedProductPublishingSetting ||
            detailedProductPublishingSetting.filingTypes.every(filingType => !filingType.isVisibleToUser(isUserPublisher)) ||
            detailedProductPublishingSetting.publishingSetting.modelData.deadlines.every(deadline => !deadline.publish)) {
            return wrapSuccess(null);
        }
        return wrapSuccess(((_a = result.response) === null || _a === void 0 ? void 0 : _a.productPublishingSetting.modelData.publishingMedium) ||
            null);
    }));
    return mediums;
});
const getFilingTypeForClassified = (organizationModel, selectedFilingType, anonymousOrder) => __awaiter(void 0, void 0, void 0, function* () {
    const validFilingTypesResult = yield asyncFilter(ALL_MEDIUMS, (medium) => __awaiter(void 0, void 0, void 0, function* () {
        const { response: matchedFilingType, error: filingTypeMatchError } = yield organizationModel.isFilingTypeAvailableForNewspaper({
            selectedFilingType,
            product: Product.Classified,
            publishingMedium: medium,
            anonymousOrder
        });
        const result = !matchedFilingType ||
            shouldExcludeNewspaperOrder({
                matchedFilingType,
                filingTypeMatchError,
                anonymousOrder
            })
            ? null
            : [medium, matchedFilingType];
        return wrapSuccess(result);
    }));
    if (validFilingTypesResult.error) {
        return validFilingTypesResult;
    }
    const validFilingTypes = Object.fromEntries(validFilingTypesResult.response);
    return wrapSuccess(validFilingTypes);
});
function getNewspaperOrder(product, newspaper, publishingMedium, anonymousOrder, filingTypeName) {
    return __awaiter(this, void 0, void 0, function* () {
        const { response: adTemplate, error } = yield getPublisherOrgOrderTemplate(getFirebaseContext(), newspaper.ref, product, publishingMedium);
        if (error) {
            logAndCaptureException(ColumnService.OBITS, error, 'Unable to get the template for order', { newspaperId: newspaper.id, product });
            return wrapError(error);
        }
        let classifiedFilingType;
        if (product === Product.Classified) {
            const selectedFilingType = filingTypeName;
            const validFilingTypesResult = yield getFilingTypeForClassified(newspaper, selectedFilingType, anonymousOrder);
            if (validFilingTypesResult.error) {
                return validFilingTypesResult;
            }
            classifiedFilingType = validFilingTypesResult.response[publishingMedium];
        }
        return wrapSuccess(Object.assign({ newspaper: newspaper.ref, adTemplate: (classifiedFilingType === null || classifiedFilingType === void 0 ? void 0 : classifiedFilingType.modelData.template) || adTemplate, publishingDates: [], status: NewspaperOrderStatus.DRAFT, publishingMedium }, (classifiedFilingType
            ? {
                filingType: classifiedFilingType.ref,
                layout: classifiedFilingType.supportedLayouts[0]
            }
            : {})));
    });
}
function SelectPublication({ newspaperOrdersFormData, onNewspaperOrdersFormDataChange, product, inputData, orderModel }) {
    const productTypeName = PRODUCT_TO_NAME[product].singular.toLowerCase();
    const context = getFirebaseContext();
    const organizations = context.organizationsRef();
    const newspaperOrdersWithMedium = newspaperOrdersFormData.filter((no) => isDefined(no.publishingMedium));
    const newspaperOrdersByNewspaperId = groupBy(newspaperOrdersWithMedium, no => { var _a; return (_a = no.newspaper) === null || _a === void 0 ? void 0 : _a.id; });
    const [searchedNewspaperId, setSearchedNewspaperId] = useState(undefined);
    const [addNewspaperAlert, setAddNewspaperAlert] = useState('');
    const anonymousOrder = isAnonymousUserOrder(orderModel.modelData);
    const newspapers = useContext(NewspapersContext);
    const searchedNewspaperLoaded = newspapers.find(o => o.id === searchedNewspaperId);
    const { loading: availablePublishersLoading, stateOptions, stateFilter, setStateFilter, publisherOptions } = useFetchAvailablePublishers({
        restrictedSingleState: undefined,
        isUserPublisher: false,
        restrictedPublisherIds: [],
        product
    });
    const { loading: relatedPublishersLoading, relatedPublisherOptions, autoSelectedPaper } = useFetchSubdomainAssociatedPapers(product, stateFilter);
    const [validMediumsByNewspaperId, setValidMediumsByNewspaperId] = useState({});
    const { isLoading: isLoadingValidMediums } = useSafeAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            const validMediumsForExistingPapersResult = yield asyncMap(newspaperOrdersFormData, (o) => __awaiter(this, void 0, void 0, function* () {
                if (!o.newspaper) {
                    return wrapError(Error('Newspaper not found'));
                }
                const validMediumsForPaper = yield getValidPublishingMediums(o.newspaper, product, isPublisherOrder(orderModel.modelData));
                if (validMediumsForPaper.error) {
                    return validMediumsForPaper;
                }
                return wrapSuccess([o.newspaper.id, validMediumsForPaper.response]);
            }));
            if (validMediumsForExistingPapersResult.error) {
                return validMediumsForExistingPapersResult;
            }
            const validMediumsForExistingPapers = Object.fromEntries(validMediumsForExistingPapersResult.response);
            setValidMediumsByNewspaperId(validMediumsForExistingPapers);
            return wrapSuccess(validMediumsForExistingPapers);
        }),
        dependencies: []
    });
    const loading = relatedPublishersLoading ||
        availablePublishersLoading ||
        isLoadingValidMediums;
    function addNewspaper(newspaperId) {
        return __awaiter(this, void 0, void 0, function* () {
            if (newspaperOrdersByNewspaperId[newspaperId]) {
                return;
            }
            const newspaperRef = organizations.doc(newspaperId);
            const result = yield getValidPublishingMediums(newspaperRef, product, isPublisherOrder(orderModel.modelData));
            if (result.error) {
                setAddNewspaperAlert('Failed to get valid publishing mediums for newspaper.');
                return;
            }
            let publishingMediums = result.response;
            setValidMediumsByNewspaperId(Object.assign(Object.assign({}, validMediumsByNewspaperId), { [newspaperId]: publishingMediums }));
            const newspaperSnapshot = yield getOrThrow(newspaperRef);
            const organizationModel = getModelFromSnapshot(OrganizationModel, context, newspaperSnapshot);
            // We need the filing type model out of the block below
            // TODO: refactor this with obit category select unification. We should move this into a helper to set layout and filing type for a newspaper order.
            let availableClassifiedFilingTypesByMedium = {};
            // Check if newspaper supports category (aka filing type) selected. We only need to do this for classifieds
            if (product === Product.Classified && inputData.filingTypeName) {
                const selectedFilingType = inputData.filingTypeName;
                const validFilingTypesResult = yield getFilingTypeForClassified(organizationModel, selectedFilingType, anonymousOrder);
                if (validFilingTypesResult.error) {
                    setAddNewspaperAlert('Failed to validate filing types for newspaper.');
                    return;
                }
                availableClassifiedFilingTypesByMedium = validFilingTypesResult.response;
                publishingMediums = publishingMediums.filter(medium => Object.keys(availableClassifiedFilingTypesByMedium).includes(medium));
                /*
                 * Set add error alert if filing type is unavailable for selected newspaper.
                 */
                if (!Object.keys(publishingMediums).length) {
                    setAddNewspaperAlert(`${organizationModel.modelData.name} selected does not support ${inputData.filingTypeName}. Please select another publisher.`);
                    return;
                }
                const validationStatus = yield validateNewspapersAndFilingTypes({
                    newspaperOrders: [
                        ...newspaperOrdersFormData,
                        ...Object.keys(availableClassifiedFilingTypesByMedium).map(medium => ({
                            newspaper: newspaperRef,
                            publishingMedium: medium
                        }))
                    ],
                    selectedFilingTypeLabel: selectedFilingType,
                    product,
                    anonymousOrder
                });
                if (!validationStatus.isValid) {
                    const alertMessage = isTemplateValidationIssue(validationStatus.details)
                        ? `The following publications have templates for ${inputData.filingTypeName} ads that do not support placing in multiple publications: ${validationStatus.details.papersWithTemplatedFilingTypes.join(', ')}`
                        : 'An unexpected error occurred while validating selected publishers. Please try again.';
                    setAddNewspaperAlert(alertMessage);
                    return;
                }
                setAddNewspaperAlert('');
            }
            const newOrdersResult = yield asyncMap(publishingMediums, publishingMedium => getNewspaperOrder(product, organizationModel, publishingMedium, anonymousOrder, inputData.filingTypeName));
            if (newOrdersResult.error) {
                setAddNewspaperAlert('Failed to add newspaper');
                return;
            }
            onNewspaperOrdersFormDataChange([
                ...newspaperOrdersFormData,
                ...newOrdersResult.response
            ]);
            setSearchedNewspaperId(newspaperId);
        });
    }
    function removeNewspaper(newspaperId) {
        return __awaiter(this, void 0, void 0, function* () {
            onNewspaperOrdersFormDataChange(newspaperOrdersFormData.filter(no => { var _a; return ((_a = no.newspaper) === null || _a === void 0 ? void 0 : _a.id) !== newspaperId; }));
        });
    }
    useEffect(() => {
        if (newspaperOrdersFormData.length || !autoSelectedPaper) {
            return;
        }
        // Auto selected paper is either the publisher's active organization or the paper associated with the custom subdomain
        if (autoSelectedPaper) {
            void addNewspaper(autoSelectedPaper.value);
        }
    }, [autoSelectedPaper === null || autoSelectedPaper === void 0 ? void 0 : autoSelectedPaper.value]);
    const formattedPublisherOptions = publisherOptions.map(o => ({
        label: o.name,
        value: o.id
    }));
    // Options are shown in groups when there are multiple related papers or there's only one which is not auto selected
    const shouldShowOptionsGroups = (relatedPublisherOptions.length && !autoSelectedPaper) ||
        relatedPublisherOptions.length > 1;
    // We hide the related paper options group when there is only one paper in this section, this paper will be the auto selected paper.
    // Since the auto selected paper might be in implementation status, we should make sure it is still included in the options
    if (!shouldShowOptionsGroups &&
        autoSelectedPaper &&
        !publisherOptions.map(o => o.id).includes(autoSelectedPaper.value)) {
        formattedPublisherOptions.push(autoSelectedPaper);
    }
    const optionsGroups = shouldShowOptionsGroups
        ? [
            { title: 'Related publishers', options: relatedPublisherOptions },
            {
                title: 'Other publishers',
                options: formattedPublisherOptions.filter(publisherOption => !relatedPublisherOptions
                    .map(option => option.value)
                    .includes(publisherOption.value))
            }
        ]
        : undefined;
    const selectedNewspaperIds = Object.keys(newspaperOrdersByNewspaperId);
    const title = `Where would you like to place this ${productTypeName}?`;
    const description = 'Select one or more publications to place your listing.';
    if (loading) {
        return (_jsx(LoadingState, { context: {
                service: ColumnService.OBITS,
                location: 'Ad placement - Select publication',
                tags: {
                    product,
                    availablePublishersLoading: availablePublishersLoading
                        ? 'true'
                        : 'false',
                    relatedPublishersLoading: relatedPublishersLoading
                        ? 'true'
                        : 'false',
                    adPlacementFlow: 'true',
                    orderId: orderModel.id
                }
            } }));
    }
    return (_jsxs(_Fragment, { children: [_jsx(MultiStepHeader, { title: title, description: description }), addNewspaperAlert && (_jsx(Alert, { id: "add-newspaper-error", onDismiss: () => setAddNewspaperAlert(''), title: addNewspaperAlert, status: "error", icon: _jsx(ExclamationCircleIcon, { className: "h-5 w-5" }) })), _jsxs("div", Object.assign({ className: "grid grid-cols-12 gap-6" }, { children: [_jsxs("div", Object.assign({ className: "col-span-12 md:col-span-6 xl:col-span-8" }, { children: [_jsx(Autocomplete, { id: "selectPublisher", labelText: "Publisher", placeholder: "Select a publisher", value: searchedNewspaperLoaded && !loading ? searchedNewspaperId : '', options: formattedPublisherOptions, onChange: newspaperId => {
                                    if (newspaperOrdersFormData.find(o => { var _a; return ((_a = o.newspaper) === null || _a === void 0 ? void 0 : _a.id) === newspaperId; })) {
                                        void removeNewspaper(newspaperId);
                                    }
                                    else if (newspaperId) {
                                        void addNewspaper(newspaperId);
                                    }
                                }, loading: loading, required: newspapers.length === 0, validationMessages: {
                                    valueMissing: 'Please select a publisher'
                                }, optionsGroups: optionsGroups, selectedOptionsValues: selectedNewspaperIds, showCheckBoxForSelectedItems: true }), selectedNewspaperIds.map(newspaperId => {
                                const newspaper = newspapers.find(n => n.id === newspaperId);
                                if (newspaper) {
                                    return (_jsx(PublisherBadge, { newspaper: newspaper, onClose: () => {
                                            void removeNewspaper(newspaper.id);
                                        } }, newspaper.id));
                                }
                                return null;
                            })] })), _jsx("div", Object.assign({ className: "col-span-12 md:col-span-6 xl:col-span-4 pt-8" }, { children: _jsx(PublisherLocationFilter, { stateOptions: stateOptions, onStateChange: state => {
                                setStateFilter(state);
                            }, activeFilters: { stateFilter } }) })), Object.entries(newspaperOrdersByNewspaperId).map(([newspaperId, ordersForNewspaper]) => {
                        /**
                         * We are traversing newspaperOrders instead of the newspapers context
                         * so the soft deleting of newspaperOrders can be reflected in the UI
                         */
                        const newspaper = newspapers.find(n => n.id === newspaperId);
                        if (!newspaper)
                            return null;
                        return (_jsx("div", Object.assign({ className: "col-span-12" }, { children: _jsx(PublisherCard, { newspaper: newspaper, validPublishingMediums: validMediumsByNewspaperId[newspaperId] || [], publishingMediums: ordersForNewspaper.map(o => o.publishingMedium), onPublishingMediumsChange: (value) => __awaiter(this, void 0, void 0, function* () {
                                    setAddNewspaperAlert('');
                                    const missingMedium = !ordersForNewspaper.some(o => o.publishingMedium === value);
                                    if (missingMedium) {
                                        const newOrderResult = yield getNewspaperOrder(product, getModelFromSnapshot(OrganizationModel, getFirebaseContext(), newspaper), value, anonymousOrder, inputData.filingTypeName);
                                        if (newOrderResult.error) {
                                            return;
                                        }
                                        onNewspaperOrdersFormDataChange([
                                            ...newspaperOrdersFormData,
                                            newOrderResult.response
                                        ]);
                                    }
                                    else {
                                        if (!ordersForNewspaper.some(o => o.publishingMedium !== value)) {
                                            setAddNewspaperAlert('At least one publishing medium must be selected.');
                                            return;
                                        }
                                        onNewspaperOrdersFormDataChange(newspaperOrdersFormData.filter(o => {
                                            var _a;
                                            return ((_a = o.newspaper) === null || _a === void 0 ? void 0 : _a.id) !== newspaperId ||
                                                o.publishingMedium !== value;
                                        }));
                                    }
                                }) }) }), newspaper.id));
                    })] }))] }));
}
export default SelectPublication;
