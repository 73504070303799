import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { isOCRNoticeAuditResponse, isPaginationManifestForNotice } from 'lib/types/pagination';
const getDisplayValueForPaginationManifestData = (data) => {
    if (isPaginationManifestForNotice(data)) {
        return data.internalID;
    }
    return data.orderID;
};
const getDisplayValueForResolvedAudit = (data) => {
    const { ocrResult } = data;
    if (isOCRNoticeAuditResponse(ocrResult)) {
        return ocrResult.order_numbers.join(', ');
    }
    return ocrResult.ads.map(ad => ad.title).join(', ');
};
export default function PaginationReviewDisplay({ paginationReview }) {
    const { combinedStyleAudit, manifestCheck, auditPassed } = paginationReview;
    return (_jsxs("div", { children: [_jsxs("div", Object.assign({ className: "text-xl " }, { children: ["Automated Pagination Review - ", auditPassed ? 'Passed ✅' : 'Failed'] })), !auditPassed && (_jsxs(_Fragment, { children: [_jsxs("div", { children: ["Ads missing in manifest:", ' ', _jsx("span", Object.assign({ className: "text-column-red-600" }, { children: manifestCheck.adsNotInManifest
                                    .map(getDisplayValueForPaginationManifestData)
                                    .join(', ') }))] }), _jsxs("div", { children: ["Extra ads in manifest:", ' ', _jsx("span", Object.assign({ className: "text-column-red-600" }, { children: manifestCheck.extraAdsInManifest
                                    .map(getDisplayValueForPaginationManifestData)
                                    .join(', ') }))] }), _jsxs("div", { children: ["Ads for wrong publisher:", ' ', _jsx("span", Object.assign({ className: "text-column-red-600" }, { children: combinedStyleAudit.adsForWrongPublisher
                                    .map(getDisplayValueForPaginationManifestData)
                                    .join(', ') }))] }), _jsxs("div", { children: ["Missing ads:", ' ', _jsx("span", Object.assign({ className: "text-column-red-600" }, { children: combinedStyleAudit.missingAds
                                    .map(getDisplayValueForPaginationManifestData)
                                    .join(', ') }))] }), _jsxs("div", { children: ["Ads for wrong date:", ' ', _jsx("span", Object.assign({ className: "text-column-red-600" }, { children: combinedStyleAudit.adsForWrongDate
                                    .map(getDisplayValueForPaginationManifestData)
                                    .join(', ') }))] }), _jsxs("div", { children: ["Duplicate ads:", ' ', _jsx("span", Object.assign({ className: "text-column-red-600" }, { children: paginationReview.combinedStyleAudit.duplicateAds
                                    .map(getDisplayValueForPaginationManifestData)
                                    .join(', ') }))] }), _jsxs("div", { children: ["Unknown ads:", ' ', _jsx("span", Object.assign({ className: "text-column-red-600" }, { children: paginationReview.combinedStyleAudit.unknownAds.join(', ') }))] }), _jsx("div", { children: "Potentially failed blocks:" }), paginationReview.auditFailures.map((failure, idx) => (_jsxs("div", { children: [_jsx("div", { children: failure.visionModelResult.error_description }), _jsx("img", { src: failure.blockImageUrl, alt: `Screenshot of potentially failed block` }), _jsxs("div", { children: ["Order numbers: ", getDisplayValueForResolvedAudit(failure)] }), _jsxs("div", { children: ["Minimal whitespace:", ' ', failure.visionModelResult.minimal_whitespace ? 'Yes' : 'No'] }), _jsxs("div", { children: ["No ads have scanned documents:", ' ', failure.visionModelResult.no_pictures ? 'Yes' : 'No'] }), _jsxs("div", { children: ["No duplicates:", ' ', failure.visionModelResult.no_duplicate_ads ? 'Yes' : 'No'] }), _jsxs("div", { children: ["No weird characters:", ' ', failure.visionModelResult.no_weird_characters ? 'Yes' : 'No'] })] }, idx)))] }))] }));
}
