var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { InvoiceStatus } from 'lib/enums';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { ColumnService } from 'lib/services/directory';
import { InvoiceType } from 'lib/types/invoices';
import { isPublisher } from 'lib/utils/users';
import { logAndCaptureException } from 'utils';
import { getFirebaseContext } from 'utils/firebase';
import { safeGetQuery } from 'lib/safeWrappers';
const useShouldShowDownloadSummary = (user, organization) => {
    const { value } = useAsyncEffect({
        fetchData: () => __awaiter(void 0, void 0, void 0, function* () {
            // Download summary button is hidden for publishers
            if (isPublisher(user)) {
                return false;
            }
            // Fetch unpaid invoices
            let baseQuery = getFirebaseContext()
                .invoicesRef()
                .where('status', '==', InvoiceStatus.unpaid.value);
            if (organization) {
                baseQuery = baseQuery.where('advertiserOrganization', '==', organization.ref);
            }
            else {
                const { shadowUser } = user.data();
                const userRef = shadowUser
                    ? getFirebaseContext().usersRef().doc(shadowUser)
                    : user.ref;
                baseQuery = baseQuery.where('advertiser', '==', userRef);
            }
            const { response: invoices, error } = yield safeGetQuery(baseQuery);
            if (error) {
                logAndCaptureException(ColumnService.PAYMENTS, error, 'Unable to fetch unpaid invoices for user', { userId: user.id, organizationId: organization === null || organization === void 0 ? void 0 : organization.id });
                return false;
            }
            let hasUnpaidInvoices = false;
            // Find a single unpaid invoice that is a public notice invoice
            for (const invoice of invoices.docs) {
                const { isBulkInvoice_v2, invoiceType } = invoice.data();
                if (invoiceType !== InvoiceType.ORDER &&
                    !invoice.data().void &&
                    !isBulkInvoice_v2) {
                    hasUnpaidInvoices = true;
                    break;
                }
            }
            return hasUnpaidInvoices;
        }),
        dependencies: [user.id, organization === null || organization === void 0 ? void 0 : organization.id]
    });
    return { hasUnpaidInvoices: !!value };
};
export default useShouldShowDownloadSummary;
