var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import ToastActions from 'redux/toast';
import { PublicationIssueAttachmentStatus, PublicationIssueAttachmentType } from 'lib/types/publicationIssueAttachment';
import { InputAccessories } from 'lib/components/InputAccessories';
import { PublicationIssueStatus } from 'lib/types/publicationIssue';
import { getNewspaperCodeByOrgId } from 'lib/pagination/helpers';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectUser } from 'redux/auth';
import { exists } from 'lib/types';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { Modal } from 'lib/components/Modal';
import { useSafeLoading } from 'lib/components/hooks/useSafeLoading';
import { ColumnService } from 'lib/services/directory';
import { handlePublicationIssueAttachmentUploads } from './handleUpload';
import { AWAITING_APPROVAL_TAB } from '../paginationTableUtils';
import { AttachmentUploadField } from './AttachmentUploadField/AttachmentUploadField';
import { handlePaginationReview } from './handlePaginationReview';
import PaginationReviewDisplay from './PaginationReviewDisplay';
const MANIFEST_UPLOAD_FIELD_ID = 'upload-field-manifest';
const ATTACHMENTS_UPLOAD_FIELD_ID = 'upload-field-attachments';
export function PaginationUpload({ publicationIssueWithSection, product, reloadPublicationIssues, setShowPaginationUploadModal, setPaginationTableTab }) {
    var _a;
    const user = useAppSelector(selectUser);
    const dispatch = useAppDispatch();
    const { publicationIssue, section } = publicationIssueWithSection;
    /**
     * If we are using orders, there should be a section passed in whose status
     * determines the status shown in the UI. In the absence of a section, we use
     * the status on the issue.
     */
    const controllingDocument = section !== null && section !== void 0 ? section : publicationIssue;
    const newspaperCode = getNewspaperCodeByOrgId(publicationIssue.modelData.publisher.id);
    const [userMessage, setUserMessage] = useState();
    // These are attachments that are already uploaded and come from the PublicationIssueAttachments
    const [manifest, setManifest] = useState();
    const [attachments, setAttachments] = useState();
    const [submittingChange, setSubmittingChange] = useState(false);
    const { publicationDate } = publicationIssue.modelData;
    const [paginationReview, setPaginationReview] = useState();
    const readOnly = ![
        PublicationIssueStatus.READY_FOR_PAGINATION,
        PublicationIssueStatus.CHANGES_REQUESTED
    ].includes(controllingDocument.modelData.status);
    const getUploadedAttachmentFromPublicationIssueAttachment = (publicationIssueAttachment) => {
        const { downloadUrl, storagePath, status, name, metadata } = publicationIssueAttachment.modelData;
        const { id } = publicationIssueAttachment;
        return { id, url: downloadUrl, path: storagePath, status, name, metadata };
    };
    const fetchExistingAttachments = () => __awaiter(this, void 0, void 0, function* () {
        try {
            const publicationIssueAttachments = yield publicationIssue.getAttachments();
            const manifest = publicationIssueAttachments.find(publicationIssueAttachment => publicationIssueAttachment.modelData.type ===
                PublicationIssueAttachmentType.MANIFEST &&
                publicationIssueAttachment.modelData.status !==
                    PublicationIssueAttachmentStatus.DELETED);
            const otherAttachments = publicationIssueAttachments.filter(publicationIssueAttachment => publicationIssueAttachment.modelData.type !==
                PublicationIssueAttachmentType.MANIFEST &&
                publicationIssueAttachment.modelData.status !==
                    PublicationIssueAttachmentStatus.DELETED);
            // Convert array to an object indexed by the 'id' property
            const attachmentsById = otherAttachments.reduce((acc, attachment) => {
                acc[attachment.id] = attachment;
                return acc;
            }, {});
            setManifest(manifest);
            setAttachments(attachmentsById);
        }
        catch (error) {
            setUserMessage('Error fetching attachments.');
        }
    });
    useEffect(() => {
        void fetchExistingAttachments();
    }, []);
    const handleDeleteAttachment = (uploadFieldId, attachmentId) => __awaiter(this, void 0, void 0, function* () {
        setSubmittingChange(true);
        try {
            if (uploadFieldId === MANIFEST_UPLOAD_FIELD_ID) {
                if (!manifest) {
                    throw Error('No manifest found.');
                }
                yield (manifest === null || manifest === void 0 ? void 0 : manifest.updateStatus(PublicationIssueAttachmentStatus.DELETED));
            }
            else if (uploadFieldId === ATTACHMENTS_UPLOAD_FIELD_ID) {
                if (!attachments || !attachments[attachmentId]) {
                    throw Error('Attachment not found.');
                }
                yield attachments[attachmentId].updateStatus(PublicationIssueAttachmentStatus.DELETED);
            }
            else {
                throw Error('Upload field not found.');
            }
        }
        catch (error) {
            setUserMessage(`Error deleting attachment: ${error.message}`);
        }
        finally {
            yield fetchExistingAttachments();
            setSubmittingChange(false);
        }
    });
    const handleUploadFiles = (uploadFieldId, files) => __awaiter(this, void 0, void 0, function* () {
        setSubmittingChange(true);
        const type = uploadFieldId === MANIFEST_UPLOAD_FIELD_ID
            ? PublicationIssueAttachmentType.MANIFEST
            : PublicationIssueAttachmentType.PAGINATION;
        try {
            yield handlePublicationIssueAttachmentUploads(publicationIssue.id, newspaperCode, publicationDate, files, type);
        }
        catch (error) {
            setUserMessage(`Error adding attachment: ${error.message}`);
        }
        finally {
            yield fetchExistingAttachments();
            setSubmittingChange(false);
        }
    });
    const handleSubmit = () => __awaiter(this, void 0, void 0, function* () {
        if (!manifest || !attachments || Object.entries(attachments).length === 0) {
            return setUserMessage(`Please upload a manifest and at least one attachment to submit`);
        }
        if (!exists(user)) {
            return;
        }
        yield controllingDocument.updateStatus(user, PublicationIssueStatus.AWAITING_APPROVAL);
        reloadPublicationIssues();
        setPaginationTableTab(AWAITING_APPROVAL_TAB);
        setShowPaginationUploadModal(false);
    });
    const handleUpdateMetadata = (uploadFieldId, attachmentId, key, value) => __awaiter(this, void 0, void 0, function* () {
        setSubmittingChange(true);
        try {
            if (uploadFieldId === MANIFEST_UPLOAD_FIELD_ID) {
                if (!manifest) {
                    throw Error('No manifest found.');
                }
                yield manifest.updateMetadata(key, value);
            }
            else if (uploadFieldId === ATTACHMENTS_UPLOAD_FIELD_ID) {
                if (!attachments || !attachments[attachmentId]) {
                    throw Error('Attachment not found.');
                }
                yield attachments[attachmentId].updateMetadata(key, value);
            }
            else {
                throw Error('Upload field not found.');
            }
        }
        catch (error) {
            setUserMessage(`Error updating metadata: ${error.message}`);
        }
        finally {
            yield fetchExistingAttachments();
            setSubmittingChange(false);
        }
    });
    // These are the same attachments above that have been converted to the UploadedAttachmentData format
    const convertedManifest = manifest
        ? [getUploadedAttachmentFromPublicationIssueAttachment(manifest)]
        : undefined;
    const convertedAttachments = attachments
        ? Object.values(attachments).map(attachment => getUploadedAttachmentFromPublicationIssueAttachment(attachment))
        : undefined;
    const [runningPaginationReview, runPaginationReviewOnClick] = useSafeLoading({
        callback: () => __awaiter(this, void 0, void 0, function* () {
            const [manifest] = convertedManifest || [];
            if (!manifest) {
                return;
            }
            const attachments = convertedAttachments || [];
            if (!attachments.length) {
                return;
            }
            if (runningPaginationReview) {
                return;
            }
            setSubmittingChange(true);
            const [paginationReviewError, paginationReview] = yield handlePaginationReview({
                product,
                manifest,
                attachments,
                publicationIssue,
                section
            });
            if (paginationReviewError) {
                dispatch(ToastActions.toastError({
                    headerText: 'Pagination error',
                    bodyText: 'Please try again later. If the problem persists, reach out to the Column team.'
                }));
            }
            else {
                setPaginationReview(paginationReview);
            }
            setSubmittingChange(false);
        }),
        errorConfig: {
            service: ColumnService.PAGINATION,
            message: 'Error running automated pagination review',
            tags: {
                sectionId: (_a = section === null || section === void 0 ? void 0 : section.id) !== null && _a !== void 0 ? _a : 'null',
                publicationIssueId: publicationIssue.id
            }
        }
    });
    const loading = submittingChange || runningPaginationReview;
    const enableInAppValidation = getBooleanFlag(LaunchDarklyFlags.ENABLE_IN_APP_PAGINATION_REVIEW);
    const disablePrimarySubmit = readOnly || (!paginationReview && enableInAppValidation);
    const secondaryActions = [
        {
            buttonText: 'Cancel',
            type: 'button',
            onClick: () => setShowPaginationUploadModal(false)
        }
    ];
    if (enableInAppValidation) {
        secondaryActions.push({
            buttonText: 'Run Pagination Review',
            type: 'button',
            onClick: runPaginationReviewOnClick,
            secondary: true,
            loading
        });
    }
    return (_jsxs(Modal, Object.assign({ id: "pagination-upload-modal", onClose: () => setShowPaginationUploadModal(false), primaryAction: {
            buttonText: 'Submit',
            type: 'button',
            onClick: handleSubmit,
            disabled: disablePrimarySubmit,
            loading
        }, title: _jsxs("div", Object.assign({ className: "text-2xl font-bold" }, { children: ["Upload Pagination for ", publicationDate, " ", newspaperCode] })), secondaryActions: secondaryActions }, { children: [paginationReview && (_jsx(PaginationReviewDisplay, { paginationReview: paginationReview })), readOnly && (_jsx("div", Object.assign({ className: "my-2" }, { children: _jsx("div", Object.assign({ className: "text-column-red-500" }, { children: "This issue is not \"Ready for Pagination\". New uploads are not allowed." })) }))), userMessage && _jsx("div", Object.assign({ className: "my-2" }, { children: userMessage })), _jsx("div", Object.assign({ className: "my-4" }, { children: _jsx(InputAccessories, Object.assign({ id: `file-manifest`, labelText: `Upload Manifest` }, { children: _jsx(AttachmentUploadField, { id: MANIFEST_UPLOAD_FIELD_ID, multiSelect: false, readOnly: readOnly, loading: loading, acceptFileTypes: ".csv", uploadedAttachments: convertedManifest, onUpdateMetadata: handleUpdateMetadata, onUploadFiles: handleUploadFiles, onDeleteAttachment: handleDeleteAttachment }) })) })), _jsx("div", Object.assign({ className: "my-4" }, { children: _jsx(InputAccessories, Object.assign({ id: `file-attachments`, labelText: `Upload Attachments` }, { children: _jsx(AttachmentUploadField, { id: ATTACHMENTS_UPLOAD_FIELD_ID, multiSelect: true, readOnly: readOnly, loading: loading, acceptFileTypes: ".pdf,.jpg", uploadedAttachments: convertedAttachments, onUpdateMetadata: handleUpdateMetadata, onUploadFiles: handleUploadFiles, onDeleteAttachment: handleDeleteAttachment }) })) }))] })));
}
