var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { ArrowPathIcon, HomeIcon, UsersIcon } from '@heroicons/react/24/outline';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { isColumnUser } from 'lib/helpers';
import { getLocationParams } from 'lib/frontend/utils/browser';
import LoadingState from 'components/LoadingState';
import { Permissions } from 'lib/permissions/roles';
import { userHasPermission } from 'utils/permissions';
import { useSyncExportSettings } from 'lib/frontend/hooks/useSyncExportSettings';
import OrganizationMemberSettings from '../OrganizationMemberSettings';
import HoldingCompanyInfo from './HoldingCompanyInfo';
const GENERAL_INFO = 'general-setting';
const ORGANIZATION_MEMBER_SETTINGS = 'member-settings';
const INTEGRATIONS = 'integrations';
const drawerWidth = 35;
const styles = (theme) => createStyles({
    root: {
        display: 'flex'
    },
    drawer: {
        width: theme.spacing(drawerWidth),
        flexShrink: 0
    },
    drawerPaper: {
        width: theme.spacing(drawerWidth)
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(2)
    },
    toolbar: theme.mixins.toolbar
});
function HoldingCompanySettings(_a) {
    var { classes, activeOrganization, user } = _a, props = __rest(_a, ["classes", "activeOrganization", "user"]);
    const [view, setView] = useState(getLocationParams().get('tab') || GENERAL_INFO);
    const syncExportSettings = useSyncExportSettings(activeOrganization);
    const showIntegrationSettingsTab = syncExportSettings &&
        user &&
        (isColumnUser(user) ||
            userHasPermission(user, Permissions.SETTINGS_FEATURE_FLAGS));
    const updateView = (view) => {
        window.history.replaceState(null, '', `/settings/organization/?tab=${view}`);
        setView(view);
    };
    const renderTab = (tabName, tabLabel, icon) => (_jsxs("div", Object.assign({ className: `flex rounded font-medium text-base leading-5 text-gray-700 mt-1 py-3 w-64 cursor-pointer hover:bg-gray-100 ${view === tabName && 'bg-gray-200'}`, onClick: () => updateView(tabName), id: tabName }, { children: [_jsx("div", Object.assign({ className: "flex justify-center w-3/12" }, { children: icon })), _jsx("div", Object.assign({ className: "w-9/12" }, { children: tabLabel }))] })));
    const renderSideBar = () => (_jsxs("div", Object.assign({ className: "bg-white rounded border border-gray-300 pt-3 px-4", style: {
            height: 'calc(100vh - 150px)'
        } }, { children: [userHasPermission(user, Permissions.SETTINGS_ORGANIZATION_INFO) &&
                renderTab(GENERAL_INFO, 'Newspaper Info', _jsx(HomeIcon, { className: "w-5 h-5" })), renderTab(ORGANIZATION_MEMBER_SETTINGS, 'Members', _jsx(UsersIcon, { className: "w-5 h-5" })), showIntegrationSettingsTab &&
                renderTab(INTEGRATIONS, 'Integration', _jsx(ArrowPathIcon, { className: "w-5 h-5" }))] })));
    if (!activeOrganization)
        return _jsx(LoadingState, {});
    return (_jsxs("div", Object.assign({ className: classes.root }, { children: [_jsx(CssBaseline, {}), renderSideBar(), _jsx("main", Object.assign({ className: classes.content }, { children: {
                    [GENERAL_INFO]: (_jsx(HoldingCompanyInfo, Object.assign({ activeOrganization: activeOrganization }, props))),
                    [ORGANIZATION_MEMBER_SETTINGS]: (_jsx(OrganizationMemberSettings, Object.assign({ activeOrganization: activeOrganization, user: user }, props)))
                }[view] }))] })));
}
export default withStyles(styles)(HoldingCompanySettings);
