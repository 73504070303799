var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { isResponseOrError } from '../../helpers';
import { getErrorReporter } from '../../utils/errors';
import { ColumnService } from '../../services/directory';
export function useSafeLoading({ callback, errorConfig }) {
    const [loading, setLoading] = useState(false);
    const callbackWrapper = (...args) => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        try {
            const possibleResponseOrError = yield callback(args);
            if (isResponseOrError(possibleResponseOrError)) {
                if (possibleResponseOrError.error) {
                    throw possibleResponseOrError.error;
                }
            }
        }
        catch (err) {
            getErrorReporter().logAndCaptureError((errorConfig === null || errorConfig === void 0 ? void 0 : errorConfig.service) || ColumnService.UNKNOWN, err, (errorConfig === null || errorConfig === void 0 ? void 0 : errorConfig.message) || 'Error in useSafeLoading', errorConfig === null || errorConfig === void 0 ? void 0 : errorConfig.tags);
        }
        finally {
            setLoading(false);
        }
    });
    return [loading, callbackWrapper];
}
