var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { getFirebaseContext } from 'utils/firebase';
export default function OrderLayoutFilingTypeSettings({ activeOrganization, updatedFilingType, onUpdateFilingType }) {
    var _a;
    const { isLoading: loadingTemplates, value: availableTemplates } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            const publisherTemplates = yield getFirebaseContext()
                .adTemplatesRef()
                .where('organization', '==', activeOrganization.ref)
                .get();
            const sharedTemplates = yield getFirebaseContext()
                .adTemplatesRef()
                .where('publisherOrganizations', 'array-contains', activeOrganization.ref)
                .get();
            const allTemplates = [
                ...publisherTemplates.docs,
                ...sharedTemplates.docs
            ];
            return allTemplates.filter(template => !template.data().archived);
        }),
        dependencies: [activeOrganization.id]
    });
    return (_jsx(CardGridLayout, Object.assign({ header: {
            title: 'Order Layout',
            description: 'Configure how the order will be displayed to the advertiser.'
        } }, { children: loadingTemplates || !availableTemplates ? null : (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(ColumnSelect, { value: (_a = updatedFilingType.template) === null || _a === void 0 ? void 0 : _a.id, options: availableTemplates.map(template => ({
                    label: template.data().name,
                    value: template.id
                })), onChange: templateId => {
                    const template = availableTemplates.find(template => template.id === templateId);
                    if (!template)
                        return;
                    onUpdateFilingType(Object.assign(Object.assign({}, updatedFilingType), { template: template.ref }));
                }, id: "order-layout-template-select", labelText: "Order Layout Template", required: true, allowUndefined: true }) }))) })));
}
