var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
/**
 * @deprecated Use useSafeLoading instead
 */
export function useLoading() {
    const [loading, setLoading] = useState(false);
    const runWithLoading = (callback, errHandler) => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        try {
            yield callback();
        }
        catch (err) {
            if (errHandler) {
                errHandler(err);
            }
        }
        finally {
            setLoading(false);
        }
    });
    return [loading, runWithLoading];
}
