import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { FolderArrowDownIcon, CurrencyDollarIcon, ReceiptRefundIcon, CurrencyPoundIcon, DocumentPlusIcon, DocumentTextIcon, UserGroupIcon, ClipboardIcon, CalendarIcon, SwatchIcon, UsersIcon, HomeIcon, BanknotesIcon, MegaphoneIcon, IdentificationIcon } from '@heroicons/react/24/outline';
import { userHasPermission } from 'utils/permissions';
import { getBooleanFlag } from 'utils/flags';
import { Permissions } from 'lib/permissions/roles';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { CurrencyType, State } from 'lib/enums';
import { useAppSelector } from 'redux/hooks';
import { selectHasClassifiedsActive, selectHasObituariesActive } from 'redux/auth';
import { SidebarTab } from '../SettingsSidebar';
export const GENERAL_INFO = 'general-setting';
export const ADVERTISING_DEADLINES = 'advertising-deadlines';
export const ORGANIZATION_MEMBER_SETTINGS = 'member-settings';
export const PAYMENT = 'payment-settings';
export const PRICING_RESTRICTIONS = 'pricing-settings';
export const ORGANIZATION_AFFIDAVIT_SETTINGS = 'affidavit-settings';
export const CUSTOM_BRAND = 'custom-brand';
export const DESIGN_AND_LAYOUT = 'design-and-layout';
export const FTP = 'ftp';
export const CUSTOMERS = 'customers';
export const NOTICE_TYPES = 'notice-types';
export const BILLING = 'billing';
export const OBITUARIES = 'obituaries';
export const CLASSIFIEDS = 'classifieds';
export default function PublisherSettingsSidebar({ activeOrganization, onChange, value, user }) {
    var _a;
    const hasClassifiedsActive = useAppSelector(selectHasClassifiedsActive);
    const hasObituariesActive = useAppSelector(selectHasObituariesActive);
    /*
      If obits or classifieds are active and the user has permission
      to view any of the nested tabs, we should show the parent tab
    */
    const obitsTabPermission = hasObituariesActive &&
        (userHasPermission(user, Permissions.SETTINGS_DEADLINES) ||
            userHasPermission(user, Permissions.SETTINGS_RATES) ||
            userHasPermission(user, Permissions.SETTINGS_ORDER_TYPES));
    const classifiedsTabPermission = hasClassifiedsActive &&
        (userHasPermission(user, Permissions.SETTINGS_DEADLINES) ||
            userHasPermission(user, Permissions.SETTINGS_RATES) ||
            userHasPermission(user, Permissions.SETTINGS_ORDER_TYPES));
    return (_jsxs("div", Object.assign({ className: "mt-3" }, { children: [userHasPermission(user, Permissions.SETTINGS_ORGANIZATION_INFO) && (_jsx(SidebarTab, { onClick: () => onChange(GENERAL_INFO), active: value === GENERAL_INFO, id: GENERAL_INFO, labelText: "Publisher Info", Icon: HomeIcon })), _jsx(SidebarTab, { onClick: () => onChange(ORGANIZATION_MEMBER_SETTINGS), active: value === ORGANIZATION_MEMBER_SETTINGS, id: ORGANIZATION_MEMBER_SETTINGS, labelText: "Members", Icon: UsersIcon }), userHasPermission(user, Permissions.SETTINGS_CUSTOMERS) && (_jsx(SidebarTab, { onClick: () => onChange(CUSTOMERS), active: value === CUSTOMERS, id: CUSTOMERS, labelText: "Customers", Icon: UserGroupIcon })), userHasPermission(user, Permissions.SETTINGS_DEADLINES) && (_jsx(SidebarTab, { onClick: () => onChange(ADVERTISING_DEADLINES), active: value === ADVERTISING_DEADLINES, id: ADVERTISING_DEADLINES, labelText: "Advertising Deadlines", Icon: CalendarIcon })), userHasPermission(user, Permissions.SETTINGS_DESIGN_AND_LAYOUT) && (_jsx(SidebarTab, { onClick: () => onChange(DESIGN_AND_LAYOUT), active: value === DESIGN_AND_LAYOUT, id: DESIGN_AND_LAYOUT, labelText: "Design and Layout", Icon: DocumentPlusIcon })), getBooleanFlag(LaunchDarklyFlags.ENABLE_NOTICE_TYPE_SETTINGS) &&
                userHasPermission(user, Permissions.SETTINGS_NOTICE_TYPES) && (_jsx(SidebarTab, { onClick: () => onChange(NOTICE_TYPES), active: value === NOTICE_TYPES, id: NOTICE_TYPES, labelText: "Notice Types", Icon: DocumentTextIcon })), userHasPermission(user, Permissions.SETTINGS_RATES) && (_jsx(SidebarTab, { id: PRICING_RESTRICTIONS, labelText: "Rates", Icon: ReceiptRefundIcon, onClick: () => onChange(PRICING_RESTRICTIONS), active: value === PRICING_RESTRICTIONS })), userHasPermission(user, Permissions.SETTINGS_BILLING) && (_jsx(SidebarTab, { onClick: () => onChange(BILLING), active: value === BILLING, id: BILLING, labelText: "Billing", Icon: BanknotesIcon })), user &&
                userHasPermission(user, Permissions.SETTINGS_CUSTOM_BRANDING) && (_jsx(SidebarTab, { onClick: () => onChange(CUSTOM_BRAND), active: value === CUSTOM_BRAND, id: CUSTOM_BRAND, labelText: "Custom Branding", Icon: SwatchIcon })), userHasPermission(user, Permissions.SETTINGS_AFFIDAVITS) &&
                !activeOrganization.data().affidavitDisabled && (_jsx(SidebarTab, { onClick: () => onChange(ORGANIZATION_AFFIDAVIT_SETTINGS), active: value === ORGANIZATION_AFFIDAVIT_SETTINGS, id: ORGANIZATION_AFFIDAVIT_SETTINGS, labelText: "Affidavits", Icon: ClipboardIcon })), userHasPermission(user, Permissions.SETTINGS_PAYMENT) && (_jsx(SidebarTab, { onClick: () => onChange(PAYMENT), active: value === PAYMENT, id: PAYMENT, labelText: "Payouts", Icon: activeOrganization.data().currency === CurrencyType.gbp.key
                    ? CurrencyPoundIcon
                    : CurrencyDollarIcon })), (((_a = State.by_value(activeOrganization.data().state)) === null || _a === void 0 ? void 0 : _a.showUploads) ||
                activeOrganization.data().ftp) && (_jsx(SidebarTab, { onClick: () => onChange(FTP), active: value === FTP, id: FTP, labelText: "Statewide Site Uploads", Icon: FolderArrowDownIcon })), obitsTabPermission && (_jsx(SidebarTab, { onClick: () => onChange(OBITUARIES), active: value === OBITUARIES, id: OBITUARIES, labelText: "Obituaries", Icon: IdentificationIcon })), classifiedsTabPermission && (_jsx(SidebarTab, { onClick: () => onChange(CLASSIFIEDS), active: value === CLASSIFIEDS, id: CLASSIFIEDS, labelText: "Classifieds", Icon: MegaphoneIcon }))] })));
}
