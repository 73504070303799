var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-await-in-loop */
import { useEffect, useMemo, useState } from 'react';
import ToastActions from 'redux/toast';
import { exists } from 'lib/types';
import { logAndCaptureException } from 'utils';
import { TableLayout } from 'lib/components/TableLayout';
import { isPublisherOrganization } from 'lib/utils/organizations';
import ProcessPaymentModal from 'routes/notice/ProcessPaymentModal';
import ProcessCheckModal from 'routes/notice/ProcessCheckModal';
import SendReminderModal from 'routes/notice/SendReminderModal';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import { FinancePaper } from 'lib/components/gifs';
import api from 'api';
import { SORT_DESCENDING, SORT_ASCENDING } from 'lib/types/searchable';
import { debounce } from 'lodash';
import { ColumnButton } from 'lib/components/ColumnButton';
import { CreditCardIcon } from '@heroicons/react/24/outline';
import { wrapError, wrapSuccess } from 'lib/types/responses';
import { getErrorReporter } from 'lib/utils/errors';
import { ColumnService } from 'lib/services/directory';
import { useAppDispatch } from 'redux/hooks';
import { useSet } from 'lib/frontend/hooks/useSet';
import { isPublisher } from 'lib/utils/users';
import { INVOICE_STATUSES_UNPAID } from 'lib/model/objects/invoiceModel';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { useBooleanFlag } from 'utils/flags';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { InvoicesTableRow } from './InvoicesTableRow';
import InvoicesTableFilterDialog from './InvoicesTableFilterDialog';
import { ALL_INVOICE_CREATION_DATES, DEFAULT_INVOICE_STATUS_FILTER, getElasticFilters } from './invoiceTableSettingsUtils';
import { ProcessBulkPaymentModal } from './ProcessBulkPaymentModal';
import useShouldShowDownloadSummary from './hooks/useShouldShowDownloadSummary';
const MAX_INVOICES_QUERY_SIZE = 1000;
/**
 * Determines the number of active items in the current filter for display in the UI
 * @param uploadFilter
 * @returns
 */
const getNumActiveFilters = (invoiceFilter) => {
    let numActive = 0;
    if (invoiceFilter.dateRange !== ALL_INVOICE_CREATION_DATES) {
        numActive++;
    }
    if (invoiceFilter.invoiceTableStatus !==
        DEFAULT_INVOICE_STATUS_FILTER.invoiceTableStatus) {
        numActive++;
    }
    return numActive;
};
export const downloadUnpaidInvoicesSummary = (organizationId) => __awaiter(void 0, void 0, void 0, function* () {
    const { response, error } = yield api.safePost(`documents/unpaid-invoices-summary/download`, {
        advertiserOrganizationId: organizationId
    });
    if (error) {
        logAndCaptureException(ColumnService.PAYMENTS, error, 'Failed to download unpaid invoices sumamry', { organizationId });
        return '';
    }
    return response;
});
export const downloadInvoices = (invoices) => __awaiter(void 0, void 0, void 0, function* () {
    const request = {
        invoiceIds: invoices.map(invoice => invoice.id)
    };
    try {
        const { response, error } = yield api.safePost(`documents/invoices/download`, request);
        if (response) {
            return wrapSuccess(response);
        }
        throw error;
    }
    catch (err) {
        getErrorReporter().logAndCaptureCriticalError(ColumnService.PAYMENTS, err, 'Failed to download invoices');
        return wrapError(err);
    }
});
const PAGE_SIZE = 10;
/**
 * Wrapper component for the Invoices table page.
 */
export default function InvoiceTable({ user, organization, usesBulkInvoiceV2 }) {
    var _a;
    const dispatch = useAppDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
    const [processPaymentModalInvoice, setProcessPaymentModalInvoice] = useState();
    const [processCheckModalInvoice, setProcessCheckModalInvoice] = useState();
    const [sendReminderModalInvoice, setSendReminderModalInvoice] = useState();
    const shouldAllowBulkPaymentFlag = useBooleanFlag(LaunchDarklyFlags.SHOW_BULK_PAYMENT_IN_INVOICES_TABLE, false);
    const shouldAllowBulkPayment = shouldAllowBulkPaymentFlag && !isPublisher(user);
    const [selectedInvoices, selectedInvoiceActions] = useSet();
    const [processBulkPaymentModalInvoice, setProcessBulkPaymentModalInvoice] = useState();
    const [downloadSummaryLoading, setDownloadSummaryLoading] = useState(false);
    // current value of the search bar on the invoice table
    const [invoiceFilter, setInvoiceFilter] = useState(DEFAULT_INVOICE_STATUS_FILTER);
    const [updatedInvoiceFilter, setUpdatedInvoiceFilter] = useState(invoiceFilter);
    const updateSearch = useMemo(() => debounce(value => {
        setDebouncedSearchTerm(value);
    }, 400), []);
    // columnObjectsAreEqual does not work with comparing date ranges
    // of this type, so we have to do a separate comparison
    const filterHasChanges = !columnObjectsAreEqual(updatedInvoiceFilter, invoiceFilter) ||
        updatedInvoiceFilter.dateRange !== invoiceFilter.dateRange;
    const loadInvoices = () => __awaiter(this, void 0, void 0, function* () {
        var _b;
        try {
            const filters = getElasticFilters(user, organization, invoiceFilter);
            const postBody = {
                search: debouncedSearchTerm,
                sort: [
                    { paid: SORT_ASCENDING },
                    { voided: SORT_ASCENDING },
                    { duedate: SORT_DESCENDING }
                ],
                allFilters: filters
            };
            const response = (yield api.post('search/invoices', postBody));
            if (response.error) {
                return null;
            }
            return response.results;
        }
        catch (err) {
            logAndCaptureException(ColumnService.PAYMENTS, err, 'Failed to load invoices', {
                userEmail: user.data().email,
                organizationName: ((_b = organization === null || organization === void 0 ? void 0 : organization.data()) === null || _b === void 0 ? void 0 : _b.name) || ''
            });
            return null;
        }
    });
    const { value: invoices, invalidateData, isLoading } = useAsyncEffect({
        fetchData: loadInvoices,
        dependencies: [
            user.id,
            organization === null || organization === void 0 ? void 0 : organization.id,
            JSON.stringify(invoiceFilter),
            debouncedSearchTerm
        ]
    });
    useEffect(() => {
        updateSearch(searchTerm);
    }, [searchTerm]);
    const { hasUnpaidInvoices } = useShouldShowDownloadSummary(user, organization);
    // TODO: In a tech debt sprint, let's separate out the advertiser invoice table and the publisher invoice table
    const advertiserColumns = [
        _jsx("th", Object.assign({ className: "font-medium w-56" }, { children: "Date Created" }), "date-created"),
        _jsx("th", Object.assign({ className: "font-medium w-56" }, { children: "Invoice ID" }), "invoice-id"),
        _jsx("th", Object.assign({ className: "font-medium w-56" }, { children: "Notice Name" }), "notice-name"),
        _jsx("th", Object.assign({ className: "font-medium w-56" }, { children: "Publisher" }), "publisher"),
        _jsx("th", Object.assign({ className: "font-medium w-56" }, { children: "Amount" }), "amount"),
        _jsx("th", Object.assign({ className: "font-medium w-56" }, { children: "Status" }), "status"),
        _jsx("th", Object.assign({ className: "font-medium w-56" }, { children: "Due Date" }), "due-date"),
        _jsx("th", Object.assign({ className: "font-medium w-40" }, { children: "Actions" }), "actions")
    ];
    const advertiserColumnsForBulkInvoices = [
        _jsx("th", Object.assign({ className: "font-medium w-56" }, { children: "Date Created" }), "date-created"),
        _jsx("th", Object.assign({ className: "font-medium w-56" }, { children: "Invoice ID" }), "invoice-id"),
        _jsx("th", Object.assign({ className: "font-medium w-56" }, { children: "Publisher" }), "publisher"),
        _jsx("th", Object.assign({ className: "font-medium w-56" }, { children: "Amount" }), "amount"),
        _jsx("th", Object.assign({ className: "font-medium w-56" }, { children: "Status" }), "status"),
        _jsx("th", Object.assign({ className: "font-medium w-56" }, { children: "Due Date" }), "due-date"),
        _jsx("th", Object.assign({ className: "font-medium w-56" }, { children: "Actions" }), "actions")
    ];
    const publisherColumns = [
        _jsx("th", Object.assign({ className: "font-medium w-44" }, { children: "Name" }), "date-created"),
        _jsx("th", Object.assign({ className: "font-medium w-56" }, { children: "Notice Name" }), "notice-name"),
        _jsx("th", Object.assign({ className: "font-medium w-44" }, { children: "Organization" }), "invoice-id"),
        _jsx("th", Object.assign({ className: "font-medium w-44" }, { children: "Date Created" }), "amount"),
        _jsx("th", Object.assign({ className: "font-medium w-44" }, { children: "Amount" }), "status"),
        _jsx("th", Object.assign({ className: "font-medium w-44" }, { children: "Status" }), "due-date"),
        _jsx("th", Object.assign({ className: "font-medium w-44" }, { children: "Due Date" }), "actions"),
        _jsx("th", Object.assign({ className: "font-medium w-44" }, { children: _jsx("div", Object.assign({ className: "pl-10" }, { children: "Actions" })) }), "actions")
    ];
    const publisherColumnsForBulkInvoices = [
        _jsx("th", Object.assign({ className: "font-medium w-44" }, { children: "Name" }), "date-created"),
        _jsx("th", Object.assign({ className: "font-medium w-44" }, { children: "Organization" }), "invoice-id"),
        _jsx("th", Object.assign({ className: "font-medium w-44" }, { children: "Date Created" }), "amount"),
        _jsx("th", Object.assign({ className: "font-medium w-44" }, { children: "Amount" }), "status"),
        _jsx("th", Object.assign({ className: "font-medium w-44" }, { children: "Status" }), "due-date"),
        _jsx("th", Object.assign({ className: "font-medium w-44" }, { children: "Due Date" }), "actions"),
        _jsx("th", Object.assign({ className: "font-medium w-44" }, { children: _jsx("div", Object.assign({ className: "pl-10" }, { children: "Actions" })) }), "actions")
    ];
    const title = `Total invoices: ${(_a = invoices === null || invoices === void 0 ? void 0 : invoices.length) !== null && _a !== void 0 ? _a : 0}${(invoices === null || invoices === void 0 ? void 0 : invoices.length) === MAX_INVOICES_QUERY_SIZE ? '+' : ''}`;
    return (_jsxs("div", Object.assign({ className: "pb-10 py-4 px-8 m-4" }, { children: [_jsx("div", Object.assign({ className: "flex justify-between pb-6 items-center" }, { children: _jsx("h1", Object.assign({ className: "text-2xl font-medium leading-tight text-column-gray-900 mb-1" }, { children: "Invoices" })) })), _jsx("div", Object.assign({ id: "invoices-settings", className: "bg-white rounded-lg border border-column-gray-100 mb-24 shadow-column-2" }, { children: _jsx(TableLayout, { header: {
                        subtitle: `Track all Column invoices for your notices.`,
                        title
                    }, columns: isPublisherOrganization(organization)
                        ? usesBulkInvoiceV2
                            ? publisherColumnsForBulkInvoices
                            : publisherColumns
                        : usesBulkInvoiceV2
                            ? advertiserColumnsForBulkInvoices
                            : advertiserColumns, renderRow: invoice => (_jsx(InvoicesTableRow, { invoice: invoice, organization: organization, setSendReminderModalInvoice: setSendReminderModalInvoice, setProcessPaymentModalInvoice: setProcessPaymentModalInvoice, usesBulkInvoiceV2: usesBulkInvoiceV2 })), noResultsContent: (invoices === null || invoices === void 0 ? void 0 : invoices.length) === 0
                        ? {
                            header: 'No invoices yet',
                            subheader: usesBulkInvoiceV2
                                ? 'Invoices will appear here after your first month of bulk payments activities.'
                                : '',
                            icon: _jsx("img", { src: FinancePaper })
                        }
                        : undefined, data: invoices || [], loading: isLoading, id: "invoices", filterable: {
                        shouldShowTableItem: () => {
                            return true;
                        },
                        additionalFilters: {
                            applyFilterChanges: () => setInvoiceFilter(updatedInvoiceFilter),
                            resetFilters: () => {
                                setUpdatedInvoiceFilter(DEFAULT_INVOICE_STATUS_FILTER);
                                setInvoiceFilter(DEFAULT_INVOICE_STATUS_FILTER);
                            },
                            filterHasChanges,
                            numFiltersActive: getNumActiveFilters(invoiceFilter),
                            renderDialog: () => (_jsx(InvoicesTableFilterDialog, { setUpdatedInvoiceFilter: setUpdatedInvoiceFilter, updatedInvoiceTableFilter: updatedInvoiceFilter, enableInvoiceTypeFiltering: usesBulkInvoiceV2 }))
                        },
                        onSearch: (searchTerm) => {
                            setSearchTerm(searchTerm);
                        }
                    }, pagination: { pageSize: PAGE_SIZE }, downloadable: {
                        buttonText: 'Download Invoices',
                        onClickDownload: () => __awaiter(this, void 0, void 0, function* () {
                            const { response: downloadURL, error: downloadError } = yield downloadInvoices(invoices !== null && invoices !== void 0 ? invoices : []);
                            if (downloadError) {
                                dispatch(ToastActions.toastError({
                                    headerText: 'Failed to download invoices',
                                    bodyText: 'Our team has been notified and we will look into the issue immediately. Please try again later.'
                                }));
                            }
                            else {
                                window.open(downloadURL, '_blank');
                                dispatch(ToastActions.toastSuccess({
                                    headerText: 'Invoices downloaded',
                                    bodyText: "Please check your download folder to view the invoices. If you don't see the file, make sure that you have downloads enabled for column.us."
                                }));
                            }
                        })
                    }, actionable: _jsxs(_Fragment, { children: [hasUnpaidInvoices && (_jsx(ColumnButton, { buttonText: "Download Summary", size: "sm", type: "button", loading: downloadSummaryLoading, onClick: () => __awaiter(this, void 0, void 0, function* () {
                                    setDownloadSummaryLoading(true);
                                    const url = yield downloadUnpaidInvoicesSummary((organization === null || organization === void 0 ? void 0 : organization.id) || '');
                                    if (url) {
                                        window.open(url, '_blank');
                                    }
                                    setDownloadSummaryLoading(false);
                                }) })), selectedInvoices.size > 1 && (_jsx(ColumnButton, { primary: true, buttonText: "Pay Selected Invoices", size: "sm", onClick: () => setProcessBulkPaymentModalInvoice(invoices === null || invoices === void 0 ? void 0 : invoices.filter(invoice => selectedInvoices.has(invoice.id))), startIcon: _jsx(CreditCardIcon, { className: "w-5 py-1 text-column-grey-400" }), type: "button" }))] }), selectable: shouldAllowBulkPayment
                        ? {
                            onSelectItems: (items, checked) => items.forEach(item => checked
                                ? selectedInvoiceActions.add(item.id)
                                : selectedInvoiceActions.remove(item.id)),
                            selectedItems: selectedInvoices,
                            serializeItemToUniqueId: item => item.id,
                            isSelectDisabled: item => {
                                if (item.voided) {
                                    return true;
                                }
                                const isUnpaid = INVOICE_STATUSES_UNPAID.includes(item.status);
                                if (!isUnpaid) {
                                    return true;
                                }
                                return false;
                            }
                        }
                        : undefined }) })), exists(organization) && isPublisherOrganization(organization) && (_jsxs(_Fragment, { children: [processPaymentModalInvoice && (_jsx(ProcessPaymentModal, { setOpen: () => setProcessPaymentModalInvoice(undefined), setProcessCheck: () => setProcessCheckModalInvoice(processPaymentModalInvoice), invoiceId: processPaymentModalInvoice.id, newspaper: organization })), processCheckModalInvoice && (_jsx(ProcessCheckModal, { setOpen: () => setProcessCheckModalInvoice(undefined), invoiceId: processCheckModalInvoice.id, advertiserId: processCheckModalInvoice.filerid, newspaper: organization })), sendReminderModalInvoice && (_jsx(SendReminderModal, { invoiceId: sendReminderModalInvoice.id, newspaper: organization, onCloseModal: () => setSendReminderModalInvoice(false) }))] })), !!(processBulkPaymentModalInvoice === null || processBulkPaymentModalInvoice === void 0 ? void 0 : processBulkPaymentModalInvoice.length) && (_jsx(ProcessBulkPaymentModal, { onClose: () => {
                    setProcessBulkPaymentModalInvoice(undefined);
                    selectedInvoiceActions.clear();
                    invalidateData();
                }, invoices: processBulkPaymentModalInvoice !== null && processBulkPaymentModalInvoice !== void 0 ? processBulkPaymentModalInvoice : [] }))] })));
}
