var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { wrapSuccess } from '../types/responses';
import { getErrorReporter } from '../utils/errors';
import { ColumnService } from './directory';
import { InternalServerError, wrapErrorAsColumnError } from '../errors/ColumnErrors';
import { safeAsync } from '../safeWrappers';
export class BaseService {
    constructor(ctx, collection) {
        this.ctx = ctx;
        this.collection = collection;
    }
    /**
     * Generic create method that adds `createdAt` automatically
     * and returns the document reference.
     */
    create(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const createdAt = this.ctx.fieldValue().serverTimestamp();
            const objectWithTimestamp = Object.assign(Object.assign({}, data), { createdAt, modifiedAt: null // Initially set to null, can be updated later
             });
            const collectionRef = this.collection; // Dynamically get the collection reference
            const { response: docRef, error: addError } = yield safeAsync(() => collectionRef.add(objectWithTimestamp))();
            if (addError) {
                getErrorReporter().logAndCaptureError(ColumnService.DATABASE, addError, 'Error adding document to collection');
                return wrapErrorAsColumnError(addError, InternalServerError);
            }
            return wrapSuccess(docRef);
        });
    }
}
