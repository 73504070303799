import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { CurrencyDollarIcon, DocumentTextIcon, PencilSquareIcon, NewspaperIcon, BellIcon, HomeIcon } from '@heroicons/react/24/outline';
import { isMemberOfOrganization } from 'lib/utils/users';
import { userHasPermission } from 'utils/permissions';
import { OccupationType } from 'lib/enums';
import { Permissions } from 'lib/permissions/roles';
import { getModelFromSnapshot } from 'lib/model';
import { UserModel } from 'lib/model/objects/userModel';
import { getFirebaseContext } from 'utils/firebase';
import { SidebarTab } from '../SettingsSidebar';
export const BASIC_INFO = 'basic-info';
export const BANK_ACCOUNTS = 'bank-accounts';
export const NOTIFICATIONS = 'notifications';
export const DOCUMENTS = 'documents';
export const FEATURE_FLAGS = 'flags';
export const PUBLISHER_SETTINGS = 'publisher-settings';
export const getSidebarSettingsForUser = (user) => {
    const userModel = getModelFromSnapshot(UserModel, getFirebaseContext(), user);
    const isPublishingUser = userModel.isPublisher;
    // Bank accounts are only shown in the user settings if the user is an advertiser
    // and is NOT part of any organization.
    const userInOrganization = user && isMemberOfOrganization(user, undefined);
    const showBankAccountsTab = !isPublishingUser && !userInOrganization;
    const showPublisherSettingsTab = !isPublishingUser &&
        user &&
        userModel.isOccupationType(OccupationType.individual);
    // Hiding the notifications tab for funeral directors until more notification features are defined for funeral homes
    const showNotificationsTab = !userModel.isOccupationType(OccupationType.funeral_director);
    const showFeatureFlagsTab = user &&
        (userModel.isColumnUser ||
            userHasPermission(user, Permissions.SETTINGS_FEATURE_FLAGS));
    return {
        showBankAccountsTab,
        showPublisherSettingsTab,
        showFeatureFlagsTab,
        showNotificationsTab,
        userInOrganization,
        isPublishingUser
    };
};
export default function UserSettingsSidebar({ onViewChange, activeView, user }) {
    const { showBankAccountsTab, showNotificationsTab, showPublisherSettingsTab, showFeatureFlagsTab, isPublishingUser } = getSidebarSettingsForUser(user);
    const updateView = (view) => {
        window.history.replaceState(null, '', `/settings/?tab=${view}`);
        onViewChange(view);
    };
    return (_jsxs("div", Object.assign({ className: "mt-3" }, { children: [_jsx(SidebarTab, { id: BASIC_INFO, labelText: "Basic Info", Icon: HomeIcon, onClick: () => updateView(BASIC_INFO), active: activeView === BASIC_INFO }), showNotificationsTab && (_jsx(SidebarTab, { id: NOTIFICATIONS, labelText: "Notifications", Icon: BellIcon, onClick: () => updateView(NOTIFICATIONS), active: activeView === NOTIFICATIONS })), showBankAccountsTab && (_jsx(SidebarTab, { id: BANK_ACCOUNTS, labelText: "Bank Accounts", Icon: CurrencyDollarIcon, onClick: () => updateView(BANK_ACCOUNTS), active: activeView === BANK_ACCOUNTS })), !isPublishingUser && (_jsx(SidebarTab, { id: DOCUMENTS, labelText: "Documents", Icon: DocumentTextIcon, onClick: () => updateView(DOCUMENTS), active: activeView === DOCUMENTS })), showPublisherSettingsTab && (_jsx(SidebarTab, { id: PUBLISHER_SETTINGS, labelText: "Publisher Settings", Icon: NewspaperIcon, onClick: () => updateView(PUBLISHER_SETTINGS), active: activeView === PUBLISHER_SETTINGS })), showFeatureFlagsTab && (_jsx(SidebarTab, { id: FEATURE_FLAGS, labelText: "Feature Flags", Icon: PencilSquareIcon, onClick: () => updateView(FEATURE_FLAGS), active: activeView === FEATURE_FLAGS }))] })));
}
