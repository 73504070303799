var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { ColumnService } from 'lib/services/directory';
import { useState } from 'react';
import { getFirebaseContext } from 'utils/firebase';
import { SelectAdjudicationAreaDropdown } from './SelectAdjudicationAreaDropdown';
export function SelectAdjudicationCityDropdown({ selectedCity, selectedCounty, selectedState, onCitySelected }) {
    const [californiaId, setCaliforniaId] = useState(null);
    const { value: cityOptions, isLoading: loadingAreas, isError: error } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            if (!selectedCounty) {
                return null;
            }
            const CaliforniaRef = yield getFirebaseContext()
                .adjudicationAreasRef()
                .where('type', '==', 'state')
                .where('name', '==', 'California')
                .get();
            setCaliforniaId(CaliforniaRef.docs[0].id);
            const countyRef = getFirebaseContext()
                .adjudicationAreasRef()
                .doc(selectedCounty.id);
            const citiesFromCounty = yield getFirebaseContext()
                .adjudicationAreasRef()
                .where('type', '==', 'place')
                .where('parent', '==', countyRef)
                .orderBy('name')
                .get();
            return citiesFromCounty.docs.map(doc => (Object.assign({ id: doc.id }, doc.data())));
        }),
        dependencies: [selectedCounty === null || selectedCounty === void 0 ? void 0 : selectedCounty.id],
        errorConfig: {
            message: 'Failed to load adjudication areas in placement flow',
            service: ColumnService.WEB_PLACEMENT
        }
    });
    function onChangeHandler(areaId) {
        const objectWithinList = cityOptions === null || cityOptions === void 0 ? void 0 : cityOptions.find(county => county.id === areaId);
        if (!objectWithinList)
            return;
        onCitySelected(objectWithinList);
    }
    if ((selectedState === null || selectedState === void 0 ? void 0 : selectedState.id) === californiaId &&
        (selectedCounty !== null || selectedCounty !== undefined)) {
        return (_jsx(SelectAdjudicationAreaDropdown, { selectedState: selectedCity, areaOptions: cityOptions, onChange: onChangeHandler, label: "City", placeholder: "Select a city", loading: loadingAreas, error: error }));
    }
    return null;
}
