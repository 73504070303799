var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { WrenchScrewdriverIcon } from '@heroicons/react/24/outline';
import { getOrThrow } from 'lib/utils/refs';
import { PRODUCTION_PROJECT, FIREBASE_PROJECT, DEMO_PROJECT } from 'lib/constants';
import ClickableIconWrapper from 'lib/components/TableLayout/ClickableIconWrapper';
const getAdminPageURL = () => {
    if (FIREBASE_PROJECT === PRODUCTION_PROJECT) {
        return 'https://retoo.lol';
    }
    if (FIREBASE_PROJECT === DEMO_PROJECT) {
        return 'https://column-admin-demo.web.app';
    }
    return 'http://localhost:3001';
};
export function BuildPaginationFilesButton({ issue, disabled }) {
    const [loading, setLoading] = useState(false);
    const loadPaginationFiles = () => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        const publisher = yield getOrThrow(issue.modelData.publisher);
        const adTemplate = yield getOrThrow(publisher.data().adTemplate);
        const { response: notices, error: noticeError } = yield issue.slowGetNoticesForPublicationIssue();
        if (noticeError) {
            console.error('Error getting notices for pagination', noticeError);
        }
        else {
            const paginationUrl = `${getAdminPageURL()}/pagination-builder?templateID=${adTemplate.id}&noticeIDs=${notices.map(n => n.id).join(',')}&issueID=${issue.id}`;
            window.open(paginationUrl, '_blank');
        }
        setLoading(false);
    });
    return (_jsx(ClickableIconWrapper, { id: `pagination-builder-${issue.id}`, loading: loading, icon: _jsx(WrenchScrewdriverIcon, { className: "h-5 w-5" }), onClick: loadPaginationFiles, disabled: disabled }));
}
