var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import api from 'api';
import { logAndCaptureException } from 'utils';
import { wrapError, wrapSuccess } from 'lib/types/responses';
import { ColumnService } from 'lib/services/directory';
import { Product } from 'lib/enums';
const handleNoticePaginationReview = (data) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const { response: audit, error } = yield api.post('pagination/pagination-review', data);
        if (!audit) {
            throw error;
        }
        return wrapSuccess(audit);
    }
    catch (err) {
        logAndCaptureException(ColumnService.PAGINATION, err, 'Error running visual automated review for notices');
        return wrapError(err);
    }
});
const handleOrderPaginationReview = (data) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const { response: audit, error } = yield api.post('pagination/order-pagination-review', data);
        if (!audit) {
            throw error;
        }
        return wrapSuccess(audit);
    }
    catch (err) {
        logAndCaptureException(ColumnService.PAGINATION, err, 'Error running visual automated review for orders');
        return wrapError(err);
    }
});
export const handlePaginationReview = ({ product, manifest, attachments, publicationIssue, section }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const isNoticePagination = product === Product.Notice;
    return isNoticePagination
        ? handleNoticePaginationReview({
            manifestStoragePath: manifest.path,
            attachmentStoragePaths: attachments.map(attachment => attachment.path),
            publicationIssueId: publicationIssue.id
        })
        : handleOrderPaginationReview({
            manifestStoragePath: manifest.path,
            sectionId: (_a = section === null || section === void 0 ? void 0 : section.id) !== null && _a !== void 0 ? _a : '',
            attachmentStoragePaths: attachments.map(attachment => attachment.path),
            publicationIssueId: publicationIssue.id
        });
});
