var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-await-in-loop */
import { getErrorReporter } from '../../utils/errors';
import { getModelFromRef, getModelFromSnapshot } from '../../model';
import { UserNoticeModel } from '../../model/objects/userNoticeModel';
import { UserModel } from '../../model/objects/userModel';
import { NoticeType } from '../../enums';
import { paperIsValidForCustomerPlacementFlow } from '../../publishers';
import { getOrThrow } from '../../utils/refs';
import { getNoticeTypeFromNoticeData, removeUndefinedFields } from '../../helpers';
import { wrapError, wrapSuccess } from '../../types/responses';
import { getNoticeMail } from '../../mail';
import { getOrCreateCustomer } from '../../notice/customer';
import { validateNotice } from './noticeValidation';
import { safeGetOrThrow } from '../../safeWrappers';
import { NOTICE_SUBMITTED_EVENT } from '../../types/events';
function getPublisherOrganizationFieldsForNotice(publisherOrganization) {
    if (!publisherOrganization)
        return {};
    const { adTemplate, defaultLinerRate, defaultNoticeType } = publisherOrganization.data();
    const noticeType = defaultNoticeType || NoticeType.custom.value;
    /**
     * NOTE: Consider refactoring how we populate initial madlib data to not depend on this
     * field being set on notice creation if the default notice type is a madlib
     * */
    const customNoticeType = getNoticeTypeFromNoticeData({ noticeType }, publisherOrganization, { skipDisplayType: true });
    const madlibData = (customNoticeType === null || customNoticeType === void 0 ? void 0 : customNoticeType.madlib)
        ? {
            templateData: {},
            questionTemplateData: {}
        }
        : undefined;
    return {
        newspaper: publisherOrganization.ref,
        adTemplate,
        rate: defaultLinerRate,
        noticeType,
        madlibData
    };
}
function getUserFieldsForNotice(user) {
    if (!user)
        return {};
    const { isPublisher } = user;
    const createdBy = isPublisher ? user.ref : undefined;
    const filer = !isPublisher ? user.ref : undefined;
    const filedBy = !isPublisher ? user.modelData.activeOrganization : undefined;
    return {
        createdBy,
        filer,
        filedBy,
        userId: user.id // TODO: Deprecate this field fully
    };
}
export class NoticeService {
    constructor(firebaseContext) {
        this.firebaseContext = firebaseContext;
    }
    /**
     * Create an incomplete starter notice document with an associated draft
     */
    createInitialNoticeWithDraft({ asUser, withAnonymousFilerId, inPublisherOrganization, withInitialData }) {
        return __awaiter(this, void 0, void 0, function* () {
            const initialNoticeFields = yield this.buildInitialNoticeFields({
                asUser,
                withAnonymousFilerId,
                inPublisherOrganization,
                withInitialData
            });
            const notice = yield this.saveNotice(initialNoticeFields);
            const userRef = asUser ? asUser.ref : null;
            const draftRef = yield notice.createDraft({ asUser: userRef });
            const draft = yield getOrThrow(draftRef);
            return {
                notice,
                draft
            };
        });
    }
    updateWithDraftData(noticeRef, draftRef, agent) {
        return __awaiter(this, void 0, void 0, function* () {
            const noticeModel = yield getModelFromRef(UserNoticeModel, this.firebaseContext, noticeRef);
            const { error, response } = yield noticeModel.updateWithDraftData({
                draftRef,
                agent
            });
            if (error)
                return wrapError(error);
            return wrapSuccess(response);
        });
    }
    /**
     * Publishes a notice using the given notice reference, draft reference, notice service agent, and account number.
     *
     * @param {ERef<ENotice>} noticeRef - The reference to the notice entity.
     * @param {ERef<ENoticeDraft>} draftRef - The reference to the notice draft entity.
     * @param {NoticeServiceAgent} agent - The notice service agent that will act for publishing the notice.
     * @param {boolean} isNew - Whether the notice is new or not. This is used to determine if a NOTICE_SUBMITTED_EVENT should be created.
     * @param {string | undefined} accountNumber - The account number to be updated on the notice (optional).
     * @returns {Promise<ResponseOrError<ENotice>>} - A promise that resolves with the updated notice or an error.
     */
    publishNoticeFromDraft(noticeRef, draftRef, agent, accountNumber) {
        return __awaiter(this, void 0, void 0, function* () {
            const noticeSnapshot = yield safeGetOrThrow(noticeRef);
            if (noticeSnapshot.error)
                return wrapError(noticeSnapshot.error);
            const isNew = !noticeSnapshot.response.data().noticeStatus;
            const { response: noticeModel, error: updateError } = yield this.updateWithDraftData(noticeRef, draftRef, agent);
            if (updateError)
                return wrapError(updateError);
            yield draftRef.update(removeUndefinedFields({
                editedAt: this.firebaseContext.timestamp(),
                lastEditedBy: agent.user
            }));
            const { response: publishResponse, error: publishError } = yield this.publishNotice(noticeModel, agent, isNew);
            if (publishError)
                return wrapError(publishError);
            // post publication actions
            const { error: customerUpdateError } = yield this.updateCustomerForNotice(noticeModel);
            if (customerUpdateError)
                wrapError(customerUpdateError);
            if (accountNumber) {
                yield noticeModel.maybeUpdateAccountNumberOnNotice(accountNumber);
            }
            return wrapSuccess(publishResponse);
        });
    }
    publishNotice(noticeModel, agent, isNew) {
        return __awaiter(this, void 0, void 0, function* () {
            const { error: validationError } = yield validateNotice(noticeModel.modelData, this.firebaseContext);
            if (validationError) {
                return wrapError(new Error(`Failed to validate notice: ${validationError.message}`, {
                    cause: validationError
                }));
            }
            const { error: noticePublishError } = yield noticeModel.publish();
            if (noticePublishError)
                return wrapError(noticePublishError);
            if (isNew) {
                yield this.firebaseContext.eventsRef().add({
                    type: NOTICE_SUBMITTED_EVENT,
                    createdAt: this.firebaseContext.fieldValue().serverTimestamp(),
                    notice: noticeModel.ref,
                    data: removeUndefinedFields({
                        newspaper: noticeModel.modelData.newspaper,
                        filer: noticeModel.modelData.filer,
                        publicationDates: noticeModel.modelData.publicationDates,
                        source: agent.source,
                        submittedNoticeData: noticeModel.modelData
                    })
                });
            }
            return wrapSuccess(noticeModel);
        });
    }
    saveNotice(noticeData) {
        return __awaiter(this, void 0, void 0, function* () {
            const noticeRef = this.firebaseContext.userNoticesRef().doc();
            yield noticeRef.set(noticeData);
            return yield getModelFromRef(UserNoticeModel, this.firebaseContext, noticeRef);
        });
    }
    buildInitialNoticeFields({ asUser, withAnonymousFilerId, inPublisherOrganization, withInitialData }) {
        return __awaiter(this, void 0, void 0, function* () {
            const publisherOrganization = inPublisherOrganization
                ? yield getOrThrow(inPublisherOrganization)
                : null;
            if (publisherOrganization) {
                const publisherEnabledForPlacement = paperIsValidForCustomerPlacementFlow(publisherOrganization);
                if (!publisherEnabledForPlacement) {
                    getErrorReporter().logInfo('Placing notice for paper not enabled for customer placement flow', {
                        publisherOrganizationId: publisherOrganization.id
                    });
                }
            }
            const user = asUser
                ? getModelFromSnapshot(UserModel, this.firebaseContext, asUser)
                : null;
            const initialNotice = Object.assign(Object.assign(Object.assign(Object.assign({ createTime: this.firebaseContext.fieldValue().serverTimestamp(), isArchived: false }, getUserFieldsForNotice(user)), getPublisherOrganizationFieldsForNotice(publisherOrganization)), { anonymousFilerId: withAnonymousFilerId || undefined }), withInitialData);
            return removeUndefinedFields(initialNotice);
        });
    }
    /**
     * Adds any mail delivery requests on Notice to the corresponding filer document.
     *
     * @param {UserNoticeModel} notice - The UserNoticeModel object to update the filer for.
     * @return {Promise<ResponseOrError<null>>} - ResponseOrError object with null response if op was successful.
     */
    addMailDeliveryRequestsToFiler(notice) {
        return __awaiter(this, void 0, void 0, function* () {
            const { filer, newspaper } = notice.modelData;
            let mailDocs;
            try {
                mailDocs = yield getNoticeMail(notice.ref);
            }
            catch (e) {
                return wrapError(e);
            }
            const mailsToBeSaved = [];
            if (mailDocs.length > 0) {
                for (const mail of mailDocs) {
                    const { name, copies, address, isCourthouse, isNoticeTypeDefault, courtHouse } = mail.data();
                    if (!isNoticeTypeDefault) {
                        mailsToBeSaved.push(Object.assign(Object.assign({ name,
                            copies,
                            address }, (isCourthouse && { isCourthouse })), (courtHouse && { courtHouse })));
                    }
                }
            }
            try {
                yield filer.update({
                    savedInfo: Object.assign({ newspaper }, (mailsToBeSaved.length && { mails: mailsToBeSaved }))
                });
                return wrapSuccess(null);
            }
            catch (e) {
                return wrapError(e);
            }
        });
    }
    /**
     * Updates the customer for a notice.
     *
     * @param {UserNoticeModel} notice - The notice to update the customer for.
     * @return {Promise<ResponseOrError<null>>} - A promise that resolves to a null response if successful.
     */
    updateCustomerForNotice(notice) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const user = yield getOrThrow(notice.modelData.filer);
                const newspaper = yield getOrThrow(notice.modelData.newspaper);
                const customer = yield getOrCreateCustomer(this.firebaseContext, user, newspaper);
                if (customer.data().archived) {
                    yield customer.ref.update({ archived: false });
                }
                return wrapSuccess(null);
            }
            catch (e) {
                return wrapError(e);
            }
        });
    }
    getNoticeEventsQuery(notice) {
        return this.firebaseContext
            .eventsRef()
            .where('notice', '==', notice.ref)
            .orderBy('createdAt');
    }
}
