import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { TextField } from 'lib/components/TextField';
import { ColumnButton } from 'lib/components/ColumnButton';
import React, { useState } from 'react';
import LoadingState from 'components/LoadingState';
import { Alert } from 'lib/components/Alert';
import CouponBadge from './CouponBadge';
function Coupons({ invoiceLoading, couponLoading, coupons, couponError, onAddCoupon, onRemoveCoupon }) {
    const [couponCode, setCouponCode] = useState('');
    return (_jsx(_Fragment, { children: _jsx("div", Object.assign({ className: "grid grid-cols-2 gap-6" }, { children: _jsxs("div", Object.assign({ className: "col-span-2" }, { children: [_jsx(TextField, { type: "text", id: "coupon-code-input", labelText: "Discount Code", value: couponCode || '', onChange: couponCode => setCouponCode(couponCode) }), _jsxs("div", Object.assign({ className: "mt-2 flex justify-end" }, { children: [couponLoading && _jsx(LoadingState, { isFullScreen: false }), !couponLoading && (_jsx(ColumnButton, { type: "button", buttonText: "Apply Discount Code", secondary: true, loading: couponLoading, disabled: invoiceLoading, onClick: () => {
                                    const code = couponCode;
                                    setCouponCode('');
                                    void onAddCoupon(code);
                                } }))] })), _jsx("div", Object.assign({ className: "mt-2 flex justify-end" }, { children: !couponLoading &&
                            !invoiceLoading &&
                            coupons &&
                            coupons.map(coupon => (_jsx(CouponBadge, { coupon: coupon, onClose: () => onRemoveCoupon(coupon.modelData.code) }, coupon.id))) })), couponError && (_jsx(Alert, { id: "coupon-error", status: "error", title: couponError }))] })) })) }));
}
export default Coupons;
