var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Cog8ToothIcon } from '@heroicons/react/24/outline';
import { TableLayout } from 'lib/components/TableLayout';
import { ColumnButton } from 'lib/components/ColumnButton';
import { Product } from 'lib/enums';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { last } from 'lodash';
import FilingTypeTableRow from './FilingTypeTableRow';
/**
 * Table component for visualizing and editing filing types
 */
export default function FilingTypeTable({ onClickGlobalControls, onEditFilingType, onViewFilingType, activeOrganization, filingTypes, archivable, creatable, productLine }) {
    const [rateNameCache, setRateNameCache] = useState({});
    const [templateNameCache, setTemplateNameCache] = useState({});
    const productNameSingular = last(PRODUCT_TO_NAME[productLine].singular.split(' '));
    /**
     * Fetches rate and template data for each filing type and caches it in state
     */
    const cacheRateAndTemplateData = () => __awaiter(this, void 0, void 0, function* () {
        filingTypes.forEach((filingType) => __awaiter(this, void 0, void 0, function* () {
            if (filingType.rate) {
                const rate = yield filingType.rate.get();
                setRateNameCache(prev => {
                    var _a;
                    return (Object.assign(Object.assign({}, prev), { [rate.id]: ((_a = rate.data()) === null || _a === void 0 ? void 0 : _a.description) || '' }));
                });
            }
            // TODO: remove the feature flag condition when APP-3453 is complete
            if (filingType.template) {
                const template = yield filingType.template.get();
                setTemplateNameCache(prev => {
                    var _a;
                    return (Object.assign(Object.assign({}, prev), { [template.id]: ((_a = template.data()) === null || _a === void 0 ? void 0 : _a.name) || '' }));
                });
            }
        }));
    });
    useEffect(() => {
        void cacheRateAndTemplateData();
    }, [filingTypes]);
    return (_jsx(TableLayout, { data: filingTypes, header: {
            title: `Categories`,
            subtitle: `Add and manage ${productNameSingular.toLocaleLowerCase()} categories for your paper.`,
            additionalContent: productLine === Product.Notice ? (_jsx("div", Object.assign({ className: "mr-4" }, { children: _jsx(ColumnButton, { buttonText: "Global Settings", startIcon: _jsx(Cog8ToothIcon, { className: "w-6 h-6" }), onClick: () => onClickGlobalControls(), type: "button" }) }))) : undefined
        }, archivable: archivable, creatable: creatable, filterable: {
            shouldShowTableItem: (filingType, search) => {
                return filingType.label
                    .toLowerCase()
                    .includes(search.toLocaleLowerCase());
            }
        }, editable: {
            onEdit: filingType => onEditFilingType(filingType)
        }, columns: [
            `Category`,
            'Rate',
            'InDesign Template',
            `${productNameSingular} Template`
        ], renderRow: filingType => (_jsx(FilingTypeTableRow, { activeOrganization: activeOrganization, templateNameCache: templateNameCache, rateNameCache: rateNameCache, filingType: filingType })), clickable: {
            onClick: filingType => onViewFilingType(filingType)
        } }));
}
