var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { ColumnService } from 'lib/services/directory';
import { getFirebaseContext } from 'utils/firebase';
import { SelectAdjudicationAreaDropdown } from './SelectAdjudicationAreaDropdown';
export function SelectAdjudicationStateDropdown({ selectedState, onStateSelected }) {
    const { value: stateOptions, isLoading: loadingAreas, isError: error } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            const statesRef = yield getFirebaseContext()
                .adjudicationAreasRef()
                .where('type', '==', 'state')
                .orderBy('name')
                .get();
            return statesRef.docs.map(doc => (Object.assign({ id: doc.id }, doc.data())));
        }),
        dependencies: [],
        errorConfig: {
            message: 'Failed to load adjudication areas in placement flow',
            service: ColumnService.WEB_PLACEMENT
        }
    });
    function onChangeHandler(areaId) {
        const objectWithinList = stateOptions === null || stateOptions === void 0 ? void 0 : stateOptions.find(state => state.id === areaId);
        if (!objectWithinList)
            return;
        onStateSelected(objectWithinList);
    }
    return (_jsx(SelectAdjudicationAreaDropdown, { selectedState: selectedState, areaOptions: stateOptions, onChange: onChangeHandler, label: "State", placeholder: "Select a state", loading: loadingAreas, error: error }));
}
