// TODO: This order status will be used as a discriminated type for the order to define props at various stages of the order lifecycle
export var OrderStatus;
(function (OrderStatus) {
    /**
     * Order is unpaid and/or incomplete
     */
    OrderStatus["DRAFT"] = "draft";
    /**
     * Order is paid and newspaperOrders are visible to the newspaper
     */
    OrderStatus["PENDING"] = "pending";
    /**
     * All newspaperOrders have been archived as complete
     */
    OrderStatus["COMPLETE"] = "complete";
    /**
     * Order has been cancelled (TODO)
     */
    OrderStatus["CANCELLED"] = "cancelled";
})(OrderStatus || (OrderStatus = {}));
/** @returns true if the order was placed by or on behalf of an anonymous user (rather than a registered advertiser user). */
export const isAnonymousOrder = (order) => !Object.keys(order).includes('advertiser');
/** @returns true if the order was created by an anonymous user (rather than a registered advertiser user). */
export const isAnonymousUserOrder = (order) => !Object.keys(order).includes('user');
/** @returns true if the order was placed by a publisher on behalf of an anonymous user (rather than a registered advertiser user). */
const isPublisherAsAnonymousOrder = (order) => isAnonymousOrder(order) && !isAnonymousUserOrder(order);
/** @returns true if the order was placed by or on behalf of an advertiser with an organization (rather than an individual). */
export const isAdvertiserWithOrganizationOrder = (order) => Object.keys(order).includes('advertiserOrganization') &&
    order
        .advertiserOrganization !== null;
/* This is only needed because the customer search shows singular customers (rather than customer
  organizations). If the UI changes to show organizations instead of customers, this will no longer
  need special consideration. */
/** @returns true if the order was placed by a publisher on behalf of an advertiser with an organization. */
export const isPublisherAsAdvertiserWithOrganizationOrder = (order) => isAdvertiserWithOrganizationOrder(order) &&
    Object.keys(order).includes('advertiserCustomer');
/** @returns true if the order was placed by a publisher. */
export const isPublisherOrder = (order) => isPublisherAsAnonymousOrder(order) ||
    isPublisherAsAdvertiserWithOrganizationOrder(order);
