import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Popover } from 'lib/components/Popover';
import { dbToUICurrencyString } from 'lib/pricing/ui';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import classNames from 'classnames';
import { PricePreviewNewspaperOrders } from './PricePreviewNewspaperOrders';
import { PricePreviewPopoverRow } from './PricePreviewPopoverRow';
export function PricePreview({ consolidatedOrderPricing, loading, priceIsStale }) {
    var _a;
    const shouldShowDropdownMenu = consolidatedOrderPricing !== undefined && !priceIsStale;
    const displayedPrice = dbToUICurrencyString((_a = consolidatedOrderPricing === null || consolidatedOrderPricing === void 0 ? void 0 : consolidatedOrderPricing.totalInCents) !== null && _a !== void 0 ? _a : 0);
    return (_jsxs("div", Object.assign({ className: "flex flex-col md:flex-row items-center justify-between gap-2" }, { children: [loading && _jsx(LoadingSpinner, {}), !loading && (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: classNames({ 'text-column-yellow-700': priceIsStale }) }, { children: ["$", displayedPrice] })), shouldShowDropdownMenu && (_jsx(Popover, Object.assign({ id: "price-preview-popover", activator: _jsx("div", Object.assign({ className: "p-2" }, { children: _jsx(ChevronDownIcon, { className: "h-5 w-5" }) })), alignment: "right" }, { children: _jsxs("div", Object.assign({ className: "md:w-64 p-4" }, { children: [_jsx("div", Object.assign({ className: "text-left mt-2 mb-6" }, { children: "Order Summary" })), _jsx(PricePreviewNewspaperOrders, { newspaperOrderPublishingDataGroup: consolidatedOrderPricing.newspaperOrderPublishingDataGroup }), _jsxs(PricePreviewPopoverRow, { children: [_jsx("div", Object.assign({ className: "font-bold" }, { children: "Total price" })), _jsxs("div", Object.assign({ className: "font-bold" }, { children: ["$", dbToUICurrencyString(consolidatedOrderPricing.totalInCents)] }))] })] })) })))] }))] })));
}
