class CustomerService {
    constructor(context) {
        this.context = context;
    }
    getCustomerFromUserAndOrg(user, organization) {
        const customerRef = this.context
            .customersRef()
            .doc(`${user.id}_${organization.id}`);
        return customerRef.get();
    }
}
export default CustomerService;
