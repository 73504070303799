import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Autocomplete } from 'lib/components/Autocomplete';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { Alert } from 'lib/components/Alert';
export function SelectAdjudicationAreaDropdown({ areaOptions, selectedState, onChange, label, placeholder, loading, error }) {
    if (!areaOptions) {
        return null;
    }
    if (loading)
        return _jsx(LoadingSpinner, {});
    if (error) {
        return (_jsx(Alert, { id: "adjudication-areas-loading-error", status: "error", title: "Error retrieving Adjudication Areas" }));
    }
    return (_jsx(Autocomplete, { id: `select${label}`, labelText: label, onChange: onChange, placeholder: placeholder, value: (selectedState === null || selectedState === void 0 ? void 0 : selectedState.id) || '', options: areaOptions.map(area => ({
            value: area.id,
            label: area.name || ''
        })) }));
}
