var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { exists } from 'lib/types';
import { Autocomplete } from 'lib/components/Autocomplete';
import { ColumnService } from 'lib/services/directory';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { getErrorReporter } from 'lib/utils/errors';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Alert } from 'lib/components/Alert';
import { fetchAdjudicationAreas, groupAdjudicationAreasByParent } from 'routes/settings/publisher/publisherSettings/AdjudicationInfo/helper';
export function SelectAdjudicatedPublisherDropdown({ publisherOrganization, onPublisherOrganizationChange, publisherOptions, loading }) {
    const formattedPublisherOptions = publisherOptions.map(formatOptions);
    const { value: noteText, isLoading: loadingText, isError: error } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            const result = yield getPublisherLocationNoteText(publisherOrganization);
            return result;
        }),
        dependencies: [publisherOrganization === null || publisherOrganization === void 0 ? void 0 : publisherOrganization.id],
        errorConfig: {
            message: 'Failed to load adjudication areas for SelectPublisherDropdown',
            service: ColumnService.WEB_PLACEMENT,
            tags: {
                activeOrganizationId: (publisherOrganization === null || publisherOrganization === void 0 ? void 0 : publisherOrganization.id) || ''
            }
        }
    });
    if (error) {
        _jsx(Alert, { id: "publisher-dropdown-naming-error", status: "error", icon: _jsx(InformationCircleIcon, { className: "w-5 h-5" }), title: "Error when retrieving adjudication areas name" });
    }
    return (_jsx(Autocomplete, { id: "selectPublisher", labelText: "Publisher", noteText: !loading && !loadingText ? noteText : '', placeholder: "Select a publisher", value: exists(publisherOrganization) && !loading
            ? publisherOrganization.id
            : '', options: formattedPublisherOptions, onChange: onPublisherOrganizationChange, loading: loading, noOptionsMessage: "No results. Please start a new search or update your filters" }));
}
function formatOptions(paper) {
    return {
        value: paper.id,
        label: paper.name
    };
}
function getPublisherLocationNoteText(publisherOrganization) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!publisherOrganization) {
            getErrorReporter().logAndCaptureWarning('No organization selected/found in notice placement');
            return;
        }
        const adjudicationAreas = yield fetchAdjudicationAreas(publisherOrganization);
        const grouped = yield groupAdjudicationAreasByParent(adjudicationAreas);
        let areaLabel = '';
        Object.keys(grouped).forEach(key => {
            const countiesFromState = Object.keys(grouped[key].counties);
            if (countiesFromState.length > 0) {
                const countyNames = countiesFromState.map(countyKey => grouped[key].counties[countyKey].name);
                areaLabel =
                    areaLabel.length === 0
                        ? `${countyNames.join(', ')} ${grouped[key].name}`
                        : `${areaLabel} and ${countyNames.join(', ')} ${grouped[key].name}`;
            }
        });
        const noteText = areaLabel
            ? `This newspaper publishes in ${areaLabel}.`
            : undefined;
        return noteText;
    });
}
