import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { CustomerTypeString } from 'routes/ads/filters/helpers';
import FlowChoiceForm from './FlowChoiceForm';
import FuneralHomeCustomerSearch from './FuneralHomeSearch';
import MultiStepHeader from '../../components/MultiStepHeader';
function FlowChoiceStep({ chosenCustomer, onChosenCustomerChange }) {
    const [flowChoice, setFlowChoice] = useState(chosenCustomer
        ? CustomerTypeString.FUNERAL_HOME
        : CustomerTypeString.INDIVIDUAL);
    return (_jsxs(_Fragment, { children: [_jsx(MultiStepHeader, { title: "Customer type", description: "Select the type of customer you are placing for." }), _jsxs("div", Object.assign({ className: "grid gap-4" }, { children: [_jsx(FlowChoiceForm, { onFlowChoiceChange: o => {
                            setFlowChoice(o);
                            onChosenCustomerChange(undefined);
                        }, flowChoice: flowChoice }), flowChoice === CustomerTypeString.FUNERAL_HOME && (_jsx(FuneralHomeCustomerSearch, { chosenCustomer: chosenCustomer, onChosenCustomerChange: o => {
                            onChosenCustomerChange(o);
                        } }))] }))] }));
}
export default FlowChoiceStep;
