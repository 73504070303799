var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment';
import axios from 'axios';
import { exists } from '../types';
import { PAGINATION_UPLOAD_DESTINATIONS, TEST_UPLOAD_DESTINATION } from './constants';
import { FIREBASE_PROJECT, PRODUCTION_PROJECT } from '../constants';
import { cdnIfy, isColumnUser } from '../helpers';
import { wrapError, wrapSuccess } from '../types/responses';
import { LaunchDarklyFlags } from '../types/launchDarklyFlags';
import { getLaunchDarklyContext } from '../utils/flags';
import { getErrorReporter } from '../utils/errors';
export const getUploadDestinationByNewspaperCode = (newspaperCode) => {
    if (FIREBASE_PROJECT !== PRODUCTION_PROJECT) {
        return TEST_UPLOAD_DESTINATION;
    }
    const uploadDestination = PAGINATION_UPLOAD_DESTINATIONS.find(destination => destination.newspaperCode === newspaperCode);
    if (uploadDestination) {
        return uploadDestination;
    }
    throw new Error(`Could not find upload destination for newspaper code: ${newspaperCode}`);
};
export const getUploadDestinationByOrgId = (orgId) => {
    if (FIREBASE_PROJECT !== PRODUCTION_PROJECT) {
        return TEST_UPLOAD_DESTINATION;
    }
    const uploadDestination = PAGINATION_UPLOAD_DESTINATIONS.find(destinations => destinations.organizationId === orgId);
    if (uploadDestination) {
        return uploadDestination;
    }
    throw new Error(`Could not find upload destination for newspaper ID: ${orgId}`);
};
export const getNewspaperCodeByOrgId = (orgId) => {
    const newspaperSite = getUploadDestinationByOrgId(orgId);
    return newspaperSite.newspaperCode;
};
/**
 * Splits the newspaper code into its site and publication code parts, then
 * joins them with the date and a sequence number.
 *
 * E.g., given a newspaper code of 'COL-NEWS', a publication date of 12/13, and
 * an index of 0, this function would return 'COL1213NEWS01.pdf'.
 */
export const getPaginationFileName = (newspaperCode, publicationDate, index) => {
    const code = newspaperCode || 'XXX-X';
    const [siteCode = '', publicationCode = ''] = code.split('-');
    if (!publicationCode) {
        getErrorReporter().logInfo('[getPaginationFileName] Newspaper code does not have publication code (missing hyphen or no publication code after hyphen)', { code });
    }
    const dateString = moment(publicationDate).format('MMDD');
    const sequenceNumber = (index + 1).toString().padStart(2, '0');
    return `${siteCode}${dateString}${publicationCode}${sequenceNumber}.pdf`;
};
const getFileDimensionsFromPixelsAndDPI = (pixelWidth, pixelHeight, DPIWidth, DPIHeight) => {
    // Round these values to the nearest hundredth
    const heightInInches = parseFloat((pixelHeight / DPIHeight).toFixed(2));
    const widthInInches = parseFloat((pixelWidth / DPIWidth).toFixed(2));
    return { height: heightInInches, width: widthInInches };
};
export const getJpgFileDimensions = (storagePath) => __awaiter(void 0, void 0, void 0, function* () {
    /**
     * imgix has stopped sending back DPI for JPGs, so we use a default instead
     * Slack thread: https://columnpbc.slack.com/archives/C0630CV3998/p1705693259428069
     */
    const defaultDPI = 600;
    const useColumnCDN = yield getLaunchDarklyContext().getBooleanFeatureFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN, { type: 'anonymous', defaultValue: false });
    const sizeURL = cdnIfy(storagePath, {
        useImgix: true,
        imgixTransformations: { fm: 'json' },
        useColumnCDN
    });
    const sizeResponse = yield axios.get(sizeURL);
    const res = yield sizeResponse.data;
    const { PixelHeight, PixelWidth } = res;
    if (!PixelHeight || !PixelWidth) {
        throw new Error(`Could not get dimensions for file at ${storagePath}`);
    }
    if (typeof PixelHeight !== 'number' || typeof PixelWidth !== 'number') {
        throw new Error(`Unexpected type encountered in file dimensions: ${typeof PixelHeight} (PixelHeight), ${typeof PixelWidth} (PixelWidth)`);
    }
    return getFileDimensionsFromPixelsAndDPI(PixelWidth, PixelHeight, defaultDPI, defaultDPI);
});
export const getPDFFileDimensions = (storagePath, DPIHeight, DPIWidth) => __awaiter(void 0, void 0, void 0, function* () {
    const useColumnCDN = yield getLaunchDarklyContext().getBooleanFeatureFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN, { type: 'anonymous', defaultValue: false });
    const sizeURL = cdnIfy(storagePath, {
        useImgix: true,
        imgixTransformations: { fm: 'json' },
        useColumnCDN
    });
    try {
        const sizeResponse = yield axios.get(sizeURL);
        const res = yield sizeResponse.data;
        const { PixelHeight, PixelWidth } = res;
        if (!PixelHeight || !PixelWidth) {
            return wrapError(new Error(`Could not get dimensions for file at ${storagePath}`));
        }
        if (typeof PixelHeight !== 'number' || typeof PixelWidth !== 'number') {
            return wrapError(new Error(`Unexpected type encountered in file dimensions: ${typeof PixelHeight} (PixelHeight), ${typeof PixelWidth} (PixelWidth)`));
        }
        return wrapSuccess(getFileDimensionsFromPixelsAndDPI(PixelWidth, PixelHeight, DPIWidth, DPIHeight));
    }
    catch (err) {
        return wrapError(err);
    }
});
export const shouldShowDisabledColumnForUser = (user) => {
    return exists(user) && isColumnUser(user);
};
export const __private = {
    getFileDimensionsFromPixelsAndDPI
};
