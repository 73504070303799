import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { firestoreTimestampOrDateToDate } from 'lib/helpers';
import { dbToUICurrencyString } from 'lib/pricing/ui';
import moment from 'moment';
import { useContext, useState } from 'react';
import { Alert } from 'lib/components/Alert';
import { LineItemType } from 'lib/enums';
import { Badge } from 'lib/components/Badge';
import { capitalize } from 'lodash';
import AdSizeDrawer from './AdSizeDrawer';
import NewspaperOrgImage from '../PublisherImage';
import { NewspapersContext } from '../../contexts/NewspapersContext';
import { PricePreviewPopoverRow } from './PricePreviewPopoverRow';
function PricePreviewLineItem({ lineItem }) {
    let description;
    if (lineItem.type === LineItemType.fee.value) {
        description = lineItem.description;
    }
    else {
        description = moment(firestoreTimestampOrDateToDate(lineItem.date)).format('MM/DD/YYYY');
    }
    return (_jsxs(PricePreviewPopoverRow, { children: [_jsx("div", { children: description }), _jsxs("div", { children: ["$", dbToUICurrencyString(lineItem.amount)] })] }));
}
function PricePreviewNewspaperLineItems({ newspaper, newspaperOrderPublishingData }) {
    const subtotalInCents = newspaperOrderPublishingData.lineItems.reduce((acc, lineItem) => acc + lineItem.amount, 0);
    return (_jsxs(_Fragment, { children: [_jsx(PricePreviewPopoverRow, Object.assign({ first: true }, { children: _jsx("div", Object.assign({ className: "w-32" }, { children: _jsx(NewspaperOrgImage, { newspaper: newspaper, className: "h-6" }) })) })), _jsx(PricePreviewPopoverRow, { children: _jsx(Badge, { children: capitalize(newspaperOrderPublishingData.publishingMedium) }) }), _jsx(PricePreviewPopoverRow, { children: _jsx(AdSizeDrawer, { newspaperOrderPublishingData: newspaperOrderPublishingData }) }), _jsx(PricePreviewPopoverRow, { children: _jsx("div", Object.assign({ className: "font-bold" }, { children: "Publication date" })) }), newspaperOrderPublishingData.lineItems.map((lineItem, index) => {
                const key = `${newspaper.data().name}-lineitem${index}`;
                return (_jsx(PricePreviewLineItem, { lineItem: lineItem, newspaperName: newspaper.data().name, index: index }, key));
            }), _jsx("div", Object.assign({ className: "mb-12" }, { children: _jsx("hr", {}) })), _jsxs(PricePreviewPopoverRow, Object.assign({ last: true }, { children: [_jsx("div", { children: "Subtotal" }), _jsxs("div", { children: ["$", dbToUICurrencyString(subtotalInCents)] })] }))] }));
}
export function PricePreviewNewspaperOrders({ newspaperOrderPublishingDataGroup }) {
    const newspapers = useContext(NewspapersContext);
    const [error, setError] = useState('');
    return (_jsxs(_Fragment, { children: [error && _jsx(Alert, Object.assign({ id: "newspaper-load-error" }, { children: error })), newspaperOrderPublishingDataGroup.map((newspaperOrderPublishingData, index) => {
                const newspaper = newspapers.find(n => n.id === newspaperOrderPublishingData.newspaperId);
                if (!newspaper) {
                    setError('Newspaper not found. Please refresh the page.');
                    return;
                }
                const key = `${newspaper.data().name}-${index}`;
                return (_jsx(PricePreviewNewspaperLineItems, { newspaperOrderPublishingData: newspaperOrderPublishingData, newspaper: newspaper }, key));
            })] }));
}
