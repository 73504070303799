import { call, take, put, takeLatest } from 'redux-saga/effects';
import { pathToRegexp } from 'path-to-regexp';
import QueryString from 'qs';
import { LOCATION_CHANGE, replace, push } from 'connected-react-router';
import { includeSearchParams, getSubdomain, getHostname } from 'utils/urls';
import { getUserNeedsToBeRedirectedToRegistration } from 'lib/utils/users';
import { authSelector, AuthTypes, selectDefaultOrdersRoute, selectHasClassifiedsActive, selectHasObituariesActive, selectHasPublicNoticesActive } from 'redux/auth';
import { exists } from 'lib/types';
import { OrganizationType, State } from 'lib/enums';
import { getFirebaseContext } from 'utils/firebase';
import { appSagaSelect } from 'redux/hooks';
import { isColumnUser } from 'lib/helpers';
import { logInfo } from 'utils/logger';
import { POST_ORGANIZATION_REGISTRATION_ENTRYPOINT, PUBLISHER_REGISTRATION_ENTRYPOINT, POST_REGISTRATION_ENTRYPOINT, routerSelector } from 'redux/router';
function* getLandingPage() {
    const auth = yield* appSagaSelect(authSelector);
    let { user } = auth;
    const { userAuth } = auth;
    // Firebase Auth reports a user, but we have not yet loaded the user
    // document from Firestore
    if (userAuth && !user && !auth.isClaimLogin) {
        user = (yield take(AuthTypes.SET_USER)).user;
    }
    if (exists(user)) {
        const userData = user.data();
        const activeOrg = userData.activeOrganization &&
            (yield call([
                userData.activeOrganization,
                userData.activeOrganization.get
            ]));
        const activeOrgData = activeOrg === null || activeOrg === void 0 ? void 0 : activeOrg.data();
        const defaultOrdersPage = yield* appSagaSelect(selectDefaultOrdersRoute);
        return (activeOrgData === null || activeOrgData === void 0 ? void 0 : activeOrgData.organizationType) ===
            OrganizationType.holding_company.value && (activeOrgData === null || activeOrgData === void 0 ? void 0 : activeOrgData.reportUrl)
            ? '/reports/'
            : defaultOrdersPage;
    }
    const subdomain = getSubdomain();
    if (['florida'].indexOf(subdomain) !== -1) {
        return '/login/';
    }
    if (State.by_key(subdomain)) {
        return '/search/';
    }
    if (['wapo-production'].indexOf(subdomain) !== -1) {
        return '/search/';
    }
    const hostname = getHostname();
    if (['publicnoticecolorado', 'washingtonpost'].indexOf(hostname) !== -1)
        return '/search/';
    return '/login/';
}
export function* getRedirect() {
    const r = yield* appSagaSelect(routerSelector);
    const parsedQuerystring = QueryString.parse(r.location.search);
    const redirect = parsedQuerystring.redirect
        ? String(parsedQuerystring.redirect)
        : undefined;
    return decodeURIComponent(redirect || '/');
}
export function* maybeRedirectToRegistration() {
    const { user } = yield* appSagaSelect(authSelector);
    const { location } = yield* appSagaSelect(routerSelector);
    if (!user)
        return false;
    const userNeedsToBeRedirectedToRegistration = yield call(getUserNeedsToBeRedirectedToRegistration, {
        ctx: getFirebaseContext(),
        user,
        pathname: location.pathname
    });
    if (userNeedsToBeRedirectedToRegistration) {
        yield put(push(POST_REGISTRATION_ENTRYPOINT));
        return true;
    }
    const userData = user.data();
    const currentlyOnRegistrationRoute = location.pathname.includes('/register') &&
        !location.pathname.includes(POST_ORGANIZATION_REGISTRATION_ENTRYPOINT);
    if (currentlyOnRegistrationRoute && userData.postRegistrationComplete) {
        yield put(push('/'));
        return true;
    }
    return false;
}
export function* redirect(action) {
    logInfo('RoutingSaga.redirect', action.payload);
    const { user } = yield* appSagaSelect(authSelector);
    if (!user)
        yield take([AuthTypes.SET_USER, AuthTypes.END_AUTH]);
    function* restrict() {
        const { pathname } = action.payload.location;
        const { userAuth } = yield* appSagaSelect(authSelector);
        if (!userAuth)
            yield put(push(`/login/?redirect=${encodeURIComponent(pathname)}`));
    }
    function* restrictInternal() {
        const { pathname } = action.payload.location;
        const authState = yield* appSagaSelect(authSelector);
        const { userAuth, user } = authState;
        if (!userAuth)
            yield put(push(`/login/?redirect=${encodeURIComponent(pathname)}`));
        if (!user || !isColumnUser(user)) {
            yield put(replace(`/`));
        }
    }
    const needsToRedirectToRegistration = yield call(maybeRedirectToRegistration);
    if (needsToRedirectToRegistration)
        return;
    const { pathname } = action.payload.location;
    const test = (path) => {
        return pathToRegexp(path).regexp.test(pathname);
    };
    switch (true) {
        case test('/'): {
            const landingPage = yield call(getLandingPage);
            yield put(replace(includeSearchParams(landingPage)));
            break;
        }
        // routes restricted for logged in users
        case test('/login'): {
            const { userAuth, isClaimLogin } = yield* appSagaSelect(authSelector);
            if (userAuth && !isClaimLogin) {
                const redirect = yield call(getRedirect);
                yield put(push(redirect));
            }
            break;
        }
        // unrestricted routes
        case test('/search'):
        case test('/obituaries/place'):
        case test('/obituaries/place/{.:id}'):
        case test('/obituaries/edit/:orderId'):
        case test('/classifieds/place'):
        case test('/classifieds/place/{.:id}'):
        case test('/classifieds/edit/:orderId'):
        case test('/obituary/:id'):
        case test('/classified/:id'):
        case test('/verify'):
        case test('/register'):
        case test('/place/{.:id}'):
        case test('/rfps/:id'):
        case test('/foreclosures/:id'):
        case test('/form/:noticeType/:noticeId'):
        case test('/form/newspaper/:noticeId'):
        case test('/association'):
        case test('/file/by-type/:type'):
        case test('/file/:id/{.:noticeSlug}'):
        case test('/invites/:id'):
        case test('/invoices/:id/pay'):
        case test('/forgot-password'):
        case test('/reset-password'):
        case test('/update-email'):
        case test('/public-notice/:id'): {
            break;
        }
        // restricted routes
        case test('/logout'):
        case test('/register/occupations'):
        case test('/register/organization'):
        case test('/register/organization/post-registration'):
        case test('/register/confirm'):
        case test('/reports'):
        case test('/cards'):
        case test('/payments'):
        case test('/affidavits'):
        case test('/pagination'):
        case test('/settings'):
        case test('/settings/organization'):
        case test('/notice/:id'):
        case test('/publish/:id'):
        case test('/notice/:id/invoice/create'):
        case test('/publish/:id/invoice/create'):
        case test('/error/:code'):
        case test('/cards/invoices/:id/pay'):
        case test('/subscriptions'):
        case test('/register/individual'):
        case test('/add-organization'):
        case test('/stripe-connect'):
        case test(PUBLISHER_REGISTRATION_ENTRYPOINT): {
            yield* restrict();
            break;
        }
        case test('/notices'): {
            const hasPublicNoticesActive = yield* appSagaSelect(selectHasPublicNoticesActive);
            if (!hasPublicNoticesActive) {
                yield put(replace(`/`));
            }
            else {
                yield* restrict();
            }
            break;
        }
        case test('/obituaries'): {
            const hasObituariesActive = yield* appSagaSelect(selectHasObituariesActive);
            if (hasObituariesActive) {
                yield* restrict();
            }
            else {
                yield put(replace(`/`));
            }
            break;
        }
        case test('/classifieds'): {
            const hasClassifiedsActive = yield appSagaSelect(selectHasClassifiedsActive);
            if (hasClassifiedsActive) {
                yield call(restrict);
            }
            else {
                yield put(replace(`/`));
            }
            break;
        }
        // internal restricted routes (@column.us only)
        case test('/madlib'):
        case test('/impersonate'): {
            yield call(restrictInternal);
            break;
        }
        default: {
            console.warn('Unhandled pathname', { pathname });
            const landingPage = yield call(getLandingPage);
            yield put(replace(landingPage));
            break;
        }
    }
}
export default function* root() {
    yield takeLatest(LOCATION_CHANGE, redirect);
}
