var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { logAndCaptureException } from 'utils';
import { exists } from 'lib/types';
import ToastActions from 'redux/toast';
import { canCancelNoticeWithoutSupport } from 'lib/notice/helpers';
import { Rocket, SmilingFaceWithSmilingEyes } from 'emojis';
import { TextField } from 'lib/components/TextField';
import { Form } from 'lib/components/Form';
import api from 'api';
import { getInvoiceAmountsBreakdown } from 'lib/pricing';
import { isInvoiceInitiatedOrPaid, isPaidDirectToPublisher } from 'lib/utils/invoices';
import { CheckCircleIcon, CurrencyDollarIcon } from '@heroicons/react/24/outline';
import { ELAVON } from 'lib/constants';
import { Modal } from 'lib/components/Modal';
import { useAppDispatch } from 'redux/hooks';
import { ColumnService } from 'lib/services/directory';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
export default function CancelNoticeModal({ setOpen, notice, invoice, isPublisher, newspaper, gateway, setCancelSuccessModalConfig, isInvoicedOutsideColumn }) {
    var _a, _b;
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const [cancellationReason, setCancellationReason] = useState(isPublisher ? notice.data().noticeCancellationReason || '' : '');
    // The portion of the refund amount that comes from the publisher
    const [refundPublisherAmount, setRefundPublisherAmount] = useState('0.00');
    // The maximum amount a publisher can return to the advertiser
    const [maxRefundPublisherAmount, setMaxRefundPublisherAmount] = useState('0.00');
    // The portion of the refund amount that comes from Column
    const [refundFeeAmount, setRefundFeeAmount] = useState('0.00');
    // The total amount that will be returned to the advertiser
    const [refundAmount, setRefundAmount] = useState('0.00');
    const [error, setError] = useState('');
    const partialRefundDisabled = gateway === ELAVON;
    const { value: canCancelNoticeResponse } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            const { response, error } = yield canCancelNoticeWithoutSupport(notice, isPublisher);
            if (error) {
                logAndCaptureException(ColumnService.WEB_PLACEMENT, error, 'Failed to check if user can cancel notice', { noticeId: notice.id });
            }
            return response;
        }),
        initialData: undefined,
        dependencies: [notice.id, isPublisher]
    });
    const canAdvertiserCancel = !!(canCancelNoticeResponse === null || canCancelNoticeResponse === void 0 ? void 0 : canCancelNoticeResponse.canAdvertiserCancel);
    const canPublisherCancel = !!(canCancelNoticeResponse === null || canCancelNoticeResponse === void 0 ? void 0 : canCancelNoticeResponse.canPublisherCancel);
    const canAdvertiserRequestToCancel = !!(canCancelNoticeResponse === null || canCancelNoticeResponse === void 0 ? void 0 : canCancelNoticeResponse.canAdvertiserRequestToCancel);
    const invoicePaidDirectToPublisher = !!invoice && isPaidDirectToPublisher(invoice);
    const paymentMethodIsCheck = ((_a = invoice === null || invoice === void 0 ? void 0 : invoice.data()) === null || _a === void 0 ? void 0 : _a.paymentMethod) === 'check';
    const paymentMethodIsACH = ((_b = invoice === null || invoice === void 0 ? void 0 : invoice.data()) === null || _b === void 0 ? void 0 : _b.paymentMethod) === 'ach';
    const invoiceInitiatedOrPaid = exists(invoice)
        ? isInvoiceInitiatedOrPaid(invoice)
        : false;
    const isRefund = !isInvoicedOutsideColumn &&
        !invoicePaidDirectToPublisher &&
        isPublisher &&
        invoiceInitiatedOrPaid;
    useEffect(() => {
        const getRefund = () => __awaiter(this, void 0, void 0, function* () {
            var _a;
            if (!exists(invoice)) {
                return;
            }
            const { totalInCents, publisherAmountInCents, columnAmountInCents } = getInvoiceAmountsBreakdown(invoice);
            const refundAmountTotal = ((_a = invoice.data().amount_paid) !== null && _a !== void 0 ? _a : totalInCents) / 100;
            setRefundAmount(refundAmountTotal.toFixed(2));
            setRefundFeeAmount((columnAmountInCents / 100).toFixed(2));
            const publisherTotalInDollars = publisherAmountInCents / 100;
            setRefundPublisherAmount(publisherTotalInDollars.toFixed(2));
            const maxPublisherAmount = Math.min(refundAmountTotal, publisherTotalInDollars);
            setMaxRefundPublisherAmount(maxPublisherAmount.toFixed(2));
        });
        void getRefund();
    }, []);
    const handleSubtotalChange = (newRefundSubtotal) => {
        setRefundPublisherAmount(newRefundSubtotal);
        const newRefundTotal = (parseFloat(newRefundSubtotal) + parseFloat(refundFeeAmount)).toFixed(2);
        setRefundAmount(newRefundTotal);
    };
    const handleCancel = () => __awaiter(this, void 0, void 0, function* () {
        if (!error) {
            setLoading(true);
            const { error } = yield api.safePostWithParams(`notices/${notice.id}/cancel`, {
                cancellationReason,
                isRefund: false
            });
            if (error) {
                logAndCaptureException(ColumnService.WEB_PLACEMENT, error, 'Failed to cancel notice', {
                    noticeId: notice.id
                });
                setError('An error occurred while cancelling this notice. If this persists, please contact support.');
            }
            setLoading(false);
            if (isPublisher) {
                dispatch(ToastActions.toastSuccess({
                    headerText: 'Success',
                    bodyText: `You've successfully cancelled this notice.`
                }));
            }
            else {
                setCancelSuccessModalConfig({
                    header: (_jsxs("span", { children: ["Notice cancelled ", _jsx(SmilingFaceWithSmilingEyes, {})] })),
                    body: (_jsxs("p", { children: [newspaper.data().name, " has been notified of this cancellation. You can find this notice in your archived tab."] }))
                });
            }
            setOpen(false);
        }
    });
    const handleRequestToCancel = () => __awaiter(this, void 0, void 0, function* () {
        if (error) {
            return;
        }
        setLoading(true);
        try {
            yield notice.ref.update({
                noticeCancellationReason: cancellationReason
            });
            yield api.post(`notices/request-cancellation`, {
                id: notice.id,
                cancellationReason
            });
            setCancelSuccessModalConfig({
                header: (_jsxs("span", { children: ["Notice cancellation request sent ", _jsx(Rocket, {})] })),
                body: (_jsxs("p", { children: [newspaper.data().name, " has received your request to cancel", ' ', _jsx("span", Object.assign({ className: "font-bold" }, { children: notice.data().referenceId })), ".", ' ', _jsx("span", Object.assign({ className: "font-bold" }, { children: "Your notice will continue to run as scheduled" })), ' ', "until your request is reviewed and cancelled."] }))
            });
        }
        catch (error) {
            logAndCaptureException(ColumnService.WEB_PLACEMENT, error, 'Could not request to cancel notice', {
                noticeId: notice.id
            });
        }
        finally {
            setLoading(false);
        }
        setOpen(false);
    });
    const handleRefund = () => __awaiter(this, void 0, void 0, function* () {
        if (!error) {
            setLoading(true);
            const { error } = yield api.safePostWithParams(`notices/${notice.id}/cancel`, {
                cancellationReason,
                isRefund
            });
            if (error) {
                setError('An error occurred while cancelling this notice. If this persists, please contact support.');
                logAndCaptureException(ColumnService.WEB_PLACEMENT, error, 'Failed to cancel notice', {
                    noticeId: notice.id
                });
                setLoading(false);
                return;
            }
            const refund = yield api.post(`payments/${notice.id}/refund`, {
                newspaperId: newspaper.id,
                refundReason: cancellationReason,
                refundAmount,
                isInvoiceCancellation: true
            });
            if (refund.error) {
                setError('An error occurred while refunding this notice. If this persists, please contact support.');
                logAndCaptureException(ColumnService.PAYMENTS, refund.error, 'Failed to refund notice', {
                    noticeId: notice.id
                });
                setLoading(false);
                return;
            }
            setLoading(false);
            dispatch(ToastActions.toastSuccess({
                headerText: 'Success',
                bodyText: `You've successfully cancelled this notice, and the customer will receive a refund.`
            }));
            setOpen(false);
        }
    });
    const handleContactSupport = () => window.open(`mailto:help@column.us?subject=Request to cancel Notice #${notice.id}`);
    // Determine notices that are placed as IOC but did not created the invoice at the time of cancellation
    const cancelIOCNotice = exists(invoice) && isInvoicedOutsideColumn;
    const [bodyText, buttonText, handleClick, destructive] = canPublisherCancel
        ? invoicePaidDirectToPublisher
            ? [
                'If the advertiser paid directly to the newspaper for this notice, please issue a refund. Column will notify the advertiser that their notice has been cancelled.',
                'Cancel',
                handleCancel,
                true
            ]
            : cancelIOCNotice
                ? [
                    'If the advertiser already paid the invoice for this notice, please issue a refund. Column will notify the advertiser that their notice has been cancelled.',
                    'Cancel',
                    handleCancel,
                    true
                ]
                : invoiceInitiatedOrPaid
                    ? paymentMethodIsACH
                        ? [
                            'This notice was paid via ACH. Please confirm your intent to cancel and refund this notice below, and Column Support will be notified to manually process the ACH refund.',
                            'Cancel and Request Refund',
                            handleRefund,
                            true
                        ]
                        : [
                            paymentMethodIsCheck
                                ? 'This notice was paid via check. Column will issue a refund via check to the advertiser within 30 days.'
                                : 'The advertiser has already paid for this notice. Please cancel and refund below, and the advertiser will be automatically notified.',
                            'Cancel and Refund',
                            handleRefund,
                            true
                        ]
                    : [
                        'Cancelling this notice will notify the advertiser, and void any open invoices.',
                        'Cancel Notice',
                        handleCancel,
                        true
                    ]
        : canAdvertiserCancel
            ? [
                'Cancelling this notice will notify the publisher, and the notice will be moved to your archived tab.',
                'Cancel Notice',
                handleCancel,
                true
            ]
            : canAdvertiserRequestToCancel
                ? [
                    'Deadline has passed for this notice, or you have already paid the invoice. If you would like to cancel, please click “Request to Cancel” below. The publisher will receive your request via email.',
                    'Request to Cancel',
                    handleRequestToCancel,
                    true
                ]
                : [
                    'To request to cancel this notice, please reach out to customer support below.',
                    'Contact Support',
                    handleContactSupport,
                    false
                ];
    return (_jsx(Modal, Object.assign({ id: "cancel-notice-modal", onClose: () => setOpen(false), title: `Cancel Notice${isRefund || cancelIOCNotice ? ' and Refund?' : ''}`, primaryAction: {
            buttonText,
            id: 'confirm-cancel-notice',
            formId: 'cancel-notice-form',
            type: 'submit',
            destructive,
            loading
        }, secondaryActions: [
            {
                buttonText: 'Back',
                type: 'button'
            }
        ] }, { children: _jsxs(_Fragment, { children: [_jsx("p", { children: bodyText }), _jsxs(Form, Object.assign({ id: "cancel-notice-form", onSubmit: handleClick }, { children: [_jsxs("div", { children: [isRefund && (_jsxs("div", { children: [_jsxs("div", Object.assign({ className: "flex flex-row justify-between" }, { children: [_jsx("div", Object.assign({ className: "pr-6 w-1/2" }, { children: _jsx(TextField, { id: "refund-subtotal-amount", type: "currency", labelText: "Refund amount *", placeholder: "0.00", value: refundPublisherAmount, max: maxRefundPublisherAmount, onChange: handleSubtotalChange, disabled: partialRefundDisabled, prefix: _jsx(CurrencyDollarIcon, { className: "w-6 h-6 text-gray-700" }), validationMessages: {
                                                            rangeOverflow: 'You cannot refund the advertiser for more than the subtotal of this notice.'
                                                        } }) })), _jsx("div", Object.assign({ className: "w-1/2" }, { children: _jsx(TextField, { id: "refund-fee-amount", type: "currency", labelText: "Fee amount *", placeholder: "0.00", value: refundFeeAmount, onChange: setRefundAmount, disabled: true, prefix: _jsx(CurrencyDollarIcon, { className: "w-6 h-6 text-grey-300" }) }) }))] })), _jsxs("div", Object.assign({ className: "flex flex-row items-center text-sm rounded-md align-middle min-h-11 w-full space-x-2 py-1.5 pr-3 mt-3 bg-column-green-50" }, { children: [_jsx(CheckCircleIcon, { className: "text-column-green-500 h-8 w-8 pl-3" }), _jsxs("div", Object.assign({ className: "text-column-green-500" }, { children: ["The advertiser will receive a total refund of $", refundAmount, "."] }))] }))] })), (canPublisherCancel || canAdvertiserRequestToCancel) && (_jsx("div", Object.assign({ className: "w-full mt-3" }, { children: _jsx(TextField, { id: "cancel-notice-reason", labelText: "Reason for cancellation", placeholder: "Reason for cancellation", value: cancellationReason, onChange: setCancellationReason, required: true, validationMessages: {
                                            valueMissing: 'Please specify a cancellation reason.'
                                        } }) })))] }), error && (_jsx("div", Object.assign({ className: "justify-start items-start text-red-600 text-sm font-normal pt-2" }, { children: error })))] }))] }) })));
}
