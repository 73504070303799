var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import api from 'api';
/**
 * Typed API POST calls.
 * @deprecated
 * use api.safePost() instead
 */
export const apiPost = (path, req) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield api.post(path, req);
    return res;
});
/**
 * Typed API POST calls for endpoints with dynamic params.
 *
 * @deprecated
 * use api.safePostWithParams() instead
 */
export const apiPostWithParams = (path, req) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield apiPost(path, req);
    return res;
});
/**
 * Typed API GET calls.
 * TODO: This does not work with paths that have '/foo/:param' dynamic parts.
 */
export const apiGet = (path, req) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield api.get(path, req);
    return res;
});
