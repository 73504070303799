import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CheckCircleIcon, MinusCircleIcon } from '@heroicons/react/24/outline';
import { isNoticeFilingType } from 'lib/types/filingType';
import { Badge } from 'lib/components/Badge';
export default function FilingTypeTableRow({ activeOrganization, templateNameCache, rateNameCache, filingType }) {
    var _a, _b, _c;
    const isNoticeType = isNoticeFilingType(filingType);
    const rateId = (_a = filingType.rate) === null || _a === void 0 ? void 0 : _a.id;
    const rateDescription = rateId && rateNameCache ? rateNameCache[rateId] : 'Default rate';
    const templateLabel = ((_b = filingType.template) === null || _b === void 0 ? void 0 : _b.id) && templateNameCache
        ? templateNameCache[(_c = filingType.template) === null || _c === void 0 ? void 0 : _c.id]
        : 'Default template';
    const hasNoticeTemplate = isNoticeType && !!(filingType.madlib || filingType.typeform);
    return (_jsxs(_Fragment, { children: [_jsxs("td", Object.assign({ className: "text-column-gray-500" }, { children: [filingType.label, isNoticeType &&
                        filingType.value === activeOrganization.data().defaultNoticeType && (_jsx("span", Object.assign({ className: "ml-4" }, { children: _jsx(Badge, Object.assign({ status: "success" }, { children: "Default" })) })))] })), _jsx("td", { children: rateDescription }), _jsx("td", { children: templateLabel }), _jsx("td", { children: hasNoticeTemplate ? (_jsx(CheckCircleIcon, { className: "ml-14 text-column-primary-500 w-6 h-6" })) : (_jsx(MinusCircleIcon, { className: "ml-14 text-column-gray-400 w-6 h-6" })) })] }));
}
