import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import ClickableIconWrapper from 'lib/components/TableLayout/ClickableIconWrapper';
import React, { useState } from 'react';
import { cdnIfy } from 'lib/helpers';
import { useGetBlockDimensions } from '../hooks/useGetBlockDimensions';
function IssueAttachmentRow({ issueAttachment, defaultOpen }) {
    const [open, setOpen] = useState(defaultOpen);
    const { attachmentDimensions } = useGetBlockDimensions(issueAttachment);
    const { downloadUrl, storagePath } = issueAttachment.modelData;
    const isPDF = storagePath.endsWith('.pdf');
    return (_jsxs("div", Object.assign({ className: "border-t border-column-grey-25 pt-1" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center justify-between" }, { children: [_jsx("a", Object.assign({ className: "underline cursor-pointer", href: downloadUrl }, { children: issueAttachment.modelData.name })), _jsx(ClickableIconWrapper, { icon: open ? (_jsx(ChevronDownIcon, { className: "w-5 h-5" })) : (_jsx(ChevronRightIcon, { className: "w-5 h-5" })), onClick: () => setOpen(!open), id: "toggle-attachment" })] })), open && (_jsxs(_Fragment, { children: [attachmentDimensions && (_jsx("div", { children: `${attachmentDimensions.width} x ${attachmentDimensions.height} inches` })), isPDF && (_jsx("iframe", { title: "PDF Preview", src: `${cdnIfy(storagePath, {
                            useImgix: true
                        })}#toolbar=0&navpanes=0&scrollbar=0`, frameBorder: "0", height: "100%", width: "100%" })), !isPDF && (_jsx("img", { className: "p-2 border bg-column-gray-200 mt-4 w-full", src: downloadUrl }))] }))] })));
}
export default function BlocksTab({ issueAttachments }) {
    const paginationBlocks = issueAttachments.filter(attachment => attachment.modelData.type === 'pagination');
    const manifestAttachment = issueAttachments.find(attachment => attachment.modelData.type === 'manifest');
    return (_jsxs("div", Object.assign({ className: "px-8 py-5 flex flex-col gap-4 overflow-scroll" }, { children: [_jsxs("div", Object.assign({ className: "font-medium" }, { children: [_jsx("p", Object.assign({ className: "text-xl text-column-gray-500 mb-1" }, { children: "Attachments" })), _jsx("p", Object.assign({ className: "text-sm leading-6 text-column-gray-400" }, { children: "Review the most recent manifest and pagination files for this issue" }))] })), manifestAttachment && (_jsx("div", Object.assign({ className: "flex items-center justify-between" }, { children: _jsx("a", Object.assign({ className: "underline cursor-pointer", href: manifestAttachment.modelData.downloadUrl }, { children: manifestAttachment.modelData.name })) }))), paginationBlocks.map((issueAttachment, idx) => (_jsx(IssueAttachmentRow, { issueAttachment: issueAttachment, defaultOpen: idx === 0 }, issueAttachment.id)))] })));
}
