var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { searchPublisherOrganizations } from '../routes/placeScroll/helpers';
export function useFetchAvailablePublishers({ restrictedSingleState, isUserPublisher, restrictedPublisherIds, product, adjudicationFilters }) {
    var _a, _b, _c, _d, _e;
    const [stateFilter, setStateFilter] = useState(restrictedSingleState);
    // If we're in a state restricted flow, we only show publishers in that state
    const activeStateFilter = restrictedSingleState || stateFilter;
    let stateOptions = [];
    const fetchData = () => __awaiter(this, void 0, void 0, function* () {
        // NOTE: `searchPublisherOrganizations` handles error logging internally and returns empty lists on a failed request
        const { states, publisherOrganizations } = yield searchPublisherOrganizations({
            isUserPublisher,
            stateFilter: activeStateFilter,
            restrictedPublisherIds,
            product,
            adjudicationFilters
        });
        // Only set the available states on the first load
        if (!stateOptions.length) {
            stateOptions = states.sort((a, b) => a - b);
        }
        const sortedPublisherOptions = publisherOrganizations.sort((a, b) => a.name.replace('The ', '').localeCompare(b.name.replace('The ', '')));
        return { stateOptions, sortedPublisherOptions };
    });
    const { value, isLoading } = useAsyncEffect({
        fetchData,
        dependencies: [
            isUserPublisher,
            activeStateFilter,
            (_a = adjudicationFilters === null || adjudicationFilters === void 0 ? void 0 : adjudicationFilters.stateFilter) === null || _a === void 0 ? void 0 : _a.id,
            (_b = adjudicationFilters === null || adjudicationFilters === void 0 ? void 0 : adjudicationFilters.countyFilter) === null || _b === void 0 ? void 0 : _b.id,
            (_c = adjudicationFilters === null || adjudicationFilters === void 0 ? void 0 : adjudicationFilters.cityFilter) === null || _c === void 0 ? void 0 : _c.id
        ]
    });
    stateOptions = (_d = value === null || value === void 0 ? void 0 : value.stateOptions) !== null && _d !== void 0 ? _d : [];
    const publisherOptions = (_e = value === null || value === void 0 ? void 0 : value.sortedPublisherOptions) !== null && _e !== void 0 ? _e : [];
    return {
        loading: isLoading,
        stateOptions,
        stateFilter,
        setStateFilter,
        publisherOptions
    };
}
