import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Popover } from 'lib/components/Popover';
import { ColumnButton } from 'lib/components/ColumnButton';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { PublisherLocationFilterBadge } from '../PublisherLocationFilterBadge';
import { SelectAdjudicationStateDropdown } from './SelectAdjudicationStateDropdown';
import { SelectAdjudicationCountyDropdown } from './SelectAdjudicationCountyDropdown';
import { SelectAdjudicationCityDropdown } from './SelectAdjudicationCityDropdown';
export function PublisherLocationFilterAdjudicated({ onFilterChange, activeFilters }) {
    const [selectedState, setSelectedState] = useState();
    const [selectedCounty, setSelectedCounty] = useState();
    const [selectedCity, setSelectedCity] = useState();
    const activeFilterCount = getLocationFilterCount(activeFilters);
    function applyFilters(filters) {
        onFilterChange(filters);
    }
    function resetFilters() {
        setSelectedState(undefined);
        setSelectedCounty(undefined);
        setSelectedCity(undefined);
        applyFilters({});
    }
    return (_jsx(Popover, Object.assign({ id: "publisher-location-filter", headerText: "Filter publisher list by", fullWidth: true, activator: _jsx(ColumnButton, { buttonText: _jsxs(_Fragment, { children: ["Filter location", ' ', _jsx(PublisherLocationFilterBadge, { activeFilterCount: activeFilterCount, onClear: resetFilters })] }), fullWidth: true, alignment: "between", endIcon: _jsx(FunnelIcon, { className: "pb-1 pt-0.5 w-5" }), type: "button" }) }, { children: _jsx("div", Object.assign({ className: "p-4 space-y-2" }, { children: _jsx(PublisherLocationFilterFormAdjudicated, { selectedState: selectedState, onSelectionChanged: (dropdown, value) => {
                    switch (dropdown) {
                        case 'state':
                            setSelectedState(value);
                            onFilterChange({
                                stateFilter: value
                            });
                            break;
                        case 'county':
                            setSelectedCounty(value);
                            onFilterChange({
                                stateFilter: selectedState,
                                countyFilter: value
                            });
                            break;
                        case 'city':
                            setSelectedCity(value);
                            onFilterChange({
                                stateFilter: selectedState,
                                countyFilter: selectedCounty,
                                cityFilter: value
                            });
                            break;
                    }
                }, selectedCounty: selectedCounty, selectedCity: selectedCity, resetFilters: resetFilters, applyFilters: applyFilters }) })) })));
}
function PublisherLocationFilterFormAdjudicated({ selectedState, selectedCounty, selectedCity, onSelectionChanged, resetFilters }) {
    const isCityFilterEnabled = useBooleanFlag(LaunchDarklyFlags.ENABLE_ADJUDICATION_AREA_PLACEMENT_FILTER_CITY, false);
    return (_jsxs(_Fragment, { children: [_jsx(SelectAdjudicationStateDropdown, { selectedState: selectedState, onStateSelected: value => onSelectionChanged('state', value) }), _jsx(SelectAdjudicationCountyDropdown, { selectedState: selectedState, selectedCounty: selectedCounty, onCountySelected: value => onSelectionChanged('county', value) }), isCityFilterEnabled && (_jsx(SelectAdjudicationCityDropdown, { selectedState: selectedState, selectedCity: selectedCity, selectedCounty: selectedCounty, onCitySelected: value => onSelectionChanged('city', value) })), _jsx("div", Object.assign({ className: "flex justify-between pt-3" }, { children: _jsx(ColumnButton, { buttonText: "Reset", onClick: resetFilters, type: "button" }) }))] }));
}
function getLocationFilterCount(filters) {
    // NOTE: Extend this calculation if we add more filters
    return [filters.cityFilter, filters.countyFilter, filters.stateFilter].filter(filter => !!filter).length;
}
