import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { isNoticeFilingType } from 'lib/types/filingType';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import { InheritableSettingSelectInput } from '../../InheritableSettingSelectInput';
const DEFAULT_RATE_DESCRIPTION = 'Default - use organization default rate or customer specific rates';
/**
 * Converts a set of rates into a set of options for a select input
 */
const ratesToRateSelectOptions = (rates, includeDefaultOption) => {
    const rateOptions = includeDefaultOption
        ? [
            {
                label: DEFAULT_RATE_DESCRIPTION,
                value: 'default'
            }
        ]
        : [];
    rates.forEach(rate => {
        const label = rate.data().archived
            ? `${rate.data().description} (archived)`
            : rate.data().description;
        rateOptions.push({
            value: rate.id,
            label
        });
    });
    return rateOptions;
};
export default function BillingSettingsCard({ onUpdateNoticeType, activeOrganization, updatedNoticeType, rates }) {
    var _a;
    return (_jsxs(CardGridLayout, Object.assign({ header: {
            title: 'Billing Settings',
            description: 'Decide how to charge customers for this notice type.'
        } }, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(ColumnSelect, { id: "notice-type-rate-select", labelText: "Rate", options: ratesToRateSelectOptions(rates, isNoticeFilingType(updatedNoticeType)), placeholder: DEFAULT_RATE_DESCRIPTION, value: (_a = updatedNoticeType.rate) === null || _a === void 0 ? void 0 : _a.id, onChange: newValue => {
                        var _a;
                        const newNoticeType = Object.assign({}, updatedNoticeType);
                        if (newValue !== 'default') {
                            newNoticeType.rate = (_a = rates.find(r => r.id === newValue)) === null || _a === void 0 ? void 0 : _a.ref;
                        }
                        else {
                            delete newNoticeType.rate;
                        }
                        onUpdateNoticeType(newNoticeType);
                    }, allowUndefined: true }) })), isNoticeFilingType(updatedNoticeType) && (_jsxs(_Fragment, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Require upfront payment?", description: "If enabled, notices of this type will require upfront payment unless turned off for a customer or a specific notice.", value: updatedNoticeType.upFrontPayment || false, onChange: newValue => onUpdateNoticeType(Object.assign(Object.assign({}, updatedNoticeType), { upFrontPayment: newValue })) }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Automatically generate invoices?", description: "If enabled, invoices will be created automatically upon notice submission.", value: updatedNoticeType.autoInvoice || false, onChange: newValue => onUpdateNoticeType(Object.assign(Object.assign({}, updatedNoticeType), { autoInvoice: newValue })) }) })), activeOrganization.data().allowInvoiceOutsideColumn && (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(InheritableSettingSelectInput, { id: "invoice-outside-column", labelText: "Enable invoicing outside Column?", optionText: {
                                yesLabel: `Yes — publisher will bill and collect payment outside of Column`,
                                noLabel: `No — create invoices and collect payment inside Column`,
                                defaultLabel: 'Default — use default newspaper or customer settings'
                            }, value: updatedNoticeType.invoiceOutsideColumn, onChange: newValue => {
                                const newNoticeType = Object.assign({}, updatedNoticeType);
                                if (newValue === undefined) {
                                    delete newNoticeType.invoiceOutsideColumn;
                                }
                                else {
                                    newNoticeType.invoiceOutsideColumn = newValue;
                                }
                                onUpdateNoticeType(newNoticeType);
                            } }) })))] }))] })));
}
