import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { TextField } from 'lib/components/TextField';
import { useAppSelector } from 'redux/hooks';
import { selectIsPublisher } from 'redux/auth';
import { GridInput } from 'lib/components/Card/Grid';
import MultiStepHeader from '../components/MultiStepHeader';
export const MINIMUM_ORDER = {
    contactEmail: '',
    firstName: '',
    lastName: '',
    phone: ''
};
function PersonalDetail({ inputData, setInputData }) {
    const { firstName, lastName, contactEmail, phone } = Object.assign(Object.assign({}, MINIMUM_ORDER), inputData);
    const isPublisher = useAppSelector(selectIsPublisher);
    return (_jsxs(_Fragment, { children: [_jsx(MultiStepHeader, { title: isPublisher ? 'Customer Details' : 'Contact Information', description: isPublisher
                    ? 'Please provide contact information for your customer.'
                    : 'We use this to reach you about any changes needed before publication.' }), _jsxs("div", Object.assign({ className: "grid md:grid-cols-2 gap-3 mb-8" }, { children: [_jsx(GridInput, { children: _jsx(TextField, { id: "first-name", value: firstName, type: "text", onChange: value => setInputData(Object.assign(Object.assign({}, inputData), { firstName: value })), onBlur: () => setInputData(Object.assign(Object.assign({}, inputData), { firstName: firstName.trim() })), labelText: "First name", placeholder: "Jane", required: true }) }), _jsx(GridInput, { children: _jsx(TextField, { id: "last-name", value: lastName, type: "text", onChange: value => setInputData(Object.assign(Object.assign({}, inputData), { lastName: value })), placeholder: "Doe", onBlur: () => setInputData(Object.assign(Object.assign({}, inputData), { lastName: lastName.trim() })), labelText: "Last name", required: true }) }), _jsx(GridInput, { children: _jsx(TextField, { id: "email", value: contactEmail, type: "email", onChange: value => setInputData(Object.assign(Object.assign({}, inputData), { contactEmail: value })), labelText: "Email address", placeholder: isPublisher ? 'yourclient@gmail.com' : 'youremail@gmail.com', required: true }) }), _jsx(GridInput, { children: _jsx(TextField, { id: "phone", value: phone, type: "tel", onChange: value => setInputData(Object.assign(Object.assign({}, inputData), { phone: value })), labelText: "Phone number", placeholder: "(000) 000-0000", required: true }) })] }))] }));
}
export default PersonalDetail;
