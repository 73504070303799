import { isPublicationLineItem } from '.';
import { RateType } from '../enums';
import { getLastNonFeeAndNonDiscountLineItemIndex } from './additionalFees';
/**
 * Distribute the fee proportionally to the amount of each item.
 * @param lineItems - Line items without fees
 * @param feeToDistribute - The fee
 * @returns Line items with fee distributed
 */
export const distributeFeeProportionally = (lineItems, feeToDistribute) => {
    var _a, _b;
    const indexOfLastItem = lineItems.length - 1;
    let remainingFeeToDistribute = feeToDistribute;
    const subtotal = lineItems
        .map(item => item.amount)
        .reduce((accumulator, currentValue) => accumulator + currentValue);
    const lineItemsWithDistributedFee = [];
    // eslint-disable-next-line guard-for-in
    for (const itemIndex in lineItems) {
        const lineItem = lineItems[Number(itemIndex)];
        const lineItemFee = Math.round((lineItem.amount / subtotal) * feeToDistribute);
        let amount = lineItem.amount + lineItemFee;
        remainingFeeToDistribute -= lineItemFee;
        // Add remaining fee amount (due to rounding) to the last line item
        if (Number(itemIndex) === indexOfLastItem && remainingFeeToDistribute) {
            amount += remainingFeeToDistribute;
        }
        const unitPricing = {
            price: amount,
            quantity: (_b = (_a = lineItem.unitPricing) === null || _a === void 0 ? void 0 : _a.quantity) !== null && _b !== void 0 ? _b : 1
        };
        lineItemsWithDistributedFee.push(Object.assign(Object.assign({}, lineItem), { amount, unitPricing }));
    }
    return lineItemsWithDistributedFee;
};
export const getDistributedLineItems = (lineItems, distributeEnoticeFee, rate, { feeToDistribute, expectedSubtotal }) => {
    const { finalLineItem = false, evenly = false, proportionally = false } = distributeEnoticeFee;
    const { finalLineItemPricing = false, rateType } = rate;
    const itemsToDistributeOver = lineItems.filter(l => isPublicationLineItem(l));
    if (finalLineItem &&
        finalLineItemPricing &&
        rateType === RateType.flat.value) {
        let additionalFeeTotal = 0;
        lineItems.forEach(item => {
            if (!isPublicationLineItem(item)) {
                additionalFeeTotal += item.amount;
            }
        });
        return lineItems.map((item, i) => {
            var _a, _b;
            const amount = i === 0
                ? expectedSubtotal - additionalFeeTotal
                : !isPublicationLineItem(item)
                    ? item.amount
                    : 0;
            const unitPricing = {
                price: amount,
                quantity: (_b = (_a = item.unitPricing) === null || _a === void 0 ? void 0 : _a.quantity) !== null && _b !== void 0 ? _b : 1
            };
            return Object.assign(Object.assign({}, item), { amount, unitPricing });
        });
    }
    if (finalLineItem &&
        !finalLineItemPricing &&
        rateType === RateType.flat.value) {
        const finalLineItemAmount = feeToDistribute + itemsToDistributeOver[0].amount;
        return lineItems.map((item, i) => {
            var _a, _b;
            const amount = i === itemsToDistributeOver.length - 1
                ? finalLineItemAmount
                : !isPublicationLineItem(item)
                    ? item.amount
                    : 0;
            const unitPricing = {
                price: amount,
                quantity: (_b = (_a = item.unitPricing) === null || _a === void 0 ? void 0 : _a.quantity) !== null && _b !== void 0 ? _b : 1
            };
            return Object.assign(Object.assign({}, item), { amount, unitPricing });
        });
    }
    if (finalLineItem &&
        !finalLineItemPricing &&
        rateType !== RateType.flat.value) {
        const finalLineItemAmount = feeToDistribute + itemsToDistributeOver[0].amount;
        return lineItems.map((item, i) => {
            var _a, _b;
            const amount = i === itemsToDistributeOver.length - 1
                ? finalLineItemAmount
                : item.amount;
            const unitPricing = {
                price: amount,
                quantity: (_b = (_a = item.unitPricing) === null || _a === void 0 ? void 0 : _a.quantity) !== null && _b !== void 0 ? _b : 1
            };
            return Object.assign(Object.assign({}, item), { amount, unitPricing });
        });
    }
    if ((evenly && (rateType === RateType.flat.value || finalLineItemPricing)) ||
        (finalLineItem && finalLineItemPricing)) {
        return lineItems.map(item => {
            var _a, _b;
            const amount = item.amount !== 0 && isPublicationLineItem(item)
                ? item.amount + feeToDistribute
                : item.amount;
            const unitPricing = {
                price: amount,
                quantity: (_b = (_a = item.unitPricing) === null || _a === void 0 ? void 0 : _a.quantity) !== null && _b !== void 0 ? _b : 1
            };
            return Object.assign(Object.assign({}, item), { amount, unitPricing });
        });
    }
    // Distribute the fee proportionally to the amount of each item
    if (proportionally) {
        return distributeFeeProportionally(lineItems, feeToDistribute);
    }
    const totalToDistributeOver = itemsToDistributeOver.reduce((a, b) => a + b.amount, 0);
    const distributedFee = feeToDistribute / itemsToDistributeOver.length;
    let finalLineItemAmount;
    // if there is only one value to distribute over, put the whole fee on it
    if (itemsToDistributeOver.length === 1) {
        finalLineItemAmount = totalToDistributeOver + distributedFee;
    }
    // otherwise we need to be careful with rounding
    else {
        const initialDistributed = itemsToDistributeOver
            .slice(0, -1)
            .reduce((a, b) => a + b.amount + Math.round(distributedFee), 0);
        finalLineItemAmount =
            totalToDistributeOver + feeToDistribute - initialDistributed;
    }
    const lastNonFeeNonDiscountLineItemIndex = getLastNonFeeAndNonDiscountLineItemIndex(lineItems);
    return lineItems.map((item, i) => {
        var _a, _b;
        let amount;
        if (!isPublicationLineItem(item)) {
            // don't include the fee on custom items
            amount = item.amount;
        }
        else if (i === lastNonFeeNonDiscountLineItemIndex) {
            // handle rounding on the final non-fee item
            amount = finalLineItemAmount;
        }
        else {
            // otherwise include the default spread
            amount = item.amount + Math.round(distributedFee);
        }
        const unitPricing = {
            price: amount,
            quantity: (_b = (_a = item.unitPricing) === null || _a === void 0 ? void 0 : _a.quantity) !== null && _b !== void 0 ? _b : 1
        };
        return Object.assign(Object.assign({}, item), { amount, unitPricing });
    });
};
