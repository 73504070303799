var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { useState } from 'react';
import { PlacementFlowStep } from '../placementFlowStep';
export const useGetRequireNewInvoice = ({ orderModel, newspaperOrdersFormData, isEditing, totalInCents, step }) => {
    const [requireNewInvoice, setRequireNewInvoice] = useState(false);
    const { isLoading } = useAsyncEffect({
        fetchData: () => __awaiter(void 0, void 0, void 0, function* () {
            if (step !== PlacementFlowStep.Summary) {
                setRequireNewInvoice(false);
                return;
            }
            if (!isEditing) {
                setRequireNewInvoice(true);
                return;
            }
            const pricing = yield orderModel.getConsolidatedPricing({
                distributeFee: true,
                version: orderModel.modelData.activeVersion
            });
            // Price is different
            if (pricing.totalInCents !== totalInCents) {
                setRequireNewInvoice(true);
                return;
            }
            const newspaperOrders = yield orderModel.getNewspaperOrders();
            // Number of newspaper orders is not the same
            if (newspaperOrders.length !== newspaperOrdersFormData.length) {
                setRequireNewInvoice(true);
                return;
            }
            for (const newspaperOrder of newspaperOrders) {
                if (!newspaperOrdersFormData.find(newsaperOrderFromData => {
                    var _a, _b, _c, _d;
                    const samePublishingDates = ((_a = newsaperOrderFromData.publishingDates) === null || _a === void 0 ? void 0 : _a.length) ===
                        newspaperOrder.modelData.publishingDates.length &&
                        ((_b = newsaperOrderFromData.publishingDates) === null || _b === void 0 ? void 0 : _b.every(pd => newspaperOrder.modelData.publishingDates.includes(pd)));
                    const samePublisher = ((_c = newsaperOrderFromData.newspaper) === null || _c === void 0 ? void 0 : _c.id) ===
                        ((_d = newspaperOrder.modelData.newspaper) === null || _d === void 0 ? void 0 : _d.id);
                    const samePublishingMedium = newsaperOrderFromData.publishingMedium ===
                        newspaperOrder.modelData.publishingMedium;
                    if (samePublishingDates && samePublisher && samePublishingMedium) {
                        return true;
                    }
                })) {
                    setRequireNewInvoice(true);
                    return;
                }
            }
            setRequireNewInvoice(false);
        }),
        dependencies: [step, totalInCents]
    });
    return {
        isLoading,
        requireNewInvoice
    };
};
