import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useState } from 'react';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { Alert } from 'lib/components/Alert';
import { isEmpty } from 'lodash';
import { FilingTypeVisibility, Product } from 'lib/enums';
import { logAndCaptureCriticalError } from 'utils';
import { ColumnService } from 'lib/services/directory';
import { NotFoundError } from 'lib/errors/ColumnErrors';
import SelectSchedule from './SelectSchedule';
import { NewspapersContext } from '../../contexts/NewspapersContext';
import Placemat from '../../Placemat';
const isValidFilingType = (filingType, isPublisher) => {
    // Filing type must have a rate
    if (!filingType.modelData.rate) {
        return false;
    }
    // Filing type must not be disabled
    if (filingType.modelData.visibility === FilingTypeVisibility.disabled.value) {
        return false;
    }
    if (!isPublisher &&
        filingType.modelData.visibility ===
            FilingTypeVisibility.publisher_only.value) {
        return false;
    }
    return true;
};
export const getValidFilingTypes = (filingTypes, isPublisher) => filingTypes === null || filingTypes === void 0 ? void 0 : filingTypes.filter(filingType => isValidFilingType(filingType, isPublisher));
function PerPublisherSettings({ product, newspaperOrderWithPublishingSettings, isPublisher, onNewspaperOrderChange, onFilingTypeChange, validateFilingTypeSelection, isInitialPlacementFlow, disableEdits }) {
    const { newspaperOrder, publishingSettings } = newspaperOrderWithPublishingSettings;
    const newspapers = useContext(NewspapersContext);
    const newspaperSnap = newspapers.find(snap => { var _a; return snap.id === ((_a = newspaperOrder.newspaper) === null || _a === void 0 ? void 0 : _a.id); });
    const [userError, setUserError] = useState('');
    const { filingTypes, publishingSetting } = publishingSettings;
    const selectedFilingType = filingTypes.find(filingType => { var _a; return filingType.ref.id === ((_a = newspaperOrder.filingType) === null || _a === void 0 ? void 0 : _a.id); });
    const handleFilingTypeChange = (label) => {
        const newFilingType = filingTypes === null || filingTypes === void 0 ? void 0 : filingTypes.find(filingType => filingType.modelData.label === label);
        if (!newFilingType) {
            const err = new NotFoundError('Filing type not found in publishingSettings from label');
            logAndCaptureCriticalError(ColumnService.OBITS, err, 'failed to handleFilingTypeChange', {
                publishingSettingId: publishingSetting.id,
                label
            });
            setUserError('Failed to find filing type.  Please refresh the page.');
            return;
        }
        // We need to set the layout early here so our newspaperOrder is in a complete state to save to db
        const layout = newFilingType.supportedLayouts[0];
        const newspaperOrderChanges = Object.assign(Object.assign({}, newspaperOrder), { filingType: newFilingType.ref, layout });
        // TODO: When we complete APP-3536 this should be removed and the change should always be included
        if (newFilingType.modelData.template) {
            newspaperOrderChanges.adTemplate = newFilingType.modelData.template;
        }
        onNewspaperOrderChange(newspaperOrderChanges);
        // TODO: Change this when we move to a single filing type selection for an order
        onFilingTypeChange(label);
    };
    if (!newspaperSnap) {
        return (_jsx(Alert, { id: "missing-newspaper", title: "Error", description: "Missing newspaper" }));
    }
    if (!publishingSetting) {
        return (_jsx(Alert, { id: "missing-publishing-setting", title: "Error", description: "Could not retrieve publishing setting" }));
    }
    const validFilingTypes = getValidFilingTypes(filingTypes, isPublisher);
    if (!validFilingTypes || isEmpty(validFilingTypes)) {
        const message = 'No valid filing types found for newspaper order in placement/edit flow';
        logAndCaptureCriticalError(ColumnService.OBITS, new NotFoundError(message), message, {
            publishingSettingId: publishingSetting.id
        });
        return (_jsx(Alert, { id: "missing-filing-types", title: "Error", description: `${newspaperSnap.data().name} does not have any valid ${product} categories enabled for ${newspaperOrder.publishingMedium}. Please select a different newspaper or medium.` }));
    }
    /**
     * Category is selected at an earlier step for classifieds
     * Also, we should not allow the filing type on an order to change post submission
     * as it is an immutable part of an order (see https://columnpbc.slack.com/archives/C063V00UK6W/p1720703737591309)
     */
    const showFilingTypeSelector = isInitialPlacementFlow && product === Product.Obituary;
    return (_jsx(Placemat, { children: _jsxs("div", Object.assign({ className: "flex flex-col gap-y-3" }, { children: [userError && (_jsx(Alert, { id: "user-error", title: "Error", description: userError })), _jsx(SelectSchedule, { newspaperOrder: newspaperOrder, newspaper: newspaperSnap, onNewspaperOrderChange: onNewspaperOrderChange, publishingSetting: publishingSetting, product: product, disableEdits: disableEdits }), showFilingTypeSelector && (_jsx(ColumnSelect, { id: "publication-category", labelText: "Publication category", value: selectedFilingType === null || selectedFilingType === void 0 ? void 0 : selectedFilingType.modelData.label, options: validFilingTypes.map(filingType => ({
                        label: filingType.modelData.label,
                        value: filingType.modelData.label
                    })), onChange: handleFilingTypeChange, allowUndefined: true, required: true, checkDependentValidations: validateFilingTypeSelection, disabled: disableEdits }))] })) }));
}
export default PerPublisherSettings;
