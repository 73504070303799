import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { exists } from 'lib/types';
import { RateType } from 'lib/enums';
import { useAppSelector } from 'redux/hooks';
import { selectActiveOrganization } from 'redux/auth';
import { isRateShared } from 'lib/utils/rates';
import { isNoticeRate } from 'lib/types/rates';
import DrawerPropertyRow from '../../../../DrawerPropertyRow';
import { shouldShowThirdRunRate, prettyFormatNumber, isLegacyRate } from '../../ratesTableSettingsUtils';
import { rateSupportsDisplayAdsForPublisher } from '../../rateUpdateForm/rateUpdateFormUtils';
import { hideRatePricingDetailsWhenEditing } from '../../../ratesUtils';
import ImagePriceRow from './ImagePriceRow';
/**
 * Edit core properties on the rate
 */
export default function BaseRatePropertiesTab({ rateSnapshot }) {
    var _a, _b, _c;
    const rate = rateSnapshot.data();
    const { additionalRates, description, rate_0, rateType, runBased } = rate;
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const showPricingDetails = !hideRatePricingDetailsWhenEditing(rate);
    const showRoundOff = rateType === RateType.column_inch.value;
    if (!exists(activeOrganization))
        return null;
    return (_jsxs(_Fragment, { children: [_jsx(DrawerPropertyRow, { label: "Rate name", value: description }), _jsx(DrawerPropertyRow, { label: "How rate is calculated", value: (_a = RateType.by_value(rateType)) === null || _a === void 0 ? void 0 : _a.label }), isNoticeRate(rate) && (_jsx(DrawerPropertyRow, { label: "Ads supported", value: rateSupportsDisplayAdsForPublisher(rateSnapshot.id, activeOrganization, rate)
                    ? 'Text-based ads and ads with images'
                    : 'Text-based ads only' })), showPricingDetails && (_jsxs(_Fragment, { children: [_jsx(DrawerPropertyRow, { label: "Charge per run type", value: runBased ? 'Different rate' : 'Same rate' }), _jsx(DrawerPropertyRow, { label: "Minimum charge (all runs)", value: `$${prettyFormatNumber(rate.minimum / 100)}` }), _jsx(DrawerPropertyRow, { label: "Minimum charge (per run)", value: `$${prettyFormatNumber((rate.runMinimumInCents || 0) / 100)}` }), isLegacyRate(rate) || runBased ? (_jsxs(_Fragment, { children: [_jsx(DrawerPropertyRow, { label: "1st run rate", value: `$${prettyFormatNumber(rate_0 / 100)}` }), _jsx(DrawerPropertyRow, { label: "2nd run rate", value: `$${prettyFormatNumber(rate.rate_1 / 100)}` }), shouldShowThirdRunRate(rate) && (_jsx(DrawerPropertyRow, { label: "3rd run rate", value: `$${prettyFormatNumber(rate.rate_2 / 100)}` }))] })) : (_jsx(DrawerPropertyRow, { label: "Rate", value: `$${prettyFormatNumber(rate_0 / 100)}` })), additionalRates &&
                        Object.keys(additionalRates)
                            .sort((a, b) => a.localeCompare(b))
                            .map((key, idx) => {
                            const runNumberIdx = key.split('_')[1];
                            const runNumber = parseInt(runNumberIdx, 10) + 1;
                            return (_jsx(DrawerPropertyRow, { label: `${runNumber}th run rate`, value: `$${prettyFormatNumber(additionalRates[key] / 100)}` }, idx));
                        })] })), _jsx(ImagePriceRow, { rate: rate }), showRoundOff && (_jsx(DrawerPropertyRow, { label: "Round off", value: rateSnapshot.data().roundOff
                    ? `${rateSnapshot.data().roundOff} inches`
                    : 0 })), isRateShared(rate) && (_jsx(DrawerPropertyRow, { label: "Shared by", value: `${((_c = (_b = rateSnapshot.data().publisherOrganizations) === null || _b === void 0 ? void 0 : _b.length) !== null && _c !== void 0 ? _c : 0) + 1} organizations` }))] }));
}
