var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { getAffinityXConstantsFromCache, isAffinityXOrderNumber } from 'lib/integrations/affinityx/helpers';
import { getFirebaseContext } from 'utils/firebase';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
import { getValidAffinityXOrderNumber } from 'components/AffinityXSyncPanel/helpers';
import { safeStringify } from 'lib/utils/stringify';
import { logInfo } from 'utils/logger';
export const useFetchAffinityXOrderNumber = (noticeSnap, mostRecentTriggerEvent, affinityCancelEventsQuerySnap, setOrderNumber) => {
    useAsyncEffect({
        fetchData: () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            const { customId } = noticeSnap.data();
            if (!customId) {
                logInfo('Cannot fetch AffinityX order number, customId not generated on notice yet', {
                    noticeId: noticeSnap.id
                });
                return;
            }
            const newspaperRef = noticeSnap.data().newspaper;
            const { response: fetchedAffinityXConstants, error: fetchAXConstantsError } = yield getAffinityXConstantsFromCache(getFirebaseContext(), newspaperRef);
            if (fetchAXConstantsError) {
                logAndCaptureException(ColumnService.AFFINITY_X, fetchAXConstantsError, 'Error fetching AffinityX constants');
                return;
            }
            // TODO: Consider running migration to update the orderNumberGeneration.orderNumPrefix to an empty string for FCC and WICK
            // Both now have newspaper abbreviations as in app caches
            const customIdWithPrefix = isAffinityXOrderNumber(noticeSnap.data().customId)
                ? noticeSnap.data().customId
                : `${fetchedAffinityXConstants.newspaperAbbreviation}${noticeSnap.data().customId}`;
            try {
                const affinityXOrderNumber = getValidAffinityXOrderNumber({
                    customId: customIdWithPrefix,
                    mostRecentTriggerEvent,
                    cancelEvents: (_a = affinityCancelEventsQuerySnap === null || affinityCancelEventsQuerySnap === void 0 ? void 0 : affinityCancelEventsQuerySnap.docs) !== null && _a !== void 0 ? _a : [],
                    /**
                     * Since creating a new order number in AffinityX
                     * may incur additional charges for the publisher,
                     * we don't want to do this automatically. Instead,
                     * we should keep incrementing the order number a
                     * manual action.
                     */
                    incrementOrderNumberIfUsed: false
                });
                setOrderNumber(affinityXOrderNumber);
                return { affinityXOrderNumber };
            }
            catch (err) {
                logAndCaptureException(ColumnService.AFFINITY_X, err, 'Error generating AffinityX order number', {
                    noticeId: noticeSnap.id
                });
            }
        }),
        dependencies: [
            noticeSnap.id,
            mostRecentTriggerEvent === null || mostRecentTriggerEvent === void 0 ? void 0 : mostRecentTriggerEvent.id,
            safeStringify(affinityCancelEventsQuerySnap === null || affinityCancelEventsQuerySnap === void 0 ? void 0 : affinityCancelEventsQuerySnap.docs),
            noticeSnap.data().customId
        ]
    });
};
