var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import sanitizeHtml from 'sanitize-html';
import moment from 'moment-timezone';
import sanitizeFilename from 'sanitize-filename';
import { isPublisher } from './utils/users';
import { getErrorReporter } from './utils/errors';
import { FIREBASE_PROJECT, CLOUDINARY_BUCKET, envs } from './constants';
import { exists } from './types';
import { getDeadlineTimeForPaper, publishingDayEnumValuesFromDeadlines, disableNonPublishingDays, getIsAfterPublishingDeadline } from './utils/deadlines';
import { OrganizationType, OccupationType, InvoiceStatus, NoticeStatusType, NoticeType, CurrencyType, RateType } from './enums';
import { FileType, getFileTypeFromExtensionString } from './types/mime';
import { getOrCreateCustomer, getOrCreateCustomerOrganizationForNotice, getOrCreateCustomerOrganization, getShouldInvoiceCustomerOrCustomerOrgOutsideColumn } from './notice/customer';
import { getColumnInches, getInvoiceAmountsBreakdown } from './pricing';
import { OPEN_INVITE_STATUSES } from './users';
import { isAffidavitDisabled } from './affidavits';
import { hasPaymentOrPartialRefund } from './utils/invoices';
import { getNewspaperFromNotice } from './utils/references';
import { getInheritedProperty } from './utils/inheritance';
import { getOrThrow } from './utils/refs';
import { oklahoma } from './pricing/rateTypes/oklahoma';
import { INVOICE_STATUSES_FINALIZED } from './model/objects/invoiceModel';
import { getDateStringForDateInTimezone } from './utils/dates';
import { isCustomerOnThirtyDaysEomBilling } from './billing/helpers';
import { getErrors, getResponses, wrapError, wrapSuccess } from './types/responses';
import { ColumnService } from './services/directory';
import { CDN_URL_FOR_PROJECT } from './cdn';
export const getOccupationValFromOrganizationVal = (organizationVal) => {
    const organizationType = OrganizationType.by_value(organizationVal);
    return OccupationType.by_key(organizationType === null || organizationType === void 0 ? void 0 : organizationType.defaultOccupationKey).value;
};
export const getOrganizationValFromOccupationVal = (occupationVal) => {
    var _a, _b;
    const occupationKey = (_a = OccupationType.by_value(occupationVal)) === null || _a === void 0 ? void 0 : _a.key;
    return (_b = OrganizationType.items().find(org => org && org.defaultOccupationKey === occupationKey)) === null || _b === void 0 ? void 0 : _b.value;
};
export const dateToDateString = (date, timezone = 'America/Chicago', includeTimestamp = false) => {
    if (!includeTimestamp) {
        date.setHours(12);
    }
    const stringFormat = !(timezone === null || timezone === void 0 ? void 0 : timezone.startsWith('America'))
        ? includeTimestamp
            ? 'D MMM YYYY, h:mm:ss a z'
            : 'D MMM YYYY'
        : includeTimestamp
            ? 'MMM. D, YYYY, h:mm:ss a z'
            : 'MMM. D, YYYY';
    return moment(date).tz(timezone).format(stringFormat);
};
export const publicationDateStarted = (publicationDate, timezone) => {
    const currentTime = moment(Date.now());
    return currentTime.isAfter(moment(publicationDate).tz(timezone).startOf('day'));
};
/**
 * Convert unix timestamp into a date based on the given time zone
 * @param {number} timestamp
 * @param {string} timezone
 * @returns Date with a format according to the timezone
 */
export const unixTimeStampToNewspaperTimezoneDate = (timestamp, timezone) => {
    return moment(new Date(timestamp * 1000))
        .tz(timezone || 'America/Chicago')
        .format(!(timezone === null || timezone === void 0 ? void 0 : timezone.startsWith('America')) ? 'D MMM YYYY' : 'MMM. D, YYYY');
};
export const dateToAbbrev = (date, timezone) => {
    if (!timezone)
        date.setHours(12);
    const dateFormat = timezone && !timezone.startsWith('America') ? 'D/M/YY' : 'M/D/YY';
    return getDateStringForDateInTimezone({
        date,
        timezone: timezone || 'America/Chicago',
        dateFormat
    });
};
export const dateToUtc = (date) => moment.utc(date).format('MMM. D, YYYY');
export const toLocaleString = (date) => moment(date).format('MMM. D, YYYY');
/**
 * Convert a date-like object to a JS Date.
 */
export const firestoreTimestampOrDateToDate = (date) => {
    try {
        if (date._seconds)
            return new Date(date._seconds * 1000);
    }
    catch (err) { }
    if (!date)
        throw new Error('date is undefined');
    try {
        return date.toDate();
    }
    catch (err) {
        if (date.seconds)
            return new Date(date.seconds * 1000);
        if (typeof date === 'number')
            return new Date(date);
        if (typeof date === 'string')
            return new Date(date);
        return date;
    }
};
export const areSameDay = (d1, d2) => {
    return (d1.getDate() === d2.getDate() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getFullYear() === d2.getFullYear());
};
/**
 * Checks if the user's local date is on the same UTC day of the given timestamp
 * @param {Date} date The user's local date
 * @param {number} timestamp The start of UTC day timestamp
 * @returns {boolean}
 */
export const areSameDayUTC = (date, timestamp) => {
    return (moment.utc(moment(date).format('YYYY/MM/DD')).startOf('day').valueOf() ===
        timestamp);
};
export const stripHtmlTags = (str) => {
    if (!str)
        return '';
    return str.replace(/<[^>]*>?/gm, '');
};
export const escapeHTML = (str) => {
    const entityMap = new Map(Object.entries({
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;'
    }));
    return str
        .replace(/[&<>"']/g, (tag) => entityMap.get(tag) || tag)
        .replace(/\s$/, '&nbsp;');
};
export const captureTaglessEscapedHTML = (escapedHtml) => {
    const entityMap = {
        '&amp;': '&',
        '&lt;': '<',
        '&gt;': '>',
        '&quot;': '"',
        '&#39;': "'",
        '&nbsp;': ' '
    };
    let capturedString = escapedHtml;
    for (const [escapedChar, capturedChar] of Object.entries(entityMap)) {
        capturedString = capturedString.replace(new RegExp(escapedChar, 'g'), capturedChar);
    }
    return capturedString;
};
export const centsToDollarsString = (cents) => {
    return (cents / 100).toFixed(2);
};
export const confirmationNumberFromTransferId = (transferId) => {
    return transferId.slice(-8);
};
export const guidGenerator = () => {
    const S4 = function () {
        return ((1 + Math.random()) * 0x10000 || 0).toString(16).substring(1);
    };
    return `${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
};
export const rateCodeGenerator = () => {
    return Math.floor(100000 + Math.random() * 900000);
};
export const sameDay = (d1, d2) => {
    return (d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate());
};
const isValidDate = (d) => {
    return !Number.isNaN(Date.parse(d.toDateString()));
};
export const closestDayFutureDay = (dates) => {
    let closest = Infinity;
    const startOfToday = new Date(Date.now());
    startOfToday.setHours(0, 0, 0, 0);
    const comparableStartOfToday = startOfToday.getTime();
    dates.forEach(date => {
        const comparableDate = date.getTime();
        if (comparableDate >= comparableStartOfToday && comparableDate < closest) {
            closest = comparableDate;
        }
    });
    const close = new Date(closest);
    if (!isValidDate(close))
        return null;
    return close;
};
// TODO: it's probably not good that we're using different logic
// to get the last pub date here and in lastNoticePublicationDate;
// we should reconcile
export const lastPublicationDate = (dates) => {
    const lastDate = dates[dates.length - 1];
    if (!lastDate)
        return null;
    if (!isValidDate(lastDate))
        return null;
    return lastDate;
};
export const sortNoticePublicationDatesChronologically = (publicationDates) => {
    return publicationDates.sort((aTimestamp, bTimestamp) => {
        const a = aTimestamp.toDate();
        const b = bTimestamp.toDate();
        return a < b ? -1 : a > b ? 1 : 0;
    });
};
export const firstNoticePublicationTimestamp = (noticeSnap) => {
    return sortNoticePublicationDatesChronologically(noticeSnap.data().publicationDates)[0];
};
export const firstNoticePublicationDate = (noticeSnap) => {
    return firstNoticePublicationTimestamp(noticeSnap).toDate();
};
export const lastNoticePublicationDate = (noticeSnap) => noticeSnap
    .data()
    .publicationDates.map(fbTime => fbTime.toDate())
    .sort((a, b) => (a > b ? -1 : a < b ? 1 : 0))[0];
/**
 * A helper function that checks if a notice has started and/or finished publication.
 */
export const noticeIsPublished = (notice, newspaper) => {
    const { iana_timezone } = newspaper.data();
    const firstPublicationDate = moment
        .tz(firstNoticePublicationDate(notice), iana_timezone)
        .startOf('day');
    const hasReachedFirstPublication = moment().isAfter(firstPublicationDate);
    const lastPublicationDate = moment
        .tz(lastNoticePublicationDate(notice), iana_timezone)
        .startOf('day');
    const hasReachedFinalPublication = moment().isAfter(lastPublicationDate);
    return {
        hasReachedFirstPublication,
        hasReachedFinalPublication
    };
};
export const canPublisherEditNoticeWithoutSupport = (noticeSnap) => {
    if (!noticeSnap.data().publicationDates)
        return true;
    return new Date() < firstNoticePublicationDate(noticeSnap);
};
export const canAdvertiserEditNoticeWithoutSupport = (noticeSnap, newspaperSnap) => {
    const { deadlines, deadlineOverrides = {} } = newspaperSnap.data();
    if (!deadlines)
        throw new Error('No deadlines found for newspaper');
    const publicationDayEnumValues = publishingDayEnumValuesFromDeadlines(deadlines);
    // get any unusual publication dates that might have been edited in by the publisher
    const customPublicationDate = noticeSnap.data().publicationDates.find(date => 
    // returns true if date isn't a valid publication date
    disableNonPublishingDays(date.toDate(), publicationDayEnumValues, newspaperSnap.data().deadlineOverrides));
    // if there is an unusual publication date, the advertiser cannot edit this notice
    if (customPublicationDate)
        return false;
    const nearestDeadline = getDeadlineTimeForPaper(firstNoticePublicationDate(noticeSnap), deadlines, deadlineOverrides, newspaperSnap.data().iana_timezone, noticeSnap.data(), newspaperSnap);
    return moment(new Date()).isBefore(nearestDeadline);
};
export const assetQuality = {
    high: {
        width: 1000
    }
};
export const IMAGE_TYPES = [FileType.TIF, FileType.PNG, FileType.JPG];
export const fileTypeIsImage = (fileType) => IMAGE_TYPES.includes(fileType);
/**
 * Converts a cdnified URL into a storage path
 */
export const unCdnify = (url) => {
    if (url.includes('imgix.net')) {
        return url.replace(/https:\/\/.*?.imgix.net\//, '');
    }
    // Example:
    // https://firebasestorage.googleapis.com/v0/b/bucket.appspot.com/o/documentcloud%2FdJG9yJUrdPdK56twDH8L%2Ftemplates%2FBellevilleTemplate4.idml?alt=media&token=8dd21a65-9178-4ebb-ae83-488536cb21e9
    if (url.includes('firebasestorage.googleapis.com')) {
        const path = url.split('/o/')[1].split('?')[0];
        return decodeURIComponent(path);
    }
    if (url.includes('storage.googleapis.com')) {
        const path = url.split('appspot.com')[1].split('?')[0];
        return decodeURIComponent(path);
    }
    if (url.includes('res.cloudinary.com')) {
        return url.split(`${FIREBASE_PROJECT}/`)[1];
    }
    if (url.includes('cdn.column.us')) {
        return url.split(`cdn.column.us/`)[1];
    }
    throw new Error(`Cannot unCdnify url ${url}`);
};
/**
 * Cloudinary restricts resource IDs to 255 characters
 * see https://support.cloudinary.com/hc/en-us/articles/207746165-Why-do-I-get-a-public-ID-too-long-error-when-trying-to-fetch-from-a-remote-URL-
 *
 * Attempting to access a resource with a longer ID will result in a 400 error
 */
export const CDN_MAX_CHARS = 255;
export const cdnIfy = (storagePath, options = {}) => {
    var _a;
    // In the Admin environment we pass in the project, in all other environments
    // it is inferred
    const project = (_a = options.firebaseProject) !== null && _a !== void 0 ? _a : FIREBASE_PROJECT;
    const projectPlusStoragePath = `${project}/${storagePath}`;
    const extensionString = (storagePath && storagePath.split('.').pop()) || '';
    const fileType = getFileTypeFromExtensionString(extensionString);
    // we can use Cloud CDN if the project supports Cloud CDN and if there are no transformation required
    const useColumnBucketCDN = options.useColumnCDN &&
        project in CDN_URL_FOR_PROJECT &&
        !options.imgixTransformations &&
        !options.cloudinaryTransformations;
    if (useColumnBucketCDN) {
        return `${CDN_URL_FOR_PROJECT[project]}/${storagePath}`;
    }
    // we use imgix if colud cdn is not enabled and the filetype is an imgix only filetype or the storage path is too long
    const requiresImgix = options.useImgix ||
        projectPlusStoragePath.length > CDN_MAX_CHARS ||
        (fileType &&
            [
                FileType.CSV,
                FileType.EPS,
                FileType.HEIC,
                FileType.HEIF,
                FileType.HTML,
                FileType.IDML,
                FileType.JSON,
                FileType.TIF,
                FileType.WORD_DOC,
                FileType.XML,
                FileType.ZIP
            ].includes(fileType));
    if (requiresImgix) {
        return `https://${project}.imgix.net/${storagePath}${options.imgixTransformations
            ? `?${new URLSearchParams(options.imgixTransformations).toString()}`
            : ''}`;
    }
    const isImageOrPdf = fileType && (fileTypeIsImage(fileType) || fileType === FileType.PDF);
    if (!project)
        throw new Error('Cannot create CDN link without firebase project');
    let resourceType;
    let transformations = '';
    if (isImageOrPdf) {
        resourceType = 'image';
        transformations = (options === null || options === void 0 ? void 0 : options.cloudinaryTransformations)
            ? `/${options.cloudinaryTransformations}`
            : '';
    }
    else {
        resourceType = 'raw';
    }
    if (options.useColumnCDN &&
        project in CDN_URL_FOR_PROJECT &&
        resourceType === 'image') {
        return `${CDN_URL_FOR_PROJECT[project]}/${resourceType}${transformations}/${projectPlusStoragePath}`;
    }
    return `https://res.cloudinary.com/${CLOUDINARY_BUCKET}/${resourceType}/upload${transformations}/${projectPlusStoragePath}`;
};
export const safeCdnIfy = (...args) => {
    try {
        return wrapSuccess(cdnIfy(...args));
    }
    catch (err) {
        return wrapError(err);
    }
};
export const preventXSS = (html) => sanitizeHtml(html, {
    allowedTags: ['div', 'strong', 'ais-highlight-0000000000']
});
export const getNoticeTypeFromNoticeDataUnwrapped = (notice, newspaper, options) => {
    var _a;
    const newspaperSpecificType = (_a = newspaper === null || newspaper === void 0 ? void 0 : newspaper.allowedNotices) === null || _a === void 0 ? void 0 : _a.find(type => {
        if (notice.noticeType === NoticeType.display_ad.value &&
            (options === null || options === void 0 ? void 0 : options.skipDisplayType) &&
            notice.previousNoticeType)
            return type.value === notice.previousNoticeType;
        return type.value === notice.noticeType;
    });
    if (newspaperSpecificType)
        return newspaperSpecificType;
    const noticeType = NoticeType.by_value(notice.noticeType);
    if (noticeType) {
        return noticeType;
    }
    return null;
};
export const getNoticeTypeFromNoticeData = (notice, newspaper, options) => {
    return getNoticeTypeFromNoticeDataUnwrapped(notice, newspaper === null || newspaper === void 0 ? void 0 : newspaper.data(), options);
};
export const getNoticeType = (noticeSnap, newspaperSnap, options) => {
    if (!(noticeSnap === null || noticeSnap === void 0 ? void 0 : noticeSnap.data()))
        return NoticeType.custom;
    return getNoticeTypeFromNoticeData(noticeSnap.data(), newspaperSnap, options);
};
// TODO (APP-388): ideally we can revert this back to 600 once we figure out how to mitigate the downstream affects on pagination size
export const DEFAULT_DPI = 300;
export const DEFAULT_DISPLAY_AD_BORDER_SIZE_IN_PIXELS = 8;
export const inchesToPixels = (inches, ppi) => {
    // Rounding here because decimal pixels are almost never useful
    return Math.round(inches * (ppi || DEFAULT_DPI));
};
export const pixelsToInches = (pixels, ppi) => {
    return pixels / (ppi || DEFAULT_DPI);
};
export const noticeNeedsUpFrontInvoice = (notice, newspaper) => {
    var _a;
    if (!newspaper.data().allowedNotices)
        return false;
    if (notice.data().noticeType === NoticeType.custom.value)
        return false;
    if (notice.data().noticeType === NoticeType.display_ad.value)
        return false;
    if (notice.data().invoice)
        return false;
    if (notice.data().noticeStatus === NoticeStatusType.cancelled.value)
        return false;
    const typeform = (_a = newspaper
        .data()
        .allowedNotices.find(an => an.value === notice.data().noticeType)) === null || _a === void 0 ? void 0 : _a.typeform;
    if (!typeform)
        return false;
    return true;
};
/**
 * A notice should be auto-invoiced if:
 *  - autoInvoice is set on the newspaper, rate, or notice type AND
 *  - notice is not custom or cancelled
 */
export const shouldAutoInvoice = (noticeSnap) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d;
    // Custom or canceled notices do not auto-invoice
    if (noticeSnap.data().noticeType === NoticeType.custom.value ||
        noticeSnap.data().noticeStatus === NoticeStatusType.cancelled.value) {
        return false;
    }
    // TODO(COREDEV-1662): Use new parent boolean helper once merged!
    const newspaperSnap = yield getOrThrow(noticeSnap.data().newspaper);
    const parentSnap = yield ((_a = newspaperSnap.data().parent) === null || _a === void 0 ? void 0 : _a.get());
    const newspaperAutoInvoice = newspaperSnap.data().autoInvoice || ((_b = parentSnap === null || parentSnap === void 0 ? void 0 : parentSnap.data()) === null || _b === void 0 ? void 0 : _b.autoInvoice);
    if (newspaperAutoInvoice) {
        return true;
    }
    const rateSnap = yield ((_c = noticeSnap.data().rate) === null || _c === void 0 ? void 0 : _c.get());
    if ((_d = rateSnap === null || rateSnap === void 0 ? void 0 : rateSnap.data()) === null || _d === void 0 ? void 0 : _d.autoInvoice) {
        return true;
    }
    // If the notice is a display ad, we want to make sure we use the correct notice type settings by checking the previousNoticeType property
    const noticeTypeWithSettings = getNoticeType(noticeSnap, newspaperSnap, {
        skipDisplayType: true
    });
    if (noticeTypeWithSettings === null || noticeTypeWithSettings === void 0 ? void 0 : noticeTypeWithSettings.autoInvoice) {
        return true;
    }
    return false;
});
export const getShouldInvoiceNoticeOutsideColumnByNoticeType = (noticeSnap, newspaperSnap) => {
    var _a;
    // If the notice is a display ad, we want to make sure we use the correct notice type settings by checking the previousNoticeType property
    const noticeTypeWithSettings = getNoticeType(noticeSnap, newspaperSnap, {
        skipDisplayType: true
    });
    // Keeping this block in as this has been the behavior return historically, but we
    // may want to remove this in the future
    if (noticeSnap.data().noticeType === NoticeType.custom.value ||
        noticeSnap.data().noticeStatus === NoticeStatusType.cancelled.value) {
        return false;
    }
    return (_a = noticeTypeWithSettings === null || noticeTypeWithSettings === void 0 ? void 0 : noticeTypeWithSettings.invoiceOutsideColumn) !== null && _a !== void 0 ? _a : null;
};
export const getShouldSendAffidavitNotification = (noticeSnap, newspaperSnap, advertiserSnap) => {
    const { affidavit } = noticeSnap.data();
    if (!exists(newspaperSnap) || !exists(advertiserSnap) || !affidavit) {
        return false;
    }
    const affidavitDisabled = isAffidavitDisabled(noticeSnap.data(), newspaperSnap);
    if (affidavitDisabled) {
        return false;
    }
    return true;
};
export const getShouldSendAffidavitNotificationForNotice = (noticeSnap) => __awaiter(void 0, void 0, void 0, function* () {
    const newspaperSnap = yield noticeSnap.data().newspaper.get();
    const advertiserSnap = yield noticeSnap.data().filer.get();
    return getShouldSendAffidavitNotification(noticeSnap, newspaperSnap, advertiserSnap);
});
// Returns if we should release the affidavit of a notice for the advertiser before invoice payment
export const getAlwaysAllowAffidavitDownloadForNotice = (ctx, noticeSnap) => __awaiter(void 0, void 0, void 0, function* () {
    var _e, _f, _g, _h, _j;
    const newspaper = yield noticeSnap.data().newspaper.get();
    const advertiserOrg = yield ((_e = noticeSnap.data().filedBy) === null || _e === void 0 ? void 0 : _e.get());
    const advertiser = yield ((_f = noticeSnap.data().filer) === null || _f === void 0 ? void 0 : _f.get());
    /**
     * Note: The current user may have access to the notice but not the original filer's customer record
     * In this case, the call to get the customer will fail with a permission error
     * However we want the check to continue, so log a warning rather than throwing the error
     */
    let customer;
    try {
        customer = yield getOrCreateCustomer(ctx, advertiser, newspaper);
    }
    catch (error) {
        getErrorReporter().logAndCaptureWarning(`Cannot get customer for notice: ${noticeSnap.ref.id}. The original filer may be anonymous or no longer a member of the advertiser organization.`);
    }
    const customerOrg = yield getOrCreateCustomerOrganizationForNotice(ctx, noticeSnap);
    return (((_g = advertiser === null || advertiser === void 0 ? void 0 : advertiser.data()) === null || _g === void 0 ? void 0 : _g.alwaysAllowAffidavitDownload) ||
        ((_h = advertiserOrg === null || advertiserOrg === void 0 ? void 0 : advertiserOrg.data()) === null || _h === void 0 ? void 0 : _h.alwaysAllowAffidavitDownload) ||
        ((_j = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _j === void 0 ? void 0 : _j.alwaysAllowAffidavitDownload) ||
        (customer === null || customer === void 0 ? void 0 : customer.data().enableAffidavitsBeforePayment) ||
        (customerOrg === null || customerOrg === void 0 ? void 0 : customerOrg.data().enableAffidavitsBeforePayment));
});
export const shouldReleaseAffidavit = (ctx, noticeSnap, invoiceSnap) => __awaiter(void 0, void 0, void 0, function* () {
    if (!noticeSnap.data().affidavit) {
        throw new Error(`Should release affidavit helper called on a notice without an affidavit!`);
    }
    const isInvoicePaid = exists(invoiceSnap) &&
        (hasPaymentOrPartialRefund(invoiceSnap) ||
            invoiceSnap.data().invoiceOutsideColumn);
    if (isInvoicePaid)
        return true;
    const alwaysAllowAffidavitDownload = yield getAlwaysAllowAffidavitDownloadForNotice(ctx, noticeSnap);
    if (alwaysAllowAffidavitDownload)
        return true;
    return false;
});
export const shouldReleaseAffidavitForNotice = (ctx, noticeSnap) => __awaiter(void 0, void 0, void 0, function* () {
    var _k;
    const invoiceSnap = yield ((_k = noticeSnap.data().invoice) === null || _k === void 0 ? void 0 : _k.get());
    return shouldReleaseAffidavit(ctx, noticeSnap, invoiceSnap);
});
export const getAllowMultiPageAffidavits = (noticeSnap, newspaperSnap) => __awaiter(void 0, void 0, void 0, function* () {
    var _l, _m, _o;
    const rate = yield ((_l = noticeSnap.data().rate) === null || _l === void 0 ? void 0 : _l.get());
    return (((_m = rate === null || rate === void 0 ? void 0 : rate.data()) === null || _m === void 0 ? void 0 : _m.multiPageAffidavits) ||
        ((_o = newspaperSnap.data()) === null || _o === void 0 ? void 0 : _o.multiPageAffidavits));
});
/**
 * This function Ensures that dates passed from frontend to backend are same
 * @param date Date string from new Date().toDateString();
 * @returns newDate;
 */
export const ensureSameDate = (date) => {
    if (!moment(date, 'ddd MMM DD YYYY', true).isValid())
        throw new Error('Invalid Date Format');
    return moment(date).toDate();
};
export const getCurrencySymbol = (currency) => {
    var _a, _b;
    return ((_b = (_a = CurrencyType.by_key(currency === null || currency === void 0 ? void 0 : currency.toLowerCase())) === null || _a === void 0 ? void 0 : _a.symbol) !== null && _b !== void 0 ? _b : CurrencyType.usd.symbol);
};
/*
 * This function is used to determine whether or not a
 * notice requires upfront payment at any time. If
 * called before invoice creation, we pull from the
 * settings of associated objects. During invoice
 * creation, the user can use the override toggle &
 * then that value is set on the notice object.
 */
export const getNoticeRequiresUpfrontPayment = (ctx, noticeSnap) => __awaiter(void 0, void 0, void 0, function* () {
    var _p;
    // Logic hierarchy for requireUpfrontPayment:
    // 0. Notice
    if (noticeSnap.data().requireUpfrontPayment != null) {
        return !!noticeSnap.data().requireUpfrontPayment;
    }
    // 1. Customer (user x for newspaper y)
    const newspaperSnap = (yield noticeSnap
        .data()
        .newspaper.get());
    const advertiserSnap = (yield noticeSnap
        .data()
        .filer.get());
    const customer = yield getOrCreateCustomer(ctx, advertiserSnap, newspaperSnap);
    if (customer.data().requireUpfrontPayment != null) {
        return !!customer.data().requireUpfrontPayment;
    }
    // 2. Customer organization (advertiser org x for newspaper y)
    const customerOrganization = yield getOrCreateCustomerOrganizationForNotice(ctx, noticeSnap);
    if ((customerOrganization === null || customerOrganization === void 0 ? void 0 : customerOrganization.data().requireUpfrontPayment) != null) {
        return !!customerOrganization.data().requireUpfrontPayment;
    }
    // 3. Custom notice type
    const noticeType = getNoticeType(noticeSnap, newspaperSnap);
    if (noticeType && noticeType.upFrontPayment != null) {
        return noticeType.upFrontPayment;
    }
    // 4. Newspaper (does the newspaper default to requiring upfront payment?)
    if (((_p = newspaperSnap.data()) === null || _p === void 0 ? void 0 : _p.requireUpfrontPayment) != null) {
        return !!newspaperSnap.data().requireUpfrontPayment;
    }
    return false;
});
export const getCustomerRequiresUpfrontPayment = (customer) => __awaiter(void 0, void 0, void 0, function* () {
    var _q;
    return (_q = customer.data().requireUpfrontPayment) !== null && _q !== void 0 ? _q : false;
});
// For the given notice and newspaper this returns if bulk payments should be enabled for this notice
export const shouldBulkInvoiceUser_v2 = (ctx, notice, newspaper) => __awaiter(void 0, void 0, void 0, function* () {
    var _r, _s, _t, _u, _v;
    // Bulk payments must be enabled on the newspaper
    const parentOrg = yield ((_r = newspaper.data().parent) === null || _r === void 0 ? void 0 : _r.get());
    if (newspaper.data().bulkPaymentEnabled_v2 === false) {
        return false;
    }
    if (!newspaper.data().bulkPaymentEnabled_v2 &&
        !((_s = parentOrg === null || parentOrg === void 0 ? void 0 : parentOrg.data()) === null || _s === void 0 ? void 0 : _s.bulkPaymentEnabled_v2)) {
        return false;
    }
    const advertiserOrg = yield ((_t = notice.data().filedBy) === null || _t === void 0 ? void 0 : _t.get());
    const advertiser = yield notice.data().filer.get();
    const customer = yield getOrCreateCustomer(ctx, advertiser, newspaper);
    const customerOrganization = advertiserOrg
        ? yield getOrCreateCustomerOrganization(ctx, advertiserOrg, newspaper)
        : null;
    const customerBulkPayment = customer.data().bulkPaymentEnabled_v2;
    // For advertiser organiations, check bulk payment enablement at the customer organization level
    if (customerOrganization) {
        const customerOrgBulkPayment = customerOrganization.data()
            .bulkPaymentEnabled_v2;
        if (typeof customerOrgBulkPayment === 'boolean') {
            return customerOrgBulkPayment;
        }
        // For individuals, check bulk payment enablement at the customer level
    }
    else if (typeof customerBulkPayment === 'boolean') {
        return customerBulkPayment;
    }
    // TODO: remove falling back to check enablement on advertiser(org) level
    // Fall back to checking bulk payments on the user and user's org level
    if ((_u = advertiserOrg === null || advertiserOrg === void 0 ? void 0 : advertiserOrg.data()) === null || _u === void 0 ? void 0 : _u.bulkPaymentEnabled_v2) {
        return true;
    }
    if ((_v = advertiser.data()) === null || _v === void 0 ? void 0 : _v.bulkPaymentEnabled_v2) {
        return true;
    }
    return false;
});
export const getDueDate = (ctx, noticeSnap, requireUpfrontPayment, date) => __awaiter(void 0, void 0, void 0, function* () {
    const newspaperSnap = (yield noticeSnap
        .data()
        .newspaper.get());
    const { deadlines, deadlineOverrides = {}, iana_timezone } = newspaperSnap.data();
    if (!deadlines)
        throw new Error('No deadlines found for newspaper');
    const shouldBulkInvoiceUser = yield shouldBulkInvoiceUser_v2(ctx, noticeSnap, newspaperSnap);
    const customerOnThirtyDaysEomBilling = yield isCustomerOnThirtyDaysEomBilling(ctx, noticeSnap, newspaperSnap);
    if (shouldBulkInvoiceUser ||
        (customerOnThirtyDaysEomBilling && !requireUpfrontPayment)) {
        const endOfNextMonth = moment()
            .tz(iana_timezone)
            .add(2, 'M')
            .startOf('month')
            .toDate()
            .getTime();
        return endOfNextMonth / 1000;
    }
    if (requireUpfrontPayment) {
        const closestPublicationDate = firstNoticePublicationDate(noticeSnap);
        const deadline = getDeadlineTimeForPaper(closestPublicationDate, deadlines, deadlineOverrides, iana_timezone, noticeSnap.data(), newspaperSnap);
        // If publisher changes due date then get the time of the ad deadline for the first publication date
        if (date) {
            const formattedDate = moment(date).format('YYYY-MM-DD');
            const hours = deadline.toDate().getHours();
            const minutes = deadline.toDate().getMinutes();
            const customDeadline = moment(formattedDate).set({
                hour: hours,
                minute: minutes
            });
            return customDeadline.toDate().getTime() / 1000;
        }
        return deadline.toDate().getTime() / 1000;
    }
    return moment().tz(iana_timezone).add(1, 'M').toDate().getTime() / 1000 - 2;
});
export const standardizePhoneNumber = (phoneNumberString) => {
    const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumberString;
};
export const validatePhoneNumbers = (phoneNumberString) => __awaiter(void 0, void 0, void 0, function* () {
    const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g;
    return !!phoneNumberString.match(phoneRegex);
});
export const getPhoneNumberFromNotice = (notice) => __awaiter(void 0, void 0, void 0, function* () {
    var _w, _x;
    const userSnap = yield getOrThrow(notice.data().filer);
    const userIsPublisher = isPublisher(userSnap);
    const activeOrganizationSnap = yield ((_w = userSnap
        .data()
        .activeOrganization) === null || _w === void 0 ? void 0 : _w.get());
    const activeOrgPhone = (_x = activeOrganizationSnap === null || activeOrganizationSnap === void 0 ? void 0 : activeOrganizationSnap.data()) === null || _x === void 0 ? void 0 : _x.phone;
    const savedPhone = userIsPublisher
        ? activeOrgPhone || ''
        : userSnap.data().phone || activeOrgPhone || '';
    const standardizedPhone = standardizePhoneNumber(savedPhone);
    if (savedPhone !== standardizedPhone && userIsPublisher) {
        yield (activeOrganizationSnap === null || activeOrganizationSnap === void 0 ? void 0 : activeOrganizationSnap.ref.update({
            phone: standardizedPhone
        }));
    }
    else {
        yield userSnap.ref.update({
            phone: standardizedPhone
        });
    }
    return standardizedPhone;
});
export const maybeGetXMLSyncExportSettings = (newspaperSnap) => __awaiter(void 0, void 0, void 0, function* () {
    var _y, _z;
    const newspaperXMLExport = newspaperSnap.data().xmlExport;
    if (newspaperXMLExport) {
        return newspaperXMLExport;
    }
    const parent = yield ((_y = newspaperSnap.data().parent) === null || _y === void 0 ? void 0 : _y.get());
    const parentXMLExport = (_z = parent === null || parent === void 0 ? void 0 : parent.data()) === null || _z === void 0 ? void 0 : _z.xmlExport;
    if (parentXMLExport) {
        return parentXMLExport;
    }
    return undefined;
});
export const getXMLSyncExportSettings = (newspaperSnap) => __awaiter(void 0, void 0, void 0, function* () {
    const syncExportSettings = yield maybeGetXMLSyncExportSettings(newspaperSnap);
    if (!syncExportSettings) {
        throw new Error(`XML export information not specified for paper ${newspaperSnap.data().name} with ID: ${newspaperSnap.id}`);
    }
    return syncExportSettings;
});
/**
 * Helper function calls getXMLExportFormatFromNewspaper function
 * @param notice Snapshot of notice, used to pull newspaper data
 * @returns      XML Export settings from the newspaper referenced in the notice
 */
export const maybeGetXMLSyncExportFormatFromNotice = (notice) => __awaiter(void 0, void 0, void 0, function* () {
    const newspaper = yield getNewspaperFromNotice(notice);
    return yield maybeGetXMLSyncExportSettings(newspaper);
});
/**
 * Helper function calls getXMLExportFormatFromNewspaper function
 * @param notice Snapshot of notice, used to pull newspaper data
 * @returns      XML Export settings from the newspaper referenced in the notice
 */
export const getXMLSyncExportFormatFromNotice = (notice) => __awaiter(void 0, void 0, void 0, function* () {
    const newspaper = yield getNewspaperFromNotice(notice);
    return yield getXMLSyncExportSettings(newspaper);
});
//
export const maybeGetBuildIntegrationExportSettings = (newspaperSnap) => __awaiter(void 0, void 0, void 0, function* () {
    var _0;
    return (_0 = getInheritedProperty(newspaperSnap.ref, 'buildExport')) !== null && _0 !== void 0 ? _0 : undefined;
});
export const getBuildIntegrationExportSettings = (newspaperSnap) => __awaiter(void 0, void 0, void 0, function* () {
    const buildExportSettings = yield maybeGetBuildIntegrationExportSettings(newspaperSnap);
    if (!buildExportSettings) {
        throw new Error(`Build export information missing for paper ${newspaperSnap.data().name} with ID ${newspaperSnap.id}`);
    }
    return buildExportSettings;
});
export const maybeGetBuildExportFormatFromNotice = (noticeSnap) => __awaiter(void 0, void 0, void 0, function* () {
    const newspaperSnap = yield getNewspaperFromNotice(noticeSnap);
    return yield maybeGetBuildIntegrationExportSettings(newspaperSnap);
});
export const getBuildExportFormatFromNotice = (noticeSnap) => __awaiter(void 0, void 0, void 0, function* () {
    const newspaperSnap = yield getNewspaperFromNotice(noticeSnap);
    return yield getBuildIntegrationExportSettings(newspaperSnap);
});
export const getExportSettings = (newspaperSnap) => __awaiter(void 0, void 0, void 0, function* () {
    var _1, _2, _3, _4;
    const newspaperFTP = (_1 = newspaperSnap.data().bulkDownload) === null || _1 === void 0 ? void 0 : _1.ftp;
    if (newspaperFTP)
        return newspaperFTP;
    const parent = yield ((_2 = newspaperSnap.data().parent) === null || _2 === void 0 ? void 0 : _2.get());
    const parentFTPSettings = (_4 = (_3 = parent === null || parent === void 0 ? void 0 : parent.data()) === null || _3 === void 0 ? void 0 : _3.bulkDownload) === null || _4 === void 0 ? void 0 : _4.ftp;
    if (parentFTPSettings)
        return parentFTPSettings;
    throw new Error(`FTP export information not specified for paper ${newspaperSnap.data().name} with ID: ${newspaperSnap.id}`);
});
// count bold words from confirmedHtml
export const getBoldWords = (html, DOMparser) => {
    const space = /\s/;
    const doc = new DOMparser().parseFromString(html, 'text/html');
    let totalBoldWords = 0;
    const totalBoldElements = [];
    const elementsWithStrongTag = doc.getElementsByTagName('strong');
    const elementsWithBTag = doc.getElementsByTagName('b');
    for (let i = 0; i < elementsWithStrongTag.length; i++) {
        totalBoldElements.push(elementsWithStrongTag === null || elementsWithStrongTag === void 0 ? void 0 : elementsWithStrongTag[i].innerHTML);
    }
    for (let i = 0; i < elementsWithBTag.length; i++) {
        totalBoldElements.push(elementsWithBTag === null || elementsWithBTag === void 0 ? void 0 : elementsWithBTag[i].innerHTML);
    }
    if (!totalBoldElements.length)
        return 0;
    for (let i = 0; i < totalBoldElements.length; i++) {
        if (space.test(totalBoldElements[i])) {
            const splitText = totalBoldElements[i]
                .split(' ')
                .filter((elem) => elem !== '');
            totalBoldWords += splitText.length;
        }
        else
            totalBoldWords += 1;
    }
    return totalBoldWords;
};
export const isPastDueInNewspaperTimezone = (dueDate, timezone, now) => {
    const SECONDS_IN_MINUTE = 60;
    const SECONDS_IN_DAY = 86400;
    const MILLISECONDS_IN_SECOND = 1000;
    const dueDateNewspaperTimeZone = dueDate.tz(timezone);
    const offset = dueDateNewspaperTimeZone.utcOffset() * SECONDS_IN_MINUTE;
    return now.isAfter(moment((Math.ceil(dueDateNewspaperTimeZone.unix() / SECONDS_IN_DAY) *
        SECONDS_IN_DAY -
        offset) *
        MILLISECONDS_IN_SECOND));
};
// check if upload file type is accepted
export const getFileExtension = (fileName) => {
    var _a, _b;
    return (_b = (_a = fileName === null || fileName === void 0 ? void 0 : fileName.split('.')) === null || _a === void 0 ? void 0 : _a.pop()) === null || _b === void 0 ? void 0 : _b.toLowerCase();
};
export const isValidExtension = (currentFileName, validExtensions) => {
    const extension = getFileExtension(currentFileName);
    if (validExtensions.includes(extension)) {
        return true;
    }
    return false;
};
export const getDaysSinceFirstWeekdayOfQuarter = (now) => {
    let startOfQuarter = moment(now.format('YYYY-MM-DD')).startOf('quarter');
    // handle Saturday
    if (startOfQuarter.day() === 6) {
        startOfQuarter = startOfQuarter.add(2, 'days');
    }
    // handle Sunday
    if (startOfQuarter.day() === 0) {
        startOfQuarter = startOfQuarter.add(1, 'days');
    }
    return now.startOf('day').diff(startOfQuarter.startOf('day'), 'days');
};
/**
 * This is a helper function meant to mimic the behavior of Promise.allSettled(),
 * which we do not have available until we upgrade to Node 12. It should be used
 * in place of Promise.all() when an early rejection of an item should not
 * cause the entire Promise.all() to reject.
 *
 * @deprecated
 * use Promise.allSettled() instead
 *
 * @param awaitArray is an array of items to wait for resolution on. If given a
 * promise, this function waits for it to settle.
 *
 * @returns a promise that resolves to an array after all promises in the given
 * array have settled (either resolved or rejected). The array contains objects
 * with a value if the corresponding item resolved or a reason if it rejected.
 */
export const awaitAllPromises = (awaitArray) => __awaiter(void 0, void 0, void 0, function* () {
    const returnArray = Array(awaitArray.length);
    yield Promise.all(awaitArray.map((promiseOrValue, index) => __awaiter(void 0, void 0, void 0, function* () {
        /**
         * For each item in the array create a wrapper Promise that we resolve
         * only after the item settles (including a rejected Promise). This
         * ensures that the Promise.all() does not settle early on a single
         * reject.
         */
        return new Promise(res => {
            /**
             * We could get a non-promise, so we only need to add handling if it
             * is in fact a Promise.
             */
            if (promiseOrValue instanceof Promise) {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                promiseOrValue
                    .then(value => {
                    returnArray[index] = { value, status: 'fulfilled' };
                })
                    .catch(reason => {
                    returnArray[index] = { reason, status: 'rejected' };
                })
                    .finally(() => res());
                return;
            }
            /**
             * We reach this part of the function if the item is merely a value.
             * Push it to the return array as a resolved item and resolve the
             * wrapper Promise.
             */
            returnArray[index] = {
                status: 'fulfilled',
                value: promiseOrValue
            };
            res();
        });
    })));
    return returnArray;
});
export const getFulfilled = (results) => {
    const fulfilled = results.filter((res) => {
        if (res.status === 'fulfilled') {
            return true;
        }
        return false;
    });
    return fulfilled.map(({ value }) => value);
};
export const getRejected = (results) => {
    const rejected = results.filter((res) => {
        if (res.status === 'fulfilled') {
            return false;
        }
        return true;
    });
    return rejected.map(({ reason }) => reason);
};
export const getNoticePreviewLines = (rate, displayParameters, noticeType) => {
    // Some rates (OK, IA) are calculated on a per-line basis even for display ads
    // If the notice is a display, use the linesPerInch computed value
    // instead of displayParams.lines, which won't exist for a display
    if (exists(rate) &&
        !!displayParameters &&
        noticeType === NoticeType.display_ad.value) {
        if (rate.data().rateType === RateType.oklahoma.value) {
            return oklahoma.getImageLines(displayParameters, rate.data());
        }
    }
    return (displayParameters === null || displayParameters === void 0 ? void 0 : displayParameters.lines) || 0;
};
/**
 * Get the units and quantity for a given rate.
 *
 * ex: A notice priced at 4 inches would be
 * {
 *   unit: "Display Inches",
 *   value: 4
 * }
 */
export const getDisplayUnits = (rate, displayParameters) => {
    var _a, _b, _c;
    if (!displayParameters) {
        return ((_a = rate === null || rate === void 0 ? void 0 : rate.data()) === null || _a === void 0 ? void 0 : _a.rateType) === RateType.inch.value
            ? {
                unit: RateType.inch.plural,
                value: 0
            }
            : {
                unit: RateType.column_inch.plural,
                value: 0
            };
    }
    return ((_b = rate === null || rate === void 0 ? void 0 : rate.data()) === null || _b === void 0 ? void 0 : _b.rateType) === RateType.inch.value
        ? {
            unit: RateType.inch.plural,
            value: displayParameters.area
        }
        : {
            unit: RateType.column_inch.plural,
            value: getColumnInches(displayParameters.height, displayParameters.columns, ((_c = rate === null || rate === void 0 ? void 0 : rate.data()) === null || _c === void 0 ? void 0 : _c.roundOff) || null)
        };
};
export const getNoticeIsInvoicedOutsideColumn = (ctx, notice) => __awaiter(void 0, void 0, void 0, function* () {
    var _5, _6, _7;
    try {
        // Note: throughout this function we use 'foo != null' to find values
        // that are neither 'null' or 'undefined' (relying on null == undefined).
        const newspaper = yield notice.data().newspaper.get();
        const invoice = yield ((_5 = notice.data().invoice) === null || _5 === void 0 ? void 0 : _5.get());
        // Logic hierarchy for invoiceOutsideColumn:
        // 0. Invoice if exists
        if (exists(invoice) && invoice.data().invoiceOutsideColumn != null) {
            return invoice.data().invoiceOutsideColumn;
        }
        // 1. Newspaper
        if (!((_6 = newspaper.data()) === null || _6 === void 0 ? void 0 : _6.allowInvoiceOutsideColumn)) {
            return false;
        }
        // 2. Customer
        const customer = yield getOrCreateCustomer(ctx, yield notice.data().filer.get(), newspaper);
        const customerIsInvoicedOutsideColumn = yield getShouldInvoiceCustomerOrCustomerOrgOutsideColumn(customer);
        if (customerIsInvoicedOutsideColumn != null) {
            return customerIsInvoicedOutsideColumn;
        }
        // 3. Customer organization
        const customerOrg = yield getOrCreateCustomerOrganizationForNotice(ctx, notice);
        const customerOrgIsInvoicedOutsideColumn = yield getShouldInvoiceCustomerOrCustomerOrgOutsideColumn(customerOrg);
        if (customerOrgIsInvoicedOutsideColumn != null) {
            return customerOrgIsInvoicedOutsideColumn;
        }
        // 4. Notice type
        const noticeTypeIsInvoicedOutsideColumn = getShouldInvoiceNoticeOutsideColumnByNoticeType(notice, newspaper);
        if (noticeTypeIsInvoicedOutsideColumn != null) {
            return noticeTypeIsInvoicedOutsideColumn;
        }
        // 5. Newspaper
        return !!((_7 = newspaper.data()) === null || _7 === void 0 ? void 0 : _7.invoiceOutsideColumn);
    }
    catch (err) {
        getErrorReporter().logAndCaptureError(ColumnService.PAYMENTS, err, `Error determining invoice outside Column status for notice`, { noticeId: notice.id });
        return false;
    }
});
export const getMinutesAndSecondsStringFromSeconds = (seconds) => {
    if (seconds === 1)
        return '1 second';
    if (seconds < 60)
        return `${seconds} seconds`;
    const wholeMinutes = Math.floor(seconds / 60);
    const remainder = seconds % 60;
    if (!remainder)
        return `${wholeMinutes} ${wholeMinutes === 1 ? 'minute' : 'minutes'}`;
    return `${wholeMinutes} ${wholeMinutes === 1 ? 'minute' : 'minutes'} and ${getMinutesAndSecondsStringFromSeconds(remainder)}`;
};
export const getToastMessageFromXMLExportSettings = (exportSettings) => {
    const baseMessage = `Sent request to sync notice.`;
    if (!exportSettings.debouncedQueueTimeInSeconds) {
        return baseMessage;
    }
    const debouncedTimeString = getMinutesAndSecondsStringFromSeconds(exportSettings.debouncedQueueTimeInSeconds);
    return `${baseMessage} Notice will sync ${debouncedTimeString} after most recent notice event`;
};
export const normalizeSpaces = (stringToNormalize) => {
    if (!stringToNormalize)
        return '';
    return stringToNormalize.replace(/\s+/g, ' ').trim();
};
export const canRefundNoticeThruStripe = (notice, invoice) => {
    /**
     * We cannot automatically refund notices paid by check in Stripe
     * We are making a product decision to also not allow automatic ACH refunds in Stripe;
     * for the time being, refunds for both of these payment methods will be handled manually
     */
    if (invoice.data().paymentMethod === 'check' ||
        invoice.data().paymentMethod === 'ach') {
        return false;
    }
    return (
    // TODO: Should this include InvoiceStatus.initiated.value? https://columnpbc.atlassian.net/browse/IT-4424
    // If payment is initiated but not fully processed, how does refunding work?
    [InvoiceStatus.paid.value, InvoiceStatus.partially_refunded.value].includes(invoice.data().status) &&
        !invoice.data().paid_outside_stripe &&
        !invoice.data().invoiceOutsideColumn &&
        !notice.data().transfer);
};
// eslint-disable-next-line @typescript-eslint/ban-types
export const removeUndefinedFields = (obj, depth = 0) => {
    Object.keys(obj).forEach(key => {
        const val = obj[key];
        if (val === undefined) {
            // eslint-disable-next-line no-param-reassign
            delete obj[key];
        }
        // Recurse into sub-properties but stop at depth 5 to avoid catastrophe
        if (typeof val === 'object' && val !== null && depth < 5) {
            removeUndefinedFields(val, depth + 1);
        }
    });
    return obj;
};
/**
 * This helper function identifies undefined values in an object and replaces them with the
 * firestore FieldValue indicating the field should be removed. It only works on first order
 * properties, though — to delete nested properties, use dot-notation keys in the update call.
 *
 * Ex. Replaces top-level properties:
 * ```ts
 * const replaced = replaceUndefinedWithDelete(ctx, {
 *   a: 1,
 *   b: undefined
 * })
 *
 * replaced === {
 *   a: 1,
 *   b: ctx.fieldValue().delete()
 * }
 * ```
 *
 * Ex. Ignores nested properties:
 * ```ts
 * const replaced = replaceUndefinedWithDelete(ctx, {
 *   a: 1,
 *   b: {
 *     c: undefined,
 *     d: 2
 *   }
 * })
 *
 * replaced === {
 *   a: 1,
 *   b: {
 *     c: undefined,
 *     d: 2
 *   }
 * }
 * ```
 *
 * To delete a nested field, use dot notation in update:
 * ```ts
 * await ref.update({
 *   'b.c': ctx.fieldValue().delete()
 * })
 * ```
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const replaceUndefinedWithDelete = (ctx, obj) => {
    const replacedObj = Object.assign({}, obj);
    Object.keys(obj).forEach(key => {
        const val = obj[key];
        if (val === undefined) {
            replacedObj[key] = ctx.fieldValue().delete();
        }
    });
    return replacedObj;
};
/**
 * Replace any properties of the object set to 'null' with 'undefined'.
 * Mostly useful for converting EPlacement to Partial<ENotice> but could
 * have other uses too.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const replaceNullWithUndefined = (obj) => {
    const res = {};
    Object.entries(obj).forEach(([key, val]) => {
        if (val === null) {
            res[key] = undefined;
        }
        else {
            res[key] = val;
        }
    });
    return res;
};
/**
 * Identify Column user emails
 * @param {string} email
 * @returns {boolean}
 */
export const isColumnEmail = (email) => {
    const allowedDomains = ['column.us', 'enotice.io'];
    return allowedDomains.some(domain => {
        return email.endsWith(domain);
    });
};
/**
 * Checks if a user is part of Column based on their email address
 * @param {ESnapshotExists<EUser>} user
 * @returns {boolean}
 */
export const isColumnUser = (user) => {
    const { email = '' } = user.data();
    return isColumnEmail(email);
};
/**
 * getDefaultColumnsForUserUserOrgWithNewspaper looks for the property defaultColumns on a customer or customer organization object
 *
 * @param customer The customer object linking between the paper and the advertiser
 * @param customerOrganization The customer organization linking between the paper and the advertiser's org if any
 * @returns default number of columns if it is attached to the customer or customer org object if it exists
 */
export const getDefaultColumnsForUserUserOrgWithNewspaper = (customer, customerOrganization) => __awaiter(void 0, void 0, void 0, function* () {
    var _8, _9;
    // if we have a customer organization and a default column
    // value set on it, use that first
    const customerOrganizationSnap = yield (customerOrganization === null || customerOrganization === void 0 ? void 0 : customerOrganization.get());
    const customerOrgDefaultColumns = (_8 = customerOrganizationSnap === null || customerOrganizationSnap === void 0 ? void 0 : customerOrganizationSnap.data()) === null || _8 === void 0 ? void 0 : _8.defaultColumns;
    if (customerOrgDefaultColumns) {
        return customerOrgDefaultColumns;
    }
    // if there is a default on the customer, use it
    const customerSnap = yield (customer === null || customer === void 0 ? void 0 : customer.get());
    const customerDefaultColumns = (_9 = customerSnap === null || customerSnap === void 0 ? void 0 : customerSnap.data()) === null || _9 === void 0 ? void 0 : _9.defaultColumns;
    if (customerDefaultColumns) {
        return customerDefaultColumns;
    }
    return null;
});
/**
 * a utility function that converts a numeric representation of a number to the
 * corresponding words in English. E.g., convert 1 to "one" or 19 to "nineteen".
 * It accepts the numeric as either a string or a number for ease of use, and
 * works for any number from 0 through 20.
 */
export const getWordsFromNumber = (numOrString) => {
    const num = typeof numOrString === 'number' ? numOrString : parseInt(numOrString, 10);
    if (isNaN(num))
        throw new Error(`The number provided as a string (${numOrString}) could not be parsed`);
    const numStrings = [
        'Zero',
        'One',
        'Two',
        'Three',
        'Four',
        'Five',
        'Six',
        'Seven',
        'Eight',
        'Nine',
        'Ten',
        'Eleven',
        'Twelve',
        'Thirteen',
        'Fourteen',
        'Fifteen',
        'Sixteen',
        'Seventeen',
        'Eighteen',
        'Nineteen',
        'Twenty'
    ];
    const numString = numStrings[num];
    if (!numString)
        throw new Error(`Sorry, this function does not convert negative numbers or positive numbers greater than 20`);
    return numString;
};
export const isInvoiceFinalized = (invoice) => {
    return (exists(invoice) &&
        INVOICE_STATUSES_FINALIZED.includes(invoice.data().status));
};
/**
 * Returns the number of calendar days separating date1 from date2.
 *
 * If date1 is BEFORE date2 then this will return a negative number:
 * Example: calendarDaysApart('2022-05-24', '2022-05-26') === -2
 *
 * If date1 is AFTER date2 then this will return a positive number:
 * Example: calendarDaysApart('2022-05-26', '2022-05-24') === 2
 *
 * Note: localTimezone is the iana timezone string
 * See these docs for supported: https://gist.github.com/diogocapela/12c6617fc87607d11fd62d2a4f42b02a
 *
 * Also, see these docs https://momentjs.com/timezone/docs/#/using-timezones/
 * for how conversions to/from different timezones work
 */
export const calendarDaysApart = (date1, date2, localTimezone) => {
    const m1 = localTimezone
        ? moment.utc(date1).tz(localTimezone).startOf('day')
        : moment.utc(date1).startOf('day');
    const m2 = localTimezone
        ? moment.utc(date2).tz(localTimezone).startOf('day')
        : moment.utc(date2).startOf('day');
    return m1.diff(m2, 'days');
};
export const getDisplayName = (firstName, lastName) => `${firstName || ''} ${lastName || ''}`.trim();
/**
 * a helper function that determines if a notice requires a partial transfer
 * upon affidavit upload instead of a full transfer. A partial transfer occurs
 * when an invoice is partially refunded, a refund less than the full amount is given, and
 * an affidavit is uploaded after notice cancelation.
 */
export const requiresPartialTransfer = (invoice, notice, enablePartialRefundsV2 = false) => {
    var _a, _b, _c, _d;
    if (!exists(invoice))
        return false;
    const refund_amount = ((_a = invoice === null || invoice === void 0 ? void 0 : invoice.data()) === null || _a === void 0 ? void 0 : _a.refund_amount) || 0;
    const { totalInCents } = getInvoiceAmountsBreakdown(invoice);
    const publisherAmountInCents = enablePartialRefundsV2
        ? totalInCents
        : ((_b = invoice === null || invoice === void 0 ? void 0 : invoice.data()) === null || _b === void 0 ? void 0 : _b.pricing.publisherAmountInCents) || 0;
    const invoiceOrNoticeHasRelevantStatusForPartialTransfer = enablePartialRefundsV2
        ? ((_c = invoice === null || invoice === void 0 ? void 0 : invoice.data()) === null || _c === void 0 ? void 0 : _c.status) === InvoiceStatus.partially_refunded.value
        : ((_d = notice === null || notice === void 0 ? void 0 : notice.data()) === null || _d === void 0 ? void 0 : _d.noticeStatus) === NoticeStatusType.cancelled.value;
    const relevantTotalAmountInCents = enablePartialRefundsV2
        ? totalInCents
        : publisherAmountInCents;
    return (exists(invoice) &&
        invoiceOrNoticeHasRelevantStatusForPartialTransfer &&
        refund_amount < relevantTotalAmountInCents);
};
export const shouldPreventLatePrepay = (userNotice, newspaper) => {
    const { deadlines, deadlineOverrides = {} } = newspaper.data();
    if (!deadlines)
        throw new Error('Newspaper deadlines not found');
    const isPastPublicationDeadline = getIsAfterPublishingDeadline(userNotice.data().publicationDates[0].toDate(), deadlines, deadlineOverrides, newspaper.data().iana_timezone, userNotice.data(), newspaper);
    return isPastPublicationDeadline && userNotice.data().requireUpfrontPayment;
};
export const hasAtLeastOneElement = (arr) => {
    return arr.length >= 1;
};
export const getInvitesAssociatedWithEmail = (ctx, email, organizationId) => __awaiter(void 0, void 0, void 0, function* () {
    const normalizedEmail = email.toLowerCase();
    const baseQuery = ctx
        .invitesRef()
        .where('email', '==', normalizedEmail)
        .where('status', 'in', OPEN_INVITE_STATUSES);
    let inviteResults;
    if (organizationId) {
        inviteResults = yield baseQuery
            .where('organizationId', '==', organizationId)
            .get();
    }
    else {
        inviteResults = yield baseQuery.get();
    }
    return inviteResults.docs;
});
export const orgsHaveSameParent = (orgA, orgB) => {
    var _a, _b;
    return !!(exists(orgA) &&
        exists(orgB) &&
        orgA.data().parent &&
        orgB.data().parent &&
        ((_a = orgA.data().parent) === null || _a === void 0 ? void 0 : _a.id) === ((_b = orgB.data().parent) === null || _b === void 0 ? void 0 : _b.id));
};
export const getOpenInvitesToRelatedOrgs = (ctx, user, organization) => __awaiter(void 0, void 0, void 0, function* () {
    const pendingInvites = [];
    if (!exists(organization)) {
        return pendingInvites;
    }
    const { email } = user.data();
    const invites = yield ctx
        .invitesRef()
        .where('email', '==', email)
        .where('status', 'in', OPEN_INVITE_STATUSES)
        .where('organizationId', '!=', organization.id)
        .get();
    for (let i = 0; i < invites.docs.length; i++) {
        const inviteSnap = invites.docs[i];
        const { organizationId } = inviteSnap.data();
        if (!organizationId) {
            throw new Error(`In getOpenInvitesToRelatedOrgs expected organizationId to be a non-empty string but received ${typeof organizationId === 'string'
                ? 'an empty string'
                : typeof organizationId}.`);
        }
        // eslint-disable-next-line no-await-in-loop
        const orgSnap = yield ctx.organizationsRef().doc(organizationId).get();
        if (exists(orgSnap) && orgsHaveSameParent(orgSnap, organization)) {
            pendingInvites.push(inviteSnap);
        }
    }
    return pendingInvites;
});
/**
 * Filter pending invites of anonymous users
 */
export const filterOpenInvitesForAnonUsers = (pendingInvites) => {
    return pendingInvites.filter(invite => !invite.data().organizationId &&
        OPEN_INVITE_STATUSES.includes(invite.data().status));
};
/**
 * Converts the given duration (milliseconds) to number of days, hours and seconds.
 */
export const convertMilliseconds = (milliseconds) => {
    const total_seconds = Math.floor(milliseconds / 1000);
    const total_minutes = Math.floor(total_seconds / 60);
    const total_hours = Math.floor(total_minutes / 60);
    const days = Math.floor(total_hours / 24);
    const seconds = total_seconds % 60;
    const minutes = total_minutes % 60;
    const hours = total_hours % 24;
    return { d: days, h: hours, m: minutes, s: seconds };
};
export const getOpenInvitesAssociatedWithEmail = (ctx, email, organizationId) => __awaiter(void 0, void 0, void 0, function* () {
    const baseQuery = ctx
        .invitesRef()
        .where('email', '==', email)
        .where('status', 'in', OPEN_INVITE_STATUSES);
    let inviteResults;
    if (organizationId) {
        inviteResults = yield baseQuery
            .where('organizationId', '==', organizationId)
            .get();
    }
    else {
        inviteResults = yield baseQuery.get();
    }
    return inviteResults.docs;
});
/**
 * Identify pattern of the e2e test user email.
 * These are random strings before and after @, like that ${random}@${random}.com; and random will be same at both
 * sides so this helps determine email from e2e.
 * @param {string} email
 * @returns {boolean}
 */
export const isTestUser = (email) => {
    const [prefix, suffix] = email.split('@');
    if (`${prefix}.com` === suffix) {
        return true;
    }
    if (`${suffix}` === 'example.org') {
        return true;
    }
    return false;
};
/**
 * Restrict launchDarkly to initialize for e2e users and test environments. But allow for production.
 * @param {string} ENV
 * @param {ESnapshotExists<EUser>} user
 * @returns {boolean}
 */
export const shouldInitializeLD = (ENV, user = undefined) => {
    if (ENV === envs.PROD) {
        return true;
    }
    if ((user && isTestUser(user === null || user === void 0 ? void 0 : user.data().email)) || ENV === envs.TEST) {
        return false;
    }
    return true;
};
export const isBulkPaymentV2EnabledOnOrganization = (newspaper, parent) => {
    var _a, _b, _c;
    if (((_a = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _a === void 0 ? void 0 : _a.bulkPaymentEnabled_v2) === false) {
        return false;
    }
    return (!!((_b = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _b === void 0 ? void 0 : _b.bulkPaymentEnabled_v2) ||
        !!((_c = parent === null || parent === void 0 ? void 0 : parent.data()) === null || _c === void 0 ? void 0 : _c.bulkPaymentEnabled_v2));
};
export function isDefined(val) {
    return val !== undefined;
}
const MAPBOX_TOKEN = 'pk.eyJ1IjoibGhlbnRzY2hrZXIiLCJhIjoiY2pxMGI0d2RtMGt3ajQyb2R3NHFvaHBvciJ9.7W5gy4Fva8g5p0lDyDk89g';
export const geocode = (query) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield fetch(`https://api.tiles.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(query)}.json?access_token=${MAPBOX_TOKEN}`);
    const data = yield result.json();
    if (!data)
        return null;
    const features = data.features;
    if (!features || !features.length)
        return null;
    const { center } = features[0];
    return {
        lat: center[1],
        lng: center[0]
    };
});
export const isAfterLastPublicationDate = (notice) => {
    const lastPublicationDate = lastNoticePublicationDate(notice);
    return moment().isAfter(moment(lastPublicationDate));
};
export const encodeRFC3986URIComponent = (str) => {
    return encodeURIComponent(str).replace(/[!'()*]/g, c => `%${c.charCodeAt(0).toString(16).toUpperCase()}`);
};
export const isTriggerEventForBuildIntegration = (triggerEvent) => {
    return triggerEvent.data().type.includes('build_ad');
};
export const getFormatOrBuildFormatFromNewspaperAndTrigger = (newspaper, trigger) => __awaiter(void 0, void 0, void 0, function* () {
    if (isTriggerEventForBuildIntegration(trigger)) {
        const { buildFormat } = yield getBuildIntegrationExportSettings(newspaper);
        return buildFormat;
    }
    const { format } = yield getXMLSyncExportSettings(newspaper);
    return format;
});
export const delay = (ms) => new Promise(resolve => {
    setTimeout(resolve, ms);
});
export const isNotNull = (value) => {
    return value !== null;
};
/**
 * This will return due date and time string with timezone of the newspaper
 */
export const getDueDateAndTimeString = (invoiceSnap, newspaper) => {
    const { due_date } = invoiceSnap.data();
    if (!exists(newspaper))
        return moment(due_date * 1000).format(`MMMM D, YYYY [at] LT`);
    const { iana_timezone } = newspaper.data();
    const date = moment(due_date * 1000).tz(iana_timezone);
    return date.format(`MMMM D, YYYY [at] LT z`);
};
/**
 * This function takes in a string and returns a consistent hash value.
 * https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
 *
 */
export const deterministicStringHash = (input) => {
    let hash = 0;
    let i;
    let chr;
    if (input.length === 0)
        return hash;
    for (i = 0; i < input.length; i++) {
        chr = input.charCodeAt(i);
        // eslint-disable-next-line no-bitwise
        hash = (hash << 5) - hash + chr;
        // eslint-disable-next-line no-bitwise
        hash |= 0;
    }
    return hash;
};
export const sanitize = (str) => sanitizeFilename(str)
    .replace(/[^a-zA-Z\d:.]/g, '_')
    .toLowerCase();
export const isResponseOrError = (possibleResponseOrError) => {
    if (!possibleResponseOrError) {
        return false;
    }
    const hasResponseProperty = Object.prototype.hasOwnProperty.call(possibleResponseOrError, 'response');
    const hasErrorProperty = Object.prototype.hasOwnProperty.call(possibleResponseOrError, 'error');
    if (!hasResponseProperty || !hasErrorProperty) {
        return false;
    }
    const isResponse = isNotNull(possibleResponseOrError.response);
    const isError = isNotNull(possibleResponseOrError.error);
    if (isResponse && isError) {
        return false;
    }
    return true;
};
export const asyncMap = (arr, mapFn) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const items = yield Promise.all(arr.map(mapFn));
        const responseOrErrorItems = items.map(item => {
            if (isResponseOrError(item)) {
                return item;
            }
            return wrapSuccess(item);
        });
        const errors = getErrors(responseOrErrorItems);
        if (errors.length) {
            return wrapError(new Error(errors.map(e => e.message).join(', ')));
        }
        const responses = getResponses(responseOrErrorItems);
        return wrapSuccess(responses);
    }
    catch (err) {
        return wrapError(err);
    }
});
export const asyncFilter = (arr, mapFn) => __awaiter(void 0, void 0, void 0, function* () {
    const { response: itemsOrNulls, error: errorMapping } = yield asyncMap(arr, mapFn);
    if (errorMapping) {
        return wrapError(errorMapping);
    }
    const filteredItems = itemsOrNulls.filter(isNotNull);
    return wrapSuccess(filteredItems);
});
export const asyncEvery = (arr, mapFn) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const results = yield Promise.all(arr.map(mapFn));
        const errors = [];
        const booleanResults = results.map(item => {
            if (!isResponseOrError(item)) {
                return item;
            }
            if (item.error) {
                errors.push(item.error);
                return false;
            }
            return item.response;
        });
        if (errors.length) {
            return wrapError(new Error(errors.map(e => e.message).join(', ')));
        }
        return wrapSuccess(booleanResults.every(Boolean));
    }
    catch (err) {
        return wrapError(err);
    }
});
