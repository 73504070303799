var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SnapshotModel } from '..';
import { Collections } from '../../constants';
import { CouponService } from '../../services/couponService';
import { CouponStatus } from '../../types/coupon';
import { safeUpdateModel } from '../safeHandlers';
export class CouponModel extends SnapshotModel {
    constructor() {
        super(...arguments);
        this.couponService = new CouponService(this.ctx);
    }
    get type() {
        return Collections.coupons;
    }
    safeDelete() {
        return __awaiter(this, void 0, void 0, function* () {
            return safeUpdateModel(this, { status: CouponStatus.DELETED });
        });
    }
    validateObject(data) {
        const [validationError] = this.couponService.isValidCouponData(data);
        if (validationError) {
            throw validationError;
        }
    }
}
