import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import React from 'react';
export function SidebarTab({ onClick, active, labelText, Icon, id }) {
    return (_jsxs("div", Object.assign({ className: classNames('flex rounded font-medium text-base leading-5 text-column-gray-500 mt-1 py-3 w-56 cursor-pointer', 'hover:bg-column-primary-50 hover:text-primary-500', {
            'bg-column-primary-50 text-primary-500': active
        }), onClick: onClick, id: id }, { children: [_jsx("div", Object.assign({ className: "flex justify-center w-1/6" }, { children: _jsx(Icon, { className: "w-5 h-5" }) })), _jsx("div", Object.assign({ className: "flex-1" }, { children: labelText }))] })));
}
export function Sidebar({ children }) {
    return _jsx("div", Object.assign({ className: "mt-3" }, { children: children }));
}
